.ant-radio-checked .ant-radio-inner{
    border-color: red !important ;
  }
  
  .ant-radio-checked .ant-radio-inner:after{
    background-color: red;
  }
  
  .ant-radio:hover .ant-radio-inner {
    border-color: red ;
  }
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;