.videos .infinite-scroll-component__outerdiv .infinite-scroll-component{
    overflow-y: auto !important;
    overflow-x: hidden !important;
}

@media (max-width: 850px){
    .videodetailsinfo-mobile{
        .ant-row{
            align-items: center;
            display: flex;
            justify-content: center;
        }

    }
  

}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;