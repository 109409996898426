.client_Expenses {
  font-family: "Open Sans", sans-serif;
  .client_header {
    font-size: 30px;
    font-weight: 700;
  }
  .ant-table-thead > tr > th {
    background: none !important;
    border-bottom: none !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none !important;
  }
  .ant-table-thead {
    display: none !important;
  }

  .ant-pagination,
  .ant-table-pagination,
  .ant-table-pagination-right {
    display: none;
  }
}

.expenses_reject_Button,
.expenses_reject_Button:hover {
  background: #e7e8ea;
  color: #0f172a;
  border: none !important;
  border-radius: 41px;
  width: 100px;
  height: 40px !important;
}
.expenses_approve_Button,
.expenses_approve_Button:active,
.expenses_approve_Button:focus{
  background: #0f172a;
  color: #e7e8ea;
  width: 100px;
  height: 40px !important;
  border-radius: 41px;
}
.expenses_approve_Button:hover {
  background: #0f172a;
  color: #e7e8ea;
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  border-radius: 41px;
  width: 100px;
  height: 40px !important;
}
.expenses_client_title {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
}
.expenses_client_relativeName {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #0f172a;
}
.expenses_client_amount {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 25px;
  color: #0f172a;
}
.antd_emptyItem {
  justify-content: center;
}
.no-data-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
  .no-data-text {
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    color: #64748b;
  }
}
.expense-container {
  background-color: #ffffff;
  padding: 20px;
  margin: 10px 0 15px;
  height: 77vh;
  border-radius: 20px;
  overflow: auto;
  .no-data-wrapper {
    height: 71vh;
  }
  .expense-tr-common {
    display: flex;
    align-items: center;
    .expense-td {
      padding: 10px 0;
      &.expense-title-td {
        width: 25%;
        cursor: pointer;
      }
      &.expense-amount-td {
        width: 15%;
      }
      &.expense-relative-td {
        width: 30%;
      }
      &.expense-status-td {
        width: 30%;
        text-align: right;
      }
    }
  }
}
.expenses_client_receipt {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.Expenses_receipt_img {
  width: 50px;
  height: 50px;
  border-radius: 15px;
}
.expenses_Rejected_Button {
  width: 100px !important;
  height: 40px !important;
  background: #ffe6e6 !important;
  color: #ff0000 !important;
  border: none !important;
  border-radius: 41px;
}
.expenses_approved_Button {
  width: 100px !important;
  height: 40px !important;
  border: none !important;
  border-radius: 41px;
  color: #e7e8ea;
  background: #00b383 !important;
  opacity: 0.3;
}
.expenses_approved_Button:hover {
  border: none !important;
  border-radius: 41px;
  color: #e7e8ea;
  background: #00b383 !important;
}
.expenses_pending_Button {
  width: 100px !important;
  height: 40px !important;
  background: #f6e1c3 !important;
  border: none !important;
  border-radius: 41px;
  color: #dc8449 !important;
}

.nav-bar {
  display: flex;
  justify-content: space-between;
}

.expense_reject_No {
  width: 100%;
  height: 60px !important;
}
.expense_popup_cancel {
  width: 140px;
  background: #e7e8ea;
  border-radius: 45px !important;
  border: none !important;
  color: #0f172a !important;
  font-size: 1.25rem !important;
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer;
}

.expense_reject_Okay {
  width: 100%;
  background: #e7e8ea;
  border-radius: 45px !important;
  border: none !important;
  color: #0f172a !important;
  font-size: 1.25rem !important;
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer;
}
.expense_popup_complete {
  width: 180px;
  background: #e7e8ea;
  border-radius: 45px !important;
  border: none !important;
  color: #e7e8ea !important;
  font-size: 1.25rem !important;
  height: 60px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer;
}
.expense_popup_text {
  font-size: 20px;
  font-weight: 600;
  color: #0f172a;
}
.expense_payment_Text {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #64748b;
}
.expense_Payment_Details {
  background: #ffffff;
  border: 1px solid #e7e8ea;
  border-radius: 11px 11px 0 0;
  padding: 20px;
}
.expense_payment_charges {
  justify-content: space-between;
  padding: 10px 0;
}
.expense_Payment_Amount {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #0f172a;
}
.expense_Payment_Type {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 17px;
  line-height: 23px;
  color: #0f172a;
}
.expense_Payment_Total_Details {
  background: #ffffff;
  border: 1px solid #e7e8ea;
  border-radius: 0 0 11px 11px;
  padding: 10px;
  margin-bottom: 20px;
}
.expense_payment_Total_charges {
  justify-content: space-between;
  padding: 10px 10px;
}
.expense_payment_Total_Type {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  color: #0f172a;
}
.expense_payment_Total_Amount {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 17px;
  line-height: 23px;
  text-align: right;
  color: #364bc6;
}
.expenses_payment_cardContainer {
  overflow-x: hidden;
  overflow-y: scroll;
  height: 150px;
}
.expenses_payment_cardContainer::-webkit-scrollbar {
  display: none;
}
.expenses_radio_card {
  width: 440px;
  height: 170px;
  padding: 5px;
   .ant-radio:hover .ant-radio-inner {
    border-color: #364bc6 !important;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: #364bc6 !important;
  }
  .ant-radio-checked .ant-radio-inner:after {
    border-color: #364bc6 !important;
     background-color: #364bc6 !important;
  }
}
.expenses_radio_Radio {
  border: 1px solid #e7e8ea;
  border-radius: 21px;
  padding: 13px;
}
.Close-container {
  display: flex !important;
  justify-content: end !important;
}
.text-end {
  text-align: "end";
}
.Add_Expenses_Form {
  .ant-input {
    background: #ffffff;
    border: 1px solid #e7e8ea;
    border-radius: 11px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #0f172a;
  }
  .anticon svg {
    color: #ff0000;
  }
  .ant-input-number-input {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #0f172a;
  }
  .Expense_FormInputs {
    .ant-input {
      height: 70px;
    }
  }
  .ant-input-number {
    width: 100% !important;
  }
  .ant-input-number-input-wrap {
    height: 70px;
    background: #ffffff;
    border: 1px solid #e7e8ea;
    border-radius: 11px;
    width: 339px;
  }
  .ant-upload-list-picture-card-container {
    width: 100%;
    height: 235px;
    border: 1px solid #e7e8ea;
    border-radius: 20px;
  }
  .ant-upload.ant-upload-select-picture-card {
    width: 100%;
    height: 235px;
    border: 1px solid #e7e8ea;
    border-radius: 20px;
  }
  .upload_Image_content {
    color: #fff;
    font-size: 18px;
    width: 165px;
    height: 60px;
    background: #364bc6;
    border-radius: 41px;
    padding: 14px;
    margin-top: 40px;
  }
  .expense_popup_AddExpense {
    height: 60px;
    width: 145px;
  }
  .expense_detailsCard {
    height: 80px;
    overflow-y: scroll;
  }
  .expense_detailsCard::-webkit-scrollbar {
    display: none;
  }
  .Addexpense_details {
    justify-content: space-between;
    padding: 10px 0;
    margin: 0;
  }
  .expensesReceipt_Img {
    height: 60px;
    width: 60px;
    border-radius: 15px;
  }
  .expensesReceipt_Title {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #0f172a;
  }
  .expensesReceipt_Amount {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    text-align: right;
    color: #0f172a;
  }
  .AddExpense_Name {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #0f172a;
  }
  .AddExpense_Amount {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
    color: #0f172a;
  }
  .ant-upload-list-item-actions {
    a {
      display: none;
    }
  }
}
.ant-input-number-handler-wrap {
  display: none !important;
}
.expenses_new_cardContainer {
  .clientprofile {
    .stripeaddcardbtn {
      position: relative;
      width: 11%;
      bottom: 0;
      left: 0;
      top: 2rem;
      &.stripeaddcardbtn-update{
        left: 0;
        top: 2rem;
      }
    }
  }
  .ant-form-item {
    width: 100% !important;
  }
  .savebutton {
    position: relative;
    bottom: 0;
    width: 53%;
    left: 8px;
  }
  .buttoncancel {
    color: black;
    width: 45%;
    height: 54px;
    left: 10px;
    top: -77px !important;
  }
  .button_cancel {
    color: black;
    width: 48%;
    height: 50px;
    top: -57px !important;
  }
}
.expense_pay_popup_okay {
  width: 135px;
  height: 60px !important;
}
.Card_margin {
  margin: "20px 0px" !important;
}
@media only screen and (width: 768px) and (resolution: 163dpi) {
  .expense-container {
    padding: 20px;
    height: 100vh !important;
    margin: -15px 8px;
  }
}
@media only screen and (width: 1024px) and (resolution: 163dpi) {
  .expense-container {
    padding: 20px;
    height: 100vh !important;
    margin: -15px 8px;
  }
}
@media screen and (max-width: 768px) {
  .payment_modal {
     min-height: 200px;
    max-height: 400px;
    overflow-y: auto;
    overflow-x: hidden;
    .ant-modal-content {
      width: fit-content;
      position: relative;
      background-color: #fff;
      background-clip: padding-box;
      border: 0;
      border-radius: 11px;
      box-shadow: 0 3px 6px -4px rgba(0, 0, 0, 0.12),
        0 6px 16px 0 rgba(0, 0, 0, 0.08), 0 9px 28px 8px rgba(0, 0, 0, 0.05);
      pointer-events: auto;
      margin-left: -3rem;
    }
  }
  .Receipt_Modal {
    .ant-modal-content {
      margin-left: -1rem;
    }
  }
  .transform-component-module_content__FBWxo {
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    height: fit-content;
    margin: 71px;
    transform-origin: 0 0;
  }
  .image-modal-container {
    .transform-component-module_content__FBWxo {
      margin: 0 !important;
    }
  }
  .expense_pay_popup_okay {
    width: 81px !important;
    height: 40px !important;
    padding-left: 24px;
    padding-right: 12px;
  }
  .expenses_client_title {
    font-family: "Open Sans", sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 25px;
    display: inline-block; /* for inline elements e.g. span, strong, em etc */
    text-overflow: ellipsis !important;
    width: 4rem;
  }
  .expense_cancel_Okay {
    width: 80px;
    background: #e7e8ea;
    border-radius: 45px !important;
    border: none !important;
    color: #0f172a !important;
    font-size: 0.8rem !important;
    height: 40px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    cursor: pointer;
  }
  .expenses_radio_card {
    width: 280px;
    height: 50%;
  }
  .info-modal-container p {
    width: auto;
  }
  .Done_btn {
    width: 50px !important;
  }
  .expense-container {
    padding: 20px;
    margin: -15px 8px;
  }
  .Expense_receipt .ExpenseImg .ExpenseImg_image {
    height: 155px;
    width: 150px;
    border-radius: 11px;
    cursor: pointer;
  }
  .expense_reject_Okay {
    width: 88px;
  }
  .expense_popup_okay {
    width: 88px;
    padding-left: 24px;
    padding-right: 12px;
  }
  .expense_popup_cancel {
    width: 88px;
  }
  .Close_modal {
    padding-left: 24px !important;
    padding-right: 12px !important;
  }
  .expenses_client_amount {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    color: #0f172a;
  }
  .ant-table tr {
    display: flex;
    justify-content: baseline;
    align-items: center;
  }
  .ant-table td {
    display: block;
    width: 150%;
    text-align: left;
  }
  .ant-table td::before {
    content: attr(data-title);
    float: left;
    font-weight: bold;
  }
  .ant-table-container {
    width: 100%;
    justify-content: center;
  }
  .highlight-bottom-border > td {
    border-bottom: solid 1px black !important;
  }

  .highlight-top-border > td {
    border-top: solid 2px black !important;
    border-bottom: solid 2px #6d9eff !important;
  }
}

.expensesItems {
  .ant-table-thead {
    visibility: hidden;
  }
  .ant-table {
    font-size: 9px;
  }
  .ant-table-tbody > tr > td {
    height: 30px;
    padding: 4px;
    border-bottom: 0 solid #f0f0f0;
  }
  .ant-table-selection-col,
  .ant-table-selection-column {
    display: none;
  }
}
.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: unset !important;
}
.ant-table-thead > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr.ant-table-row-hover:not(.ant-table-expanded-row) > td,
.ant-table-thead > tr:hover:not(.ant-table-expanded-row) > td,
.ant-table-tbody > tr:hover:not(.ant-table-expanded-row) > td {
  background: unset; //Change the existing color to `unset`
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;