.dashboard{
    display: flex;
    justify-content: space-between;
    height: 80vh;
}

.main-container{
    width: 60%;
    height: 100%;
    background-color: #ffffff;
    border-radius: 20px;
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;