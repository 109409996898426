.password-strength-meter {
    text-align: left;
  }
  
  .password-strength-meter-progress {
    -webkit-appearance: none;
    appearance: none;
    width: 250px;
    height: 8px;
  }
  
  .password-strength-meter-progress::-webkit-progress-bar {
    background-color: #eee;
    border-radius: 3px;
  }
  
  .password-strength-meter-label {
    font-size: 11px;
  }
  
  .password-strength-meter-progress::-webkit-progress-value {
    border-radius: 2px;
    background-size: 35px 20px, 100% 100%, 100% 100%;
  }
  
  .strength-Weak::-webkit-progress-value {
    background-color: #F25F5C;
    color: #F25F5C;
  }
  .strength-Medium::-webkit-progress-value {
    background-color: #FFA500;
    color: #FFA500;
  }
  .strength-Strong::-webkit-progress-value {
    background-color: #00B383;
    color: #00B383;
  }

//   label

  .strength-Weak{
    color: #F25F5C;
  }
  .strength-Medium{
    color: #FFA500;
  }
  .strength-Strong{
    color: #00B383;
  }
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;