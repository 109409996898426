.settings {
  width: 100%;
  overflow-y: scroll;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  height: 78.5vh;
  padding: 25px;
  border-radius: 10px;

  .ant-tabs-nav {
    height: 411px;
    width: 300px;

    .ant-tabs-nav-wrap {
      position: fixed !important;
    }

    .ant-tabs-nav-list {
      width: 275px;
      .ant-tabs-ink-bar {
        background: transparent !important;
      }
    }
  }

  .ant-form-item-control-input {
    width: 95%;
  }

  .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab {
    font-size: 14px !important;
    height: 70px;
    padding: 15px !important;
    border-radius: 15px !important;
    .ant-tabs-tab-btn {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      font-size: 18px;
      color: #0f172a;
    }
  }

  .ant-slider-mark-text {
    display: none;
  }

  .ant-slider-mark-text-active {
    display: block !important;
    bottom: 35px;
  }
  .bank-acknowledge {
    .ant-checkbox {
      position: absolute;
      top: -48px;
    }

    .ant-form-item-label label {
      width: 50%;
      margin-left: 33px;
    }

    .ant-form-item-explain .ant-form-item-explain-error {
      position: absolute;
      top: 0;
    }
  }
}

.profile-form .icon-position {
  background-color: white;
  position: relative;
  right: 4px;
  z-index: 10;
  top: 10px;
  cursor: pointer;

  :hover {
    opacity: 1;
  }
}

.settings-editprofile .profile-form {
  column-gap: 2%;
  row-gap: 2%;
  margin: auto;
}

.settings-editprofile .subform-1 {
  display: flex;
  flex-direction: row;

  .ant-form-item-label {
    label {
      display: block;
      color: #64748b;
      font-size: 18px;
      line-height: 40px;
    }
  }
}

.settings-editprofile .subform-2 {
  display: flex;
  flex-direction: row;
}

.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
  color: transparent;
  text-shadow: 0 0 0 0;
  border-color: transparent;
}

.ant-tabs-tab .ant-tabs-tab-active {
  border-block-end-color: #364bc6;
}

.settings-editprofile .profile-subform {
  display: flex;
  flex-direction: column;
  width: 50%;
  flex-wrap: wrap;
}

.bankInfo-btn {
  position: relative;
  top: 70px;
}

.changepwd-btn {
  position: relative;
}

.file-upload {
  border: 1px solid #e7e8ea;
  width: 100%;
  height: 150px;
  text-align: center;
  border-radius: 15px;
}

.file-upload-box {
  padding: 30px 10px;
  text-align: center;
}

.imagemodal .ant-image {
  margin-bottom: 22px;
}

.upload-image {
  width: 100%;
  height: 150px;
  position: relative;
  bottom: 104px;
}

.close-icon {
  background-color: white;
  border-radius: 5px;
  opacity: 0.8;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  z-index: 10;

  :hover {
    opacity: 1;
  }
}

.service-offer-card {
  border: 1px solid #e7e8ea;
  border-radius: 15px;
  padding: 20px;
  align-items: center;
}

.btn-position {
  position: fixed;
  bottom: 88px;
  overflow: hidden;
  right: 45px;
}

.settings-button-position.btn-position {
  position: absolute;
  bottom: 0;
  overflow: hidden;
  right: 0;
  background: #ffffff;
  width: 100%;
  padding: 20px 15px;

  .auth-action-btn.mt-5 {
    margin-top: 0 !important;
  }
  &.settings-button-position-profile {
    position: relative;
    .auth-action-btn.mt-5 {
      position: relative;
      bottom: 0;
      right: 0;
    }
  }
}

.btn-style {
  position: relative;
  top: -20px;
  left: 0;
}

.imagemodal .upload-image {
  width: 100%;
  height: 150px;
  position: relative;
  bottom: 0;
}

.upload-image-model {
  width: 350px;
  height: 150px;
  position: relative;
}

.close-icon-model {
  background-color: white;
  border-radius: 5px;
  opacity: 0.8;
  position: absolute;
  top: -55px;
  right: 10px;
  cursor: pointer;
  z-index: 10;

  :hover {
    opacity: 1;
  }
}

.role-name {
  font-weight: bold;
  font-size: 18px;
  color: #364bc6;
}

.settings-client-editprofile {
  padding: 15px;
  overflow: auto !important;
}

.subform-1 {
  display: flex;
  flex-direction: row !important;
  width: 100%;
}

.subform-1 label {
  color: rgb(192, 192, 192);
}

.subform-1 .firstone {
  height: auto;
  width: 50%;
  margin-right: 10px;
  cursor: no-drop;

  .ant-input {
    height: 60px;
    border-color: #e7e8ea !important;
  }
}

.subform-1 .lastone {
  height: auto;
  width: 40%;
  cursor: no-drop;

  .ant-input {
    height: 60px;
    border-color: #e7e8ea !important;
  }
}

.dateitem.dateitem-custom {
  .ant-input {
    height: 60px;
    border-color: #e7e8ea !important;
  }
}

.dateofbirth {
  margin-bottom: 5px;
  margin-top: 20px;

  .date {
    width: 100%;
    display: flex;
    flex-direction: row;
  }
}

.dateitem {
  width: 115px;
  padding: 10px;

  .ant-form-item-control-input-content input {
    height: 45px;
    text-align: center;
  }
}

.settings-editprofile {
  padding: 20px;
  .ant-slider-dot {
    width: 1px !important;
  }

  .ant-slider-mark-text-active {
    font-size: 16px;
    font-weight: 700;
    color: #364bc6;
  }
  .ant-form-item-label .ant-form-item-required {
    color: rgb(192, 192, 192);
  }
  h4,
  .profile-detail-title,
  .dateofbirth,
  .roleupgrade p {
    margin: 0 !important;
    color: #64748b !important;
    font-size: 18px !important;
    font-weight: 500 !important;
    padding-bottom: 10px;
    line-height: 40px;
  }
}

.ant-form-item-control-input-content {
  width: 96%;
}

.settings-editprofile .circle {
  position: absolute;
  right: 36px;
  top: 10px;
  bottom: 0;
  opacity: 0.5;
}

.settings-editprofile .caregiverrole {
  display: flex;
  flex-direction: row;
  padding: 20px;
  align-items: center;
}

.settings-editprofile .caregiverpic {
  margin-right: 3px;
  display: flex;
  align-items: flex-start;
}

.ant-form-item-control:first-child:not([class^="ant-col-"]):not(
    [class*=" ant-col-"]
  ) {
  width: 89%;
}

.companionmodelthree .ant-slider-mark .ant-slider-mark-text {
  font-size: 10px !important;
  display: none;
}

.container-fluid .active1 .ant-tabs-tab-active {
  background-color: #e6f2ff !important;
}

.container-fluid .active2 .ant-tabs-tab-active {
  background-color: #ebedf9 !important;
}

.container-fluid .active3 .ant-tabs-tab-active {
  background-color: #e6f8f3 !important;
}

.container-fluid .active4 .ant-tabs-tab-active {
  background-color: #fff4ea !important;
}

.companionmodelthree .ant-slider-mark .ant-slider-mark-text-active {
  display: block !important;
  bottom: 35px;
  font-size: 12px !important;
  font-weight: 700;
  color: #364bc6;
  width: 60px;
}

.companionmodelthree .ant-slider-dot {
  width: 1px !important;
}

.understandbtn {
  margin-left: 23%;
  padding: 10%;
}

.companionmodaltwo {
  font-weight: 550;

  .ant-modal-body {
    overflow-y: hidden;
  }

  .demo-option-label-item {
    font-weight: 550;
  }

  .ant-form {
    .ant-form-item {
      margin-bottom: 20px;

      .ant-form-item-label {
        padding-bottom: 15px;

        label {
          font-size: 17px;
          font-weight: 600;
          line-height: 26px;
          color: #0f172a;
        }
      }

      .ant-select.upgradeselect {
        .ant-select-selector {
          border: 1px solid #e7e8ea;
          border-radius: 15px;
          height: 70px;

          .ant-select-selection-search {
            .ant-select-selection-search-input {
              height: 68px;
            }
          }

          .ant-select-selection-placeholder {
            line-height: 70px;
          }
        }
      }
    }
  }

  h2 {
    font-weight: 700;
    font-size: 24px;
    line-height: 40px;
    color: #0f172a;
    margin-bottom: 20px;
  }
}

.strengthpass {
  .password-strength-meter {
    .password-strength-meter-label {
      font-size: 8px !important;
    }
  }
}

.upgradeselect {
  .ant-select-arrow {
    font-size: 14px !important;
    opacity: 1 !important;
  }
}

.companionmodelthree .ant-select-show-search {
  width: 100%;
}

@media (max-width: 920px) {
  .settings .settingnames {
    display: none !important;
  }

  .settings .ant-tabs-nav {
    width: 80px !important;

    .ant-tabs-nav-wrap {
      width: 50px;
    }
  }

  .settings .ant-tabs-nav .ant-tabs-nav-list {
    width: 70px;
  }

  .subform-1 {
    flex-direction: column !important;

    .firstone {
      width: 80% !important;
    }

    .lastone {
      width: 80% !important;
    }
  }

  .settings .ant-form-vertical .px-md-5 {
    width: 70%;
  }

  .settings-editprofile .caregiverrole {
    width: 50% !important;
  }

  .btn-position {
    width: 500px;
  }

  .settings .imageicons img {
    width: 70% !important;
  }
}

@media (max-width: 600px) {
  .settings-editprofile .roleupgrade {
    flex-direction: column !important;

    .role {
      margin-bottom: 20px;
    }
  }

  .ant-input-password {
    height: auto !important;
  }

  .profile-form .subheading {
    text-align: start;
  }

  .settings-editprofile .ant-form-item {
    height: auto !important;
  }

  .subform-2 {
    flex-direction: column !important;
  }

  .settings-editprofile .ant-slider-mark {
    font-size: 7px !important;
  }

  .settings-editprofile .caregiverrole {
    width: 113% !important;
  }

  .settings-editprofile .caregiversave {
    width: 100px;
  }

  .btn-position {
    width: 120px;
  }

  .dateofbirth {
    text-align: start;
  }

  .role {
    text-align: start;
  }

  .profile-detail-title {
    text-align: start;
  }

  .settings-editprofile .passwordinput {
    width: 80% !important;
  }

  .settings-editprofile .bankinput {
    width: 110% !important;
  }

  .ant-modal-root .ant-modal {
    width: 350px !important;
  }

  .settings-editprofile .upgrade {
    width: 66% !important;
  }

  .settings-editprofile .circle {
    top: 0;
  }
}

@media (max-width: 412px) {
  .companionmodelthree .ant-slider-mark .ant-slider-mark-text {
    font-size: 5px !important;
  }

  .settings-editprofile .caregiverrole {
    width: 126% !important;
  }

  .settings-editprofile .upgrade {
    width: 77%;
  }

  .settings-editprofile .ant-slider-mark {
    font-size: 5px !important;
  }

  .settings-editprofile .changepwd-btn {
    width: 100px !important;
  }

  .settings-editprofile .circle {
    width: 20px !important;
  }

  .settings .ant-tabs-nav .ant-tabs-nav-list {
    width: 40px;
  }

  .strengthpass {
    width: 54%;
    font-size: 9px;
    margin-left: 16px;
  }

  .settings .ant-tabs-nav {
    width: 44px !important;
  }

  .settings-editprofile .caregiversave {
    width: 100px;
    right: 19rem;
  }

  .understandbtn {
    margin-left: 8% !important;
  }

  .bankdetails {
    .ant-input-lg {
      padding: 6px 6px;
      font-size: 10px !important;
    }
  }

  .changepwd {
    .ant-input-lg {
      padding: 6px 6px;
      font-size: 10px !important;
    }
  }

  .password-strength-meter-label {
    font-size: 5px !important;
  }

  .upgradefirstmodal {
    .btn-black {
      width: 150px;
    }
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;