// .video-container {
.videotitle {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

.videoimage {
    position: relative;
  //  width:341px;
    width: 390px;
    height: 200px;
   // margin-bottom: 14px;
}


.playbtn {
    position: absolute !important;
    color: #fff  !important;
    top: 50%  !important;
    left: 50% !important;
    width: 12% !important;
    transform: translate(-50%, -50%);
}
// .ant-modal-body iframe{
//     border-radius: 10px;
//     video{
//         width:919px !important;
//         object-fit: cover !important;
//     }
// }
// video{
// //object-fit: cover;
// width: 919px;
// }


.videomodal .ant-modal-content {
   
    height: 600px;
}

.videomodal .html5-video-container {
    width: 200px !important;
    height: 200px !important;
}

.playmodal .ant-modal-body {
    padding: 0 !important;
    width:919px;
    height:430px;
}
#document .video:-webkit-full-page-media
{
    width:919px !important;
}
.html5-video-player {
    border-radius: 3% !important;
}

.react-player {
    top: 0;
    left: 0;
    border-radius: 3%;
    overflow: hidden;
}
.crossbutton{
    background-color: transparent;
    left:55rem;
}
.circlecross{
    float: right;
    position: absolute;
    right: 10px;
    top: -9%;
}
.playmodal .ytp-show-cards-title
{
    display:none !important;
}
 video[name="media"]
{
    width:919px !important;
}
video:-webkit-full-page-media{
    width:919px !important;
}

@media (max-width: 820px){
    .videoimage {
        width:312px;
    }
}
@media (max-width: 414px){
    .videoimage {
        width:268px;
    }
}
// }
@media (max-width: 767px) {
    .playmodal {
        max-height: calc(100% - 5%)!important;
        min-width: calc(100% - 15%)!important;
        overflow: hidden;
        // border-radius: 25px;
    }
    .playmodal .ant-modal-body {
        padding: 0 !important;
        width: 100%;
        height: 23rem;
    }
  
    .ant-modal-content {
      border-radius: 2;
      padding-top:1rem;
      margin-bottom:1rem;
    }
    .ant-modal-centered .ant-modal {
        // height:100%;
        // margin-bottom: 18rem;
    }
  
    .circlecross {
      width: 30px;
      height: 30px;
      margin-top: 2.5rem;
      background-color: rgb(133, 133, 133);
      border-radius: 99px;
    }
  
    .video {
    //   padding-top: 10px;
    //   max-height: calc(100% - 8%);
    //   max-width: calc(100% - 64%);
    //   display: flex;
    //   justify-content: center;
    //   align-items: center;
    //   text-align: center;
    }
  }
  @media (max-width: 825px) {
    .playmodal{
        .ant-modal-centered .ant-modal {
            // height:100%;
            margin-bottom: 38rem;
        }
        .playmodal {
            max-width: calc(100% - 1%)!important;
        }
        .video {
            // min-width: (100% - 12%)!important;
        }
    }
   

  }
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;