.client-schedule {
  width: 100%;
  display: flex;
  padding: 48px 12px;
  background-color: rgb(247, 249, 250);
}

.active {
  background: #364bc6 !important;
  border-radius: 60px;
  color: #ffffff !important;
}

.appoinment_details {
  width: 100%;
}

::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #d8d8d8;
}

.appoinment_card {
  width: 100%;
  background: #ffffff;
  border-radius: 11px;
  margin-bottom: 10px;
  cursor: pointer;
}

.timing {
  padding-top: 14px;
  padding-left: 22.4px;
}

.timing_date {
  margin-left: 10px;
}

.contact_details {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #64748b;
  margin-top: 10px;
  margin-left: 45px;
}

.contact_person {
  color: #0f172a !important;
}

.rdrCalendarWrapper {
  background: transparent;
}

abbr[title],
abbr[data-original-title] {
  text-decoration: none;
}

.react-calendar__navigation__arrow,
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__label {
  border: none;
  background: none;
  margin: 30px auto 34px;
  width: 20px;
  flex-wrap: wrap;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 20px;
  line-height: 16px;
  text-align: center;
  color: #0f172a;
}

.react-calendar__navigation {
  display: flex;
  cursor: pointer;
}
.react-calendar__navigation__arrow > img {
  cursor: pointer;
}

.react-calendar__month-view__weekdays__weekday {
  display: flex;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #64748b;
  margin-bottom: 10px;
}
.react-calendar__tile:disabled,
.react-calendar__month-view__days__day:disabled,
.react-calendar__month-view__days__day--weekend:disabled {
  color: #e5d0c4;
  // background-color: #f8f8f8;
  cursor: not-allowed;
}
.react-calendar__tile,
.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--neighboringMonth {
  border: none;
  background: none;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #0f172a;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  overflow: visible !important;
  height: 30px;
  margin: 8px auto;
}
.react-calendar__tile--active:hover {
  background: #6b79ce !important;
}

.react-calendar__month-view__days {
  justify-content: space-between;
  align-items: center;
}

.react-calendar__tile--active {
  background: #364bc6 !important;
  color: white;
  width: 40px !important;
  height: 40px !important;
  margin: auto;
}

.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
  display: none;
  visibility: hidden;
}

.react-calendar__navigation__prev-button:disabled, .react-calendar__navigation__next-button:disabled {
  color: #E7E8EA !important;
}

.react-calendar__navigation__prev-button, .react-calendar__navigation__next-button {
  font-size: 30px;
}


.schedule_container {
    width: calc(100% - 250px);
    padding: 0 40px;
  margin-left: 250px;
  transition: 0.3s ease all;
}
.collapsed-class ~ .schedule_container {
  margin-left: 73px;
  width: calc(100% - 73px);
}
.schedule_heading {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0f172a;
  margin: 0 20px 20px 0;
}

.schedule_tab {
  width: 25%;
  height: 40px;
  display: flex;
  border-radius: 60px;

  > div {
    width: 50%;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 20px;
    margin-bottom: 20px;

    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #0f172a;
    transition: 0.3s;
    cursor: pointer;
    background: #ffffff;
    border-radius: 60px;
  }
}

.schedule_appoinments {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  border-radius: 20px;
  transform: matrix(1, 0, 0, 1, 0, 0);
  padding-bottom: 30px;
   height: 70vh;
}
.weekly_schedule_appoinments {
  margin-top: 20px;
  display: flex;
  justify-content: space-between;
  background: #ffffff;
  
  border-radius: 20px;
   height: 70vh;
   flex-direction: column;
   padding: 25px;
}
.schedule_calender {
  width: 50%;
  margin-top: 25px;
  margin-left: 30px;
  padding-right: 25px;
  padding-bottom: 15px;
  border-right: 1px solid #eee;
  border-bottom: 1px solid #eee;
  border-left: 1px solid #eee;
  border-top:1px solid #eee; 
  border-radius: 5px;
  .react-calendar.calendar {
    margin-left: 18px;
  }
}

.schedule_appointments_details {
    width: 100%;
    max-height: 350px;
    overflow: scroll;
}
.schedule_appointments_care_details {
  width: 100%;
   min-height: 40vh;
   max-height: 80vh;
   overflow-y: scroll;
}
.appointments_date {
  margin-top: 15px;
  border-bottom: 1px solid #eee;
}

.appointments_all {
  border-bottom: 1px solid #eee;
  border-top: 1px solid #eee;
  padding: -10px 0;
  margin-top: 10px;
  margin-left: -22px;
}

.no_appointments_text {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 50%;
overflow-x: hidden;
}
.client_no_appointments_text{
  display: flex;
  justify-content: center;  
  align-items: center;
  width: 100%;
  flex-direction: column;
  margin-top: 3px;
overflow-x: hidden;
}
.weekly_calender{
  margin-top: 10% !important;
}

h4 {
  text-align: center;
  margin: 20px;
}

.calendar {
    .month-container {
        .day {
            .day-content {
                border-radius: 50%;
            }
        }
    }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;