.custom-radio-space.gender {
  .ant-space-item {
    width: calc(50% - 4px);
  }
}
span.ant-select-arrow {
  color: #000;
  font-size: 20px;
  opacity: 0.9;
  height: 9px;
  width: 20px;
  img {
    width: 20px;
  }
}

label.upload-file-field {
  width: 100%;
  height: 40px;
  border: 1px solid #ccc;
  position: absolute;
  top: 0;
  border-radius: 11px;
  display: flex;
  justify-content: space-between;
  img {
    float: right;
    padding-right: 10px;
    padding-top: 5px;
    width: 25px;
  }
  input {
    display: none !important;
  }
}

.ant-slider-dot {
  position: absolute;
  top: -20px !important;
  width: 0;
  height: 40px !important;
  border-radius: 0;
  border-width: 1px !important;
  cursor: pointer;
}
.ant-tooltip-inner {
  background: none;
  box-shadow: none;
  color: #1e2c7b !important;
  font-weight: 700;
  font-size: 18px;
}
.ant-slider-rail {
  display: none;
}
.ant-slider-track {
  display: none;
}
.ant-slider-mark-text {
  display: none;
}
.ant-slider-dot-active {
  border-color: #364bc6;
}
.ant-slider-mark {
  .ant-slider-mark-text:first-child {
    display: inline-block !important;
    bottom: 30px;
    color: #64748b;
    font-size: 12px;
    font-weight: 500;
  }
  .ant-slider-mark-text:last-child {
    display: inline-block !important;
    bottom: 30px;
    color: #64748b;
    font-size: 12px;
    font-weight: 500;
    left: 100% !important;
    width: 50px;
  }
}

.ant-slider-handle {
  position: absolute;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  background-color: #fff;
  border: solid 3px #364bc6;
}
.ant-tooltip {
  z-index: 10;
}
.ant-tooltip-placement-top .ant-tooltip-arrow {
  display: none;
}

.ant-slider.simple-slider.ant-slider-horizontal {
  .ant-slider-rail {
    display: block;
    background: #64748b;
  }
  .ant-slider-track {
    display: block;
    background: #364bc6;
  }
}

.map-actions {
  max-width: 300px;
  position: absolute;
  width: 100%;
  bottom: -15px;
  left: calc(50% - 150px);
}
.map-km {
  position: relative;
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  top: calc(80% - 20px);
}

.ant-select-multiple.ant-select-lg .ant-select-selection-item {
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  background: #e7e8ea;
  border: 1px solid #e7e8ea;
  color: #64748b;
}
span.ant-select-selection-item-remove {
  span.anticon.anticon-close {
    border: 1px solid #64748b;
    padding: 2px;
    border-radius: 50%;
    color: #64748b;
  }
}

.ant-steps-item-finish > .ant-steps-item-container > .ant-steps-item-content > .ant-steps-item-title {
  color: #64748b;
  font-size: 1rem;
  font-weight: 500;
}
.profileimage {
  .ant-form-item-row,
  .ant-form-item-control,
  .ant-form-item-control-input,
  .ant-form-item-control-input-content,
  .text-center,
  .ant-spin-nested-loading,
  .ant-spin-container {
    width: 100% !important;
  }
}
.steps-content .hourlyrate {
  padding: 15px;
  .ant-slider-dot {
    width: 1px !important;
  }
}
.steps-content .caregiverhourlyrate {
  .ant-slider-dot {
    width: 1px !important;
  }
  .ant-form-item-control .ant-form-item-control-input {
    padding: 15px;
  }
}
.stripeinfo {
  height: 338px;
  overflow-y: auto;
  h2 {
    font-size: 20px !important;
    color: #0F172A !important;
    font-style: normal;
    font-weight: bold;
    text-transform: capitalize;
  }

  h3 {
    font-size: 18px !important;
    font-style: normal;
    text-transform: capitalize;
    font-weight: 600;
  }

  h4 {
    font-size: 15px;
  }

  h5 {
    font-size: 14px;
  }

  p {
    font-size: 17px;
    font-style: normal;
  }

  table {
    width: 95%;
    margin: 0 auto;
  }

  table,
  th,
  td {
    border: 1px solid black;
    border-collapse: collapse;
    padding: 10px;
  }

  ul {
    padding-inline: 15px;
  }

  ul li {
    padding-bottom: initial;
  }

  .t1 {
    table-layout: fixed;
  }
}
.ant-steps .ant-steps-item-wait .ant-steps-item-container .ant-steps-icon {
  color: black;
  font-weight: 700;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon {
  background-color: #364bc6 !important;
}
.ant-steps .ant-steps-item-process .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
  color: #64748b !important;
}
.ant-steps .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
  color: #64748b !important;
}

.ant-steps .ant-steps-item-finish .ant-steps-item-container .ant-steps-item-icon .ant-steps-icon {
  color: #fff !important;
}
.stripemodal .ant-modal-body {
  padding: 24px 24px 0 24px;
}
.map-actions .ant-slider-horizontal {
  margin-bottom: -6%;
}
.profileinput {
  height: 60px;
}
.profilegender .ant-space-item .ant-radio-button-wrapper {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.steps-content {
  padding: 0 60px 10px 60px;
}
.ant-steps-item-wait .ant-steps-item-container .ant-steps-item-content .ant-steps-item-title {
  opacity: 0.5;
}
.arrowimage {
  fill: #000 !important;
}
.profileselectrole {
  .ant-select-selector {
    height: 60px !important;
    padding: 8px 10px !important;
  }
}
.profileselect {
  .ant-select-selector {
    padding: 10px 10px !important;
  }
}
.profileselectlanguage {
  .ant-select-selector {
    padding: 10px !important;
  }
}
.checkboxclass {
  .ant-checkbox {
    font-size: 50px;
  }
  .ant-checkbox .ant-checkbox-inner {
    width: 25px;
    height: 25px;
    border-radius: 25px;
  }
  .ant-checkbox-inner::after {
    width: 8px;
    height: 12px;
  }
}
.nxtbtn {
  margin-left: 245px !important;
}
.ant-tooltip .ant-tooltip-inner {
  display: none;
}
.caregiverhourlyrate,
.hourlyrate .ant-form-item-row .ant-form-item-control .ant-form-item-control-input .ant-form-item-control-input-content .ant-slider {
  padding: 0 !important;
}

.scrolltext::-webkit-scrollbar {
  width: 9px !important;
  padding-right: 10px;
}
.scrolltext::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
  padding: 10px;
}
@media (max-width: 900px) {
  .profilecont {
    margin-top: 1rem;
    margin-left: 0 !important;
  }
  .responsive-button {
    display: block;
    margin: 0 auto;
    padding: 10px 20px;
    max-width: 80%;
    margin-left: 10% !important;
  }
  .service-section {
    margin-bottom: 4rem;
  }
  .profileselect {
    max-height: 8rem;
    min-height: 8rem;
    overflow: scroll;
  }
  .sumit-button {
    display: flex !important;
    justify-content: flex-start !important;
  }
  .nxtbtn {
    margin-left: 0 !important;
  }
  .form-row {
    margin-top: 3rem;
  }
}
/* Mobile devices */
@media only screen and (max-width: 767px) {
  label.upload-file-field {
    border: none !important;
  }
  .steps-content {
    padding: 0 0 10px 0;
  }
  .map-content {
    max-height: 80vh;
    max-width: 100% !important;
    overflow: scroll;
    .map-km {
      font-size: 1rem;
      position: absolute;
      top: 250px;
      left: 142px;
    }
    .map-actions {
      position: absolute;
      bottom: 20px;
      left: 20px;
      right: 10px;
    }
    .google-next-button {
      width: 70% !important;
    }
  }
}
.ant-btn.custom-checkbox-button .ant-checkbox-inner{
  border: 1px solid rgba(0,0,0,0.2);
}
.ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item.w-100.custom-radio.text-dark.align-items-center.border-less{
    line-height: 48px !important;
  height: 50px;
  font-size: 14px;
}
.ant-btn-lg.pets-section-button{
  height: 50px;
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;