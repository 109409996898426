.StripeElement {
  display: block;
  margin: 24px 0;
  padding: 15px;
  font-size: 1em;
  border: 1px solid #d9d9d9;
  border-radius: 11px;
  outline: 0;
  height: 50px;
  width: 100%;
}
.StripeElement--invalid:hover,
.StripeElement--complete:hover,
.StripeElement--empty:hover {
  border-color: #5b71d4;
  box-shadow: 0 0 0 2px rgb(54 75 198 / 20%);
}

.StripeElement--focus {
  border-color: #5b71d4;
  box-shadow: 0 0 0 2px rgb(54 75 198 / 20%);
  border-right-width: 1px;
  outline: 0;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}

.StripeElement.IdealBankElement,
.StripeElement.FpxBankElement,
.StripeElement.PaymentRequestButton {
  padding: 0;
}

.StripeElement.PaymentRequestButton {
  height: 40px;
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;