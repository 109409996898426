.privacypolicy
{
    h2 {
        font-size: 30px;
        font-style: normal;
        font-weight: 600;
        text-transform: capitalize;
    }

    h3 {
        font-size: 24px;
        font-style: normal;
        text-transform: capitalize;
        font-weight: 600;
    }

    p {
        line-height: 25px;
        font-size: 17px;
        font-style: normal;
    }
}
.termsheadings{
    p{
        text-align: "center" !important;
    }
}
.termsmodal{
    .ant-modal-body
    {
        margin-top:-7rem;
    }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;