.ant-steps {
  overflow-x: hidden;
}

.ant-steps-item-finish .ant-steps-item-icon {
  background-color: #364bc6;
  border-color: #364bc6;
}

.ant-steps-item-finish .ant-steps-item-icon > .ant-steps-icon {
  color: #ffffff;
}

.ant-steps .ant-steps-item .ant-steps-item-container .ant-steps-item-content {
  width: auto;
  text-align: left;
}

.steps-content {
  text-align: center;
  background-color: #fff;
  border: 1px solid #e7e8ea;
  border-radius: 1rem;
  box-shadow: 0 4px 36px 0 #0000000D;
}

.ant-steps-item-process
> .ant-steps-item-container
> .ant-steps-item-content
> .ant-steps-item-title {
  color: #64748b;
  font-size: 1rem;
  font-weight: 500;
}

.ant-steps-item-wait
> .ant-steps-item-container
> .ant-steps-item-content
> .ant-steps-item-title {
  color: #e7e8ea !important;
  font-weight: 500;
}

.ant-steps-item-wait .ant-steps-item-icon {
  border-color: #364bc6 !important;
}

.steps-action {
  margin-top: 24px;
}

.ant-steps-item-tail::after {
  display: inline-block;
  width: 200% !important;
  height: 1px;
  background: #e7e8ea !important;
  border-radius: 1px;
  transition: background 0.3s;
  content: "";
}

.ant-steps-label-vertical .ant-steps-item-icon {
  display: inline-block;
  margin-left: 42px;
  z-index: 1;
  position: relative;
}

.custom-steps-create-profile {
  .ant-steps-label-vertical {
    position: relative;
    .ant-steps-item {
      text-align: center;
      width: 33.33%;
      .ant-steps-item-container{
        .ant-steps-item-icon{
          margin: 0;
        }
        .ant-steps-item-content{
          text-align: center;
        }
      }
    }
    &.ant-steps:after{
      right: 10px;
    }
  }
}

.ant-steps-label-vertical .ant-steps-item-tail {
  margin-left: 0 !important;
  padding: 3.5px 0 !important;
}

.ant-steps.ant-steps-horizontal.ant-steps-label-vertical:before {
  content: "";
  height: 10px;
  width: 10px;
  position: relative;
  left: 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  top: 12px;
  background: #fff;
}

.ant-steps.ant-steps-horizontal.ant-steps-label-vertical:after {
  content: "";
  height: 10px;
  width: 10px;
  position: absolute;
  right: 0;
  border: 1px solid #ccc;
  border-radius: 10px;
  top: 12px;
  background: #fff;
}

.container,
.container-fluid,
.container-lg,
.container-md,
.container-sm,
.container-xl,
.container-xxl {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

.user-circle {
  background: #e7e8ea;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

label.upload-file {
  background-image: url("../../../../public/images/icons/camera-icon-primary.svg");
  width: 45px;
  height: 45px;
  background-position: center;
  background-size: cover;

  input {
    display: none;
  }
}

.google-autosuggestion-list {
  margin-top: 0;
  width: 100%;
  height: 180px;
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  margin-bottom: 108px;
  position: absolute;
  z-index: 1;
  background: #fff;
  padding: 0 10px;
  overflow-y: auto;
}

.relative-img > img {
  width: 60px;
  height: 60px;
  object-fit: cover;
}

.relative-name {
  word-break: keep-all;
  line-height: 1.5;
}

.border-less {
  border: 1px solid #e7e8ea !important;
  border-radius: 11px !important;
}

.border-less.checked {
  border-color: #364bc6 !important;
}

.ant-picker-time-panel-column::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  background: #fff;
}

.ant-picker-time-panel-column::-webkit-scrollbar-thumb {
  background: #c1c1c1;
}

@media (max-width: 1200px) {
  .ant-steps-item-tail {
    width: 110%;
  }
}

@media (min-width: 576px) {
  .container,
  .container-sm {
    max-width: 540px;
  }
}

@media (min-width: 768px) {
  .container,
  .container-md,
  .container-sm {
    max-width: 720px;
  }
}

@media (min-width: 992px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm {
    max-width: 960px;
  }
}

@media (min-width: 1200px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 1140px;
  }
}

@media (min-width: 1400px) {
  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl,
  .container-xxl {
    max-width: 1320px;
  }
}

.relatives-add-space {
  margin-top: 60px;
  padding-bottom: 20px;
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;