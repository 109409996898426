@import './variable.less';

.pointer{
    cursor: pointer;
}
.color-primary{
    color:@primary !important;
}
.color-secondary{
    color:@secondary !important;
}
.fs-7{
    font-size: 0.9rem;
}

textarea {
    resize: none !important;
  }

  .ant-checkbox-checked .ant-checkbox-inner::after {
    position: absolute;
    display: table;
    border: 2px solid #fff;
    border-radius: 2px;
    border-top: 0;
    border-left: 0;
    transform: rotate(45deg) scale(1) translate(-50%, -50%);
    opacity: 1;
    transition: all 0.2s cubic-bezier(0.12, 0.4, 0.29, 1.46) 0.1s;
    content: ' ';
  }

  .ant-select-open{
    .ant-select-arrow{
        img{
            transform: rotate(180deg);
        }
    }
  }