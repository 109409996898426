.mySchedule {
    width: 100%;
    height: 100%;
    background: #e4e8f5;
    box-shadow: 0 10px 60px rgba(15, 23, 42, 0.1);
    border-radius: 20px;
    padding: 15px;
    z-index: 2;
}
.monthly_cal{
  width: 100%;
  height: 100%;
  top: 130px;
  background: #e4e8f5;
  box-shadow: 0 10px 60px rgb(15 23 42 / 10%);
  border-radius: 20px;
  padding: 25px;
  z-index: 2;
}
.mySchedule_heading {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 20px;
  line-height: 27px;
  color: #0f172a;
}
.monthly_Empty{
  height: 50px;
}
.mySchedule_tab {
  width: 100%;
  height: 44px;
  margin-top: 12px;
  display: flex;
  background: #ffffff;
  border-radius: 60px;

  > div {
    width: 50%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    color: #0f172a;
    transition: 0.3s;
    cursor: pointer;
  }
}

.active {
  background: #364bc6 !important;
  border-radius: 60px;
  color: #ffffff !important;
}

.appoinment_details {
  width: 100%;
  min-height: 15vh;
  max-height: 15vh;
  overflow-y: scroll;
  overflow-x: hidden;
}

.appoinment_client_details {
  width: 100%;
  min-height: 50vh;
  max-height: 80vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 0;
  background: transparent;
}
::-webkit-scrollbar-thumb {
  background: #ff0000;
}

.appoinment_card {
  width: auto;
  background: #ffffff;
  border-radius: 11px;
  margin-bottom: 5px;
  cursor: pointer;
  height: 80px;
}
.appoinment_card-month {
  margin:10px 0;
  width: auto;
  background: #ffffff;
  border-radius: 11px;
  cursor: pointer;
  padding: 5px;
  height: 100px;
}
.appoinment_card_border-month{
  margin:2rem 0 20px 2rem;
  background: #ffffff;
  border-radius: 11px;
  width: 310px !important;
  border: 1px solid #eee;
  cursor: pointer;
  padding: 5px 0 10px;
   height: 90px!important;
}


.timing {
  padding-top: 14px;
  padding-left: 22.4px;
}

.timing_date {
  margin-left: 10px;
  color: blue;
}

.contact_details {
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  color: #64748b;
  margin-top: 10px;
  margin-left: 45px;
}

.contact_person {
  color: #0f172a !important;
  font-weight: 800;
}

.rdrCalendarWrapper {
  background: transparent;
}
abbr[title],
abbr[data-original-title] {
  text-decoration: none;
}

.react-calendar__navigation__arrow,
.react-calendar__navigation__prev2-button,
.react-calendar__navigation__label {
  border: none;
  background: none;
  margin: 30px auto 34px;
  width: 20px;
  flex-wrap: wrap;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 16px;
  text-align: center;
  color: #0f172a;
}
.react-calendar__navigation {
  display: flex;
  cursor: pointer;
}
.react-calendar__navigation__arrow > img {
  cursor: pointer;
}

.react-calendar__month-view__weekdays__weekday {
  display: flex;
  justify-content: center;
  font-family: "Open Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: #64748b;
  margin-bottom: 10px;
}
.react-calendar__tile:disabled,
.react-calendar__month-view__days__day:disabled,
.react-calendar__month-view__days__day--weekend:disabled {
  color: #e5d0c4;
  // background-color: #f8f8f8;
  cursor: not-allowed;
}
.react-calendar__tile,
.react-calendar__month-view__days__day,
.react-calendar__month-view__days__day--neighboringMonth {
  border: none;
  background: none;
  font-family: "Open Sans", sans-serif;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #0f172a;
  border-radius: 40px;
  cursor: pointer;
  position: relative;
  overflow: visible !important;
  height: 30px;
  margin: 8px auto;
}
.react-calendar__tile--active:hover {
  background: #6b79ce !important;
}

.react-calendar__month-view__days {
  justify-content: space-between;
  align-items: center;
}

.react-calendar__navigation__prev2-button, .react-calendar__navigation__next2-button {
  display: none;
  visibility: hidden;
}

.react-calendar__navigation__prev-button:disabled, .react-calendar__navigation__next-button:disabled {
  color: #E7E8EA !important;
}
.day-appointments {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  left: 50%;
  margin: auto;
  transform: translateX(-50%);
  > div:last-child {
    margin-right: 0;
  }
}
.greenCircle {
  width: 3.86px;
  height: 3.86px;
  border-radius: 2px;
  margin-right: 2px;
  border: 2.2px solid #00b383;
}

abbr[title] {
  text-decoration: none !important;
}

@media screen and (max-width: 768px) {
  .mySchedule {
    width: 85%;
    height: 80vh;
  }
  .monthly_Empty {
    height:auto !important;
    margin-top: 7rem;
}
  .appoinment_card_border-month {
    margin:1rem 0 20px;
    background: #ffffff;
    border-radius: 11px;
    width: 274px !important;
    border: 1px solid #eee;
    cursor: pointer;
    padding:0 0 10px;
    height: 100px!important;
}
  .appoinment_details {
    width: 100%;
    min-height: 28vh;
    max-height: 8vh;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .appoinment_card-month {
   
    height: 105px !important;
}
}

.monthly_calender {
  width: 100%;
}


.react-calendar__tile--active {
  background: #364bc6 !important;
  color: white;
  width: 50px !important;
  height: 50px !important;
  margin: auto;
}
.calendar {
    .month-container {
        .day {
            .day-content {
                padding: 3px 6px;
                border-radius: 50%;

                &.blue_item {
                    border: 2px solid blue;
                }

                &.red_item {
                    border: 2px solid red;
                }
            }
        }
    }
}
.react-calendar__tile--now {
  background: #64748B !important;
  color: white !important;
  width: 40px !important;
  height: 40px !important;
  margin: auto;


}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;