.appointment-nav-container > div > h1 {
    font-weight: 700;
    font-size: 34px;
    line-height: 46.3px;
}
.nav-menu {
    border: 1px solid white;
    position: absolute;
    top: 97px;
    right: 36px;
    z-index: 999;
    border-radius: 19px;
    padding: 15px 10px 15px 20px;
    width: 300px;
    height: 220px;
    background: white;
    margin-top: 10px;
    box-shadow: 1px 10px 60px 3px rgba(0, 0, 0, 0.15);
    .ant-menu-vertical {
        border-right: none;
    }
    .menu-title {
        font-weight: 600;
        font-size: 18px;
        line-height: 25px;
    }
    .ant-menu-item:hover {
        color: #000 !important;
    }
    .ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected {
        background-color: transparent !important;
    }
    .ant-menu-item-selected {
        color: #000 !important;
    }
}

.nav-menu.caregiver {
    height: 180px !important
}
.breadcrumbs li a, .breadcrumbs li {
    font-weight:bold;
}
.NotificationsContent::-webkit-scrollbar {
    display: none;
   }
.NotificationsContent{
    width: 371px;
    height: 534px;
    overflow-y: scroll;
    
    .NotificationText{
        justify-content: space-between;
       
    }
    .TextNotifications{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #0F172A;
    }
    .NotificationTime{
        font-family: 'Open Sans', sans-serif;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: #64748B;
    }
    .Notifications{
        padding: 5px 10px;
        margin: 1px 0;
        cursor: pointer;
    }
    .paginationContainer{
        text-align: right;
        padding: 10px; 
        .anticon{
            font-size: 20px !important;
            background: #E7E8EA !important;
            padding: 5px;
            border-radius: 5px;
            margin: 0 5px;
        }
    }
}
.ant-popover-arrow{
    display: none !important;
}
.ant-popover-inner-content{
    padding: 0 !important;
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;