label.ant-radio-button-wrapper.custom-radio {
  width: 100%;

  span {
    justify-content: space-between;
  }
}

.choose-relative-modal-div {
  label.ant-radio-button-wrapper.custom-radio {
    border: 1px solid #e7e8ea;
    &:before {
      border: none;
      background: transparent;
      height: auto;
      width: 0;
    }
    &:hover{
      border-color: #5b71d4 !important;
      box-shadow: 0 0 0 2px rgba(54, 75, 198, 0.2) !important;
    }
    &.ant-radio-button-wrapper-checked:not([class*=' ant-radio-button-wrapper-disabled']).ant-radio-button-wrapper:first-child{
      border-color: #5b71d4 !important;
      box-shadow: 0 0 0 2px rgba(54, 75, 198, 0.2) !important;
    }
    &.ant-radio-button-wrapper-checked{
      border-color: #5b71d4 !important;
      box-shadow: 0 0 0 2px rgba(54, 75, 198, 0.2) !important;
    }
  }
}

.ant-space.ant-space-vertical.custom-radio-space {
  width: 100%;
}

.ant-space {
  display: inline-flex;
  flex-wrap: wrap;
}
.new-book-appoinment-heading{
  font-size: 26px !important;
  line-height: 32px !important;
  font-weight: 700 !important;
  padding-top: 15px;
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;