@import "./theme/variable.less";

.otp-input-container {
  justify-content: space-evenly;
  input.otp-input {
    background: #fff !important;
    border-radius: 0.75rem !important;
    font-size: 1rem !important;
    border: 1px solid #d1d5db;
    height: 70px !important;
    width: 70px !important;
    &:focus-visible {
      outline: none;
    }
  }
  input.incorrect {
    border: 1px solid #ff0000 !important;
  }
}

.otp-input-container > input.otp-input:last-child {
  margin-right: 0px !important;
}
.resend-otp-btn {
  color: @blue !important;
  &:disabled {
    color: #ccc !important;
  }
  &:hover {
    background-color: #fff !important;
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;