@media only screen and (max-width: 767px) {
  .work-details-container {
    height: 60vh;
    overflow-x: hidden;
    overflow-y: scroll;
    .responsive-bottom-margin {
      margin-bottom: 2rem;
    }
    .responsive-bottom-margin-extra {
      margin-bottom: 3rem;
    }
    .responsive-top-margin {
      margin-top: 2rem;
    }
    .qualification {
      max-height: 7rem;
      overflow: scroll;
    }
    .work-next-button {
      width: 80%;
    }
    .animals-label {
      margin-bottom: 0 !important;
    }
  }
}

.animals-label {
  margin-bottom: 25px;
}

//@media only screen and (min-width: 1200px) {
//  .work-details-form-row,
//  .work-details-container {
//    width: 45vw;
//  }
//}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;