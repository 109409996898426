
.footer{
    position: fixed;
    bottom: 0;
    width:86.4%;
    height: 40px;
    text-align: center;
    right: 0;
    cursor: pointer;
    font-size: 18px;
    font-weight: 400;
    color:#64748B;
      font-family: "Poppins", sans-serif;
}

.termsmodal{.ant-modal-body{
    min-height: 50vh;
    max-height: 90vh;
    overflow: auto;
    margin-top: 0 !important;
    justify-content: center !important;

}}
.Close-container{
    display: flex !important;
    justify-content: end !important;
  }
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;