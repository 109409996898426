.relative-list-container-web {
    .add-relative-btn {
        background: #364BC6;
        border-radius: 11px;
        color: #fff;
        margin: 15px 20px 15px 0;
        height: 35px;
        font-weight: 400;
        font-size: 16px;
        line-height: 22px;
    }
    .custom-small-button {
        font-size: 12px;
        padding: 6px 12px;
        transform: scale(0.8);
      }

      .custom-big-button {
        font-size: 16px;
        padding: 10px 20px;
      }

    .relative-list {
        height: 73vh;
        overflow-x: auto;
        .relative-wrapper {
            display: flex;
            align-items: center;
            background: #fff;
            margin: 15px 10px 15px 0;
            padding: 10px 20px;
            box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
            border-radius: 11px;
            width: calc(100% - 10px);
            .relative-profile {
                width: 25%;
                img {
                    border-radius: 50%;
                }
                .relative-name {
                    font-weight: 700;
                    font-size: 20px;
                    line-height: 26px;
                    margin-bottom: 0;
                    margin-left: 20px;
                }
            }
            .address-wrapper {
                width: 55%;
                .relative-address {
                    font-weight: 400;
                    font-size: 18px;
                    line-height: 20px;
                    color: #64748B;
                    margin-bottom: 0;
                    position: relative;
                    // right: 140px;
                }
            }
            .relative-actions {
                display: flex;
                justify-content: flex-end;
                .btn-wrapper {
                    width: 15%;
                }
            }
        }
    }
    .relative-list::-webkit-scrollbar {
        display: none;
    }
}

.relative-list-container-mobile {
  .add-relative-btn {
      background: #364BC6;
      border-radius: 11px;
      color: #fff;
      margin: 15px 20px 15px 0;
      height: 35px;
      font-weight: 400;
      font-size: 16px;
      line-height: 22px;
  }
  .custom-small-button {
      font-size: 12px;
      padding: 6px 12px;
      transform: scale(0.8);
    }
    
    .custom-big-button {
      font-size: 16px;
      padding: 10px 20px;
    }
    
  .relative-list {
      height: 73vh;
      overflow-x: auto;
      .relative-wrapper {
          display: flex;
          align-items: center;
          background: #fff;
          margin: 20px;
          padding: 10px 20px;
          // height: 100px;
          box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
          border-radius: 11px;
          .relative-profile {
              width: 25%;
              img {
                  border-radius: 50%;
              }
              .relative-name {
                  font-weight: 700;
                  font-size: 20px;
                  line-height: 26px;
                  margin-bottom: 0;
                  margin-left: 20px;
              }
          }
          .address-wrapper {
              width: 55%;
              .relative-address {
                  font-weight: 400;
                  font-size: 18px;
                  line-height: 20px;
                  color: #64748B;
                  margin-bottom: 0;
                  position: relative;
                  // right: 140px;
              }
          }
          .relative-actions {
              display: flex;
              justify-content: flex-end;
              .btn-wrapper {
                  width: 13%;
              }
          }
      }
  }
  .relative-list::-webkit-scrollbar {
      display: none;
  }
}
@media screen and (min-width: 768px) {
    .relative-list {
      height: auto;
      overflow-x: hidden;
    }
    .relative-list-container .relative-list .relative-wrapper {
      display: flex;
      align-items: center;
      background: #fff;
      // margin: 20px;
      padding: 10px 20px;
      height: 199px !important;
      box-shadow: 0px 10px 60px rgba(0, 0, 0, 0.1);
      border-radius: 11px;
  }
    
  
    .relative-wrapper {
      height: auto;
      box-shadow: none;
      margin: 0;
    //   padding: 20px;
      border-radius: 0;
      flex-wrap: wrap;
      width: 100%;
    }
    
    .relative-profile {
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
      img {
        border-radius: 50%;
        // margin-bottom: 10px;
      }
      .relative-name {
        margin-left: 0;
      }
    }
    
    .address-wrapper {
      width: 100%;
      text-align: center;
      .relative-address {
        position: static;
        right: auto;
        margin-bottom: 10px;
      }
    }
    
    .relative-actions {
      justify-content: center;
      .btn-wrapper {
        width: 100%;
      }
    }
    
    .relative-profile, .address-wrapper, .relative-actions {
      flex-basis: 100%;
    }
  }
  

.relative-form {
    label {
        font-weight: 400;
        font-size: 18px;
        line-height: 28px;
        color: #64748B;
    }
    input {
        height: 60px;
    }
    input[type="tel"] {
        padding-top: 20px;
    }
    .react-tel-input {
        height: 60px;
    }
    .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
        width: 100% !important;
    }
}

.relative-details-container {
    .relative-details {
        .label {
            font-weight: 400;
            font-size: 14px;
            line-height: 19px;
            color: #64748B;
        }
        .values {
            font-weight: 700;
            font-size: 16px;
            line-height: 22px;
            color: #0F172A;
        }
        .profile-wrapper {
            margin-bottom: 20px;
            img {
                border-radius: 50%;
            }
            .relative-name {
                font-weight: 700;
                font-size: 18px;
                line-height: 25px;
                margin-bottom: 0;
                margin-left: 12px;
                text-transform: capitalize;
            }
        }
    }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;