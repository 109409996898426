.email-container {
    width: 850px;
    height: 550px;
    position: absolute;
    top:0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    display: flex;
    border: 1px solid #E7E8EA;
    border-radius: 15px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    box-shadow: 0 4px 36px 0 #0000000D;
}


.reqgenaratedMsg {
    text-align: center;
}

.reqgenaratedMsg > h1 {
    color: #0F172A;
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 28px;
    line-height: 40px;
}
.reqgenaratedMsg > h1 > span {
    color: #364BC6;
}

.reqgenaratedMsg > h4 {
    color: #64748B;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 18px;
    line-height: 30px;
    width: 380px;
    height: 68px;
    font-style: normal;
    margin: auto;
}
.p-resend{
    margin: 10px auto 10px;
    color: #64748B;
    font-size: 18px;
    line-height: 18px;
    font-weight: 500;
    font-family: 'SF Pro Display', sans-serif;
    button{
        font-size: 16px;
        font-weight: 500;
        padding: 0;
    }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;