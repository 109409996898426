.message-container {
  .message-wrapper {
    min-height: 64vh;
    .no-msg-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      .no-msg-txt {
        font-weight: 400;
        font-size: 20px;
        line-height: 27px;
        text-align: center;
        color: #64748b;
        margin-bottom: 0;
      }
    }
    .timestamp.right {
      position: relative;
      bottom: 5px;
      left: 95%;
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #64748b;
    }
    .timestamp.left {
      font-weight: 400;
      font-size: 13px;
      line-height: 18px;
      color: #64748b;
      position: relative;
      bottom: 5px;
    }
    .left-child {
      .search-chat {
        height: 60px;
        width: 105%;
      }
      .search-chat::placeholder {
        font-size: 18px;
      }
      .friend-list {
        width: 105%;
      }
      .list-person-name {
        font-weight: 700;
        font-size: 14px;
        line-height: 19px;
        margin-bottom: 5px;
      }
      .latest-message {
        font-weight: 400;
        font-size: 14px;
        line-height: 19px;
        color: #64748b;
        margin-bottom: 0;
      }
      .chat-wrapper {
        padding: 15px;
      }
      .current-chat {
        background: #ebedfa;
        border-radius: 15px;
      }
      .list-item {
        width: 100%;
      }
      .unread-count {
        font-weight: 400;
        font-size: 10px;
        line-height: 12px;
        color: #ffff;
        background: #364bc6;
        left: 16vw;
        position: absolute;
        border-radius: 50%;
        padding: 4px 7px;
      }
      .list-item-wrapper {
        width: 85%;
        .updated-time {
          position: relative;
          width: 100% !important;
          // left: 5vw;
          top: 23px;
          font-size: 12px;
          color: #64748b;
        }
      }
    }
    .right-child {
      margin-left: 41px;
      background: #e7e8ea;
      border-radius: 15px;
      .active-chat {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        margin: 0 0 5px 15px;
      }
      .chat-box-wrapper {
        padding: 15px;
        .chat__container {
          height: 40vh;
          overflow: auto;
          display: block;
          .timestamp.right {
            position: relative;
            bottom: 5px;
            left: 92.8%;
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #64748b;
          }
          .timestamp.left {
            font-weight: 400;
            font-size: 13px;
            line-height: 18px;
            color: #64748b;
            position: relative;
            bottom: 5px;
          }
          .message__chats.right {
            display: flex;
            justify-content: flex-end;
            p {
              // height: fit-content;
              // text-align: right;
              padding: 10px;
              background: #0f172a;
              color: #fff;
              border-radius: 15px;
              overflow-wrap: break-word;
              width: fit-content;
              max-width: 50%;
              height: max-content;
              text-align: left;
            }
          }
          .message__chats.left {
            display: flex;
            justify-content: flex-start;
            p {
              // height: fit-content;
              // text-align: right;
              padding: 10px;
              background: #fff;
              color: #0f172a;
              border-radius: 15px;
              overflow-wrap: break-word;
              width: fit-content;
              max-width: 50%;
              height: max-content;
              text-align: left;
            }
          }
        }
        .chat__container::-webkit-scrollbar {
          display: none;
        }
        .chat-input-message {
          .bottom-scroll {
            display: flex;
            justify-content: flex-end;
          }
          .inputMessage {
            height: 60px;
            font-size: 18px;
            margin-top: 10px;
          }
          .not-available-txt {
            font-weight: 400;
            font-size: 20px;
            line-height: 27px;
            text-align: center;
            color: #64748b;
            margin-top: 40px;
          }
        }
      }
    }
    .right-client-child {
      // margin-left: 41px;
      background: #e7e8ea;
      border-radius: 15px;
      width: 100%;
      .active-chat {
        font-weight: 700;
        font-size: 20px;
        line-height: 27px;
        margin: 0 0 5px 15px;
      }
      .chat-box-wrapper {
        padding: 15px;
        .chat__container {
          height: 48vh;
          overflow: auto;
          .message__chats.right {
            display: flex;
            justify-content: flex-end;
            p {
              text-align: right;
              padding: 10px;
              background: #364bc6;
              color: #fff;
              border-radius: 15px;
            }
          }
          .message__chats.left {
            display: flex;
            justify-content: flex-start;
            p {
              text-align: right;
              padding: 10px;
              background: #fff;
              color: #0f172a;
              border-radius: 15px;
            }
          }
        }
        .chat__container2 {
          height: 37vh;
          display: block;
          // overflow: auto;
          overflow-y: auto;
          width: 100%;
          overflow-x: hidden;
          .message__chats.right {
            display: flex;
            justify-content: flex-end;
            p {
              text-align: right;
              padding: 10px;
              background: #0f172a;
              color: #fff;
              border-radius: 15px;
            }
          }
          .message__chats.left {
            display: flex;
            justify-content: flex-start;
            p {
              text-align: right;
              padding: 10px;
              background: #fff;
              color: #0f172a;
              border-radius: 15px;
            }
          }
        }
        .chat__container::-webkit-scrollbar {
          display: none;
        }
        .chat-input-message {
          .inputMessage {
            height: 60px;
            font-size: 18px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 768px) {
    .left-child {
      .search-chat {
        height: 60px;
        width: 105%;
      }
      .search-chat::placeholder {
        font-size: 18px;
      }
    }
    .right-child {
      margin-left: 8px !important;
      background: #e7e8ea;
      border-radius: 15px;
      margin-top: 13px;
    }
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;