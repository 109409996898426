.contactus {
  display: flex;
  flex-direction: row;
}
.contactinfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}
.contactimage {
  margin-bottom: 0;
}
.contact-support-main{
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  color: #0F172A;
  margin-bottom: 45px;
  a{
    color: #0F172A;
  }
}
.emailref {
  font-weight: normal !important;
}
.call-btn {
  background: #000 !important;
}
.sendmailbtn {
    background-color: black;
    margin-left: 0;
    font-size: 20px;
    width: 292px;
    font-weight: 500;
    text-align: center;
    border-radius: 32px;
    padding: 16px;
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-sx-container {
  .call-btn,
  .email-btn {
    background: #000 !important;
    width: 45%;
    font-size: 14px !important;
    height: 45px !important;
  }
}

@media (max-width: 780px) {
  .clientsettings {
    width: 100%;
    max-height: 85vh;
    display: flex;
    // display: flex;
    // justify-content: center;
    // align-items: center;
  }
  .contactus {
    flex-direction: column;
  }
}


@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;