
.profile-header-row{
    overflow-y:auto;
}
.profile-geo-location-div, .profile-details.steps-content{
    max-height: calc(100vh - 160px) !important;
    overflow: auto;
    min-height: auto;
}
.pointer.pointer-add-pp{
    width: 120px;
    text-align: center;
    margin: auto;
}
@media (max-width: 800px){
    .profile-details .form-row{
        max-height: calc(80vh - 90px);
        overflow-y: scroll;
        overflow-x: hidden;
    }
   
    .profile-header-row{
        // height: 400px !important;
      overflow-y:auto;
      display:flex; 
      justify-content:center;
    }
      
    
}
@media (max-width: 990px){
    .form-row{
        max-height: calc(45vh - 90px);
        overflow-y: scroll;
        overflow-x: hidden;
    }
}
.ant-btn-lg.custom-checkbox-button{
    border: 1px solid #E7E8EA !important;
    height: 50px;
    font-weight: 500;
    font-size: 15px;
}
.ant-btn-lg.custom-checkbox-button.checked{
    border-color: #364bc6 !important;
}


@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;