.redirect-modal {
  &__text {
    font-size: 20px;
    color: #364bc6;
    text-align: center;
  }
  //   .title {
  //     text-align: center;
  //     // font-weight: 500;
  //   }
  .proceed-btn {
    color: #364bc6;
    font-size: 20px;
    cursor: pointer;
  }
  &__img {
    width: 170px;
  }
}

@media only screen and (max-width: 425px) {
  .redirect-modal {
    .proceed-btn {
      font-size: 13px;
    }
    &__img {
      width: 100px;
    }
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;