@import "./theme/variable.less";

.center-container {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
}

.welcome-container {
  border: 1px solid #E7E8EA;
  border-radius: 15px;
  font-family: "Open Sans", sans-serif;
  width: 100%;
  height: 100%;
  max-width: 900px;
  min-height: 650px;
  display: flex;
  box-shadow: 0 4px 36px 0 #0000000D;
  .password-strength-meter-label {
    font-size: 8px !important;
  }
  .password-strength-meter-progress {
    width: 70% !important;
  }
}

.auth-action-btn {
  padding-left: 4.5rem !important;
  padding-right: 4.5rem !important;
}

.welcome-poster {
  background: url(../../../public/images/posters/welcome.png);
}
.client-login-poster {
  background: url(../../../public/images/posters/signin.png);
}
.verify-poster {
  background: url(../../../public/images/posters/verify-bg.png);
}
.left-side-poster {
  position: relative;
  width: 43%;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 15px 0 0 15px;
  font-family: "Open Sans", sans-serif;
  background-position: right;
}

.right-side-form {
  position: relative;
  width: 75%;
  border-radius: 0 15px 15px 0;
  margin: 2rem 2rem;
  font-family: "Open Sans", sans-serif;
}
.ant-form-item-explain-error {
  text-align: left !important;
}

.welcome-header {
  font-weight: 600;
}

.radio-box-div {
  width: 55%;
  margin: auto;
}

.ant-radio-button-wrapper.custom-radio {
  line-height: 29px !important;
  border: 1px solid #246C9B4D;
}

.welcome-subheader {
  margin-bottom: 5px;
  font-size: 18px;
  line-height: 26px;
  color: @secondary;
}
#name {
    color: #364bc6;
    font-size: 28px;
    font-weight: 700;
    line-height: 40px;
}
p #name{
  font-size: 18px;
  line-height: 30px;
}
#confirmBtn {
  padding: 10px;
}

.welcome-types {
  height: 50px;
}
input[type="placeholder"] {
  color: red;
}

.btn-black {
  background: @primary !important;
  border-radius: 45px !important;
  border: none !important;
  color: white !important;
  font-size: 1rem !important;
  height: 55px !important;
  font-weight: 500 !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  cursor: pointer;
  width: 185px !important;
    padding: 0 !important;
}
.confirm-button.btn-black{
  font-size: 20px !important;
  height: 60px !important;
  width: 248px !important;
}
.btn-black:disabled {
  background: #e7e8ea !important;
  color: #fff !important;
  font-size: 16px !important;
  font-weight: 500 !important;
  cursor: not-allowed;
}
.support-role-container {
  .info-text {
    font-weight: 700;
    font-size: 20px;
    line-height: normal;
  }
  .support-contact-no,
  .support-email {
    font-size: 20px;
    font-weight: 700;
  }
}

@media only screen and (max-width: 600px) {
  .p-3 {
    font-size: 18px !important;
    line-height: 25px !important;
  }
  label.ant-radio-button-wrapper.custom-radio span {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: nowrap;
  }
  .left-side-poster {
    display: none;
  }
  .right-side-form {
    position: relative;
    width: 100%;
    margin: 0;
    font-family: "Open Sans", sans-serif;
  }
  .auth-action-btn {
    padding-left: 2.5rem !important;
    padding-right: 2.5rem !important;
    width: 100%;
  }
  .w-100 {
    text-align: center;
  }
  .home-button {
    position: fixed !important;
    bottom: 15%;
    left: 50%;
    transform: translateX(-50%);
    margin: auto;
  }
  .center-container {
    flex-direction: column;
  }
}
@media only screen and (max-width: 700px) {
  .home-button {
    position: fixed !important;
    bottom: 25%;
    left: 50%;
    transform: translateX(-50%);
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;