.ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
}

.bank-acknowledge {
        .ant-checkbox {
            position: absolute;
            top: -75px;
        }
        .ant-form-item-label label {
            // width: 50%;
            margin-left: 33px !important;
        }
        .ant-form-item-explain .ant-form-item-explain-error {
            position: absolute;
            top: 0
        }
}
@media only screen and (max-width: 767px) {
  .bank-detail-wrapper{
    max-height: 70vh;
    overflow-y: scroll;
    overflow-x: hidden;
    .ant-modal-content{
      min-height: 20vh !important;
    }
    .banking-title{
      display: flex;
      align-items: center;
    }
    // .ant-modal-centered .ant-modal{
    //   min-width: 100% !important;
    // }
   
  }
  .modal-button{
    margin-left: 14%!important;
    // margin-top: 2% !important;
    // padding: 0% !important;
    // position: absolute!important;
    // top: 12rem!important;
    // width: 50%!important;
  } 
  
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;