.ant-card:hover {
    border-color: red;
}
.ant-tabs-tabpane:hover {
    border-color: red;
}
.cardinfo {
    position: relative;
}
.nameicon {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 5px;
}
.cardinfo .fullname {
    font-family: 'Open Sans', sans-serif;
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
}
.cardinfo .deleteicon {
    left: 12.5%;
    right: 12.5%;
    top: 8.33%;
    bottom: 70.45%;
}
.cardinfo .cardnumber {
    display: flex;
    padding: 10px;
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    color: #64748B;
}
.cardinfo .cardnumber .item {
    letter-spacing: 3px;
    padding-right: 7px;
    margin-right: 19px;
}
.cardinfo .carddetails {
    display: flex;
    flex-direction: row;
    padding: 10px;
}
.cardinfo .carddetails .expirycvv {
    font-family: 'Open Sans', sans-serif;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #64748B;
    margin-right: 20px;

}
.cardinfo .visaimage {
    display: flex;
    align-items: end;

}
.cardinfo .visaimage img {
    height: 12px;
    position: absolute;
    right: 3px;
}

.cardinfo .expirycvvvalue {
    font-weight: 700;
    font-size: 18px;
    line-height: 26px;
    color: #071F42;
}
.crossImage {
    display: flex;
    justify-content: center;
}
.carddetailsinfo .ant-card .ant-card-bordered
{
    width: 335px !important;
     height: 200px !important;
}

@media (max-width: 400px)
{
     .carddetailsinfo .ant-card .ant-card-bordered{
    width: 250px !important;
  }
  
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;