.parent-root {
    display: flex;
    flex-direction: column;
    width: calc(100% - 250px);
    padding: 0 40px 0 8px;
    margin-left: 250px;
      transition: 0.3s ease all;
    .card-body {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    .chart-content {
        display: flex;
        justify-content: space-between;
    }

    .dashboard-body {
        width: 68%;
    }

    .root {
        display: flex;

    }
    .chart-card-main{
        width: calc(100% - 1rem);
    }
    .right-panel{
        width: 32%;
        padding-left: 30px;
    }
    .chart-card {
        height: 50%;

        .chart {
            height: auto;
        }
    }
}
.collapsed-class ~ .parent-root {
  margin-left: 73px;
  width: calc(100% - 73px);
}
.mobile-root {
    display: flex;
    flex-direction: column;
    min-width:100% !important;
    align-items: center;
    .parent-root-mobile{
        padding:1px;
        min-width:100% !important;
    }
.mobile-small-cards {
    display: flex;
    flex-direction: column;
    min-width: 100% !important;

    .responsive-card{
        margin: .5rem;
    }
}
}
@media only screen and (max-width: 768px) {
    .dashboard-body {
        max-height: 78vh;
        overflow: scroll;
        // .recharts-text .recharts-cartesian-axis-tick-value{
        //     font-size: 12px;
        // }
    }
    .chart-container {
      height: 40vh;
      width: 16rem;
      margin-left: -25px !important;
      .h4.ant-typography {
        margin-left: -16px !important;
      }
    }
    .chart-content {
        display: flex;
        justify-content: space-between;
    }
    .chart-title{
        display: flex;
        font-size: 16px !important;
        font-weight: 500;
    }
  }
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;