.deletemodal
{
    .ant-input-affix-wrapper  input{
        height:45px;
    }
    .ant-input-affix-wrapper > .ant-input:not(textarea)
    {
        height:45px;
    }
    .confirmButtons{
        height:96px;
    }
}
@media (max-width: 412px){
    .deletemodal
{
        .ant-modal-content
        {
        top:15%;
        }
    .ant-input-affix-wrapper  input{
        height:30px;
    }
    .ant-input-affix-wrapper > .ant-input:not(textarea)
    {
        height:30px;
        margin-bottom: 0 !important;
    }
    .ant-form-item{
        margin-bottom: 0;
        height:50px;
    }
    .deleteheading{
        margin-left: 0 !important;
    }
    .deleteyesbtn{
        width:50% !important;
    }
    .deletenobtn{
        width:65% !important;
    }
    .confirmButtons{
        margin-Top:10px !important;
        height:105px;
    }
}
.logoutmodal{
    .ant-modal-content
    {
    top:30%;
    }
}

}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;