@import "../../../common/styles/theme/variable.less";

button.btn-large {
  width: 140px;
  height: 50px !important;
  font-size: 1.015rem !important;
  font-weight: 600;
}

// choose service page css

.choose-service-row-main .ant-btn-primary:focus {
  background: #364bc6 !important;
}

.service-btn {
  width: 100%;
  max-width: 250px;
  padding: 15px;
  border: 1px solid #e7e8ea;
  border-radius: 15px;
  margin-top: 18px;
  margin-left: 16px;
}

.service-btn-active {
  border: 1px solid @blue !important;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1);
}

.service-container {
  width: 100%;
  display: flex !important;
  flex-wrap: wrap;
  text-align: center;
}

.Availability {
  font-size: medium !important;
}

.service-card {
  width: 183px;
  height: 203px;

  .service-img {
    width: 80px;
    height: 80px;
    // border-radius: 50px;
  }

  .ant-checkbox-inner {
    top: 24px;
    left: 5px;
  }

  .select-all-check {
    top: 0;
    left: 0;
  }
}

.cancelled-appointment {
  opacity: 0.8;
  border: 1px solid rgba(255, 0, 0, 0.2) !important;

  .date-card {
    opacity: 0.5 !important;
    background-color: #ff0000 !important;
  }
}

.ongoing-appointment {
  border: 1px solid #364bc6 !important;
  background: #364bc6 !important;

  .time-text {
    color: #fff;
  }

  .date-card {
    background: #fff !important;
    color: #364bc6 !important;
  }
}

.completed-appointment {
  opacity: 0.8 !important;

  .date-card {
    opacity: 0.5 !important;
  }
}

.service-btn > .ant-checkbox + span {
  flex-basis: 100% !important;
}

.service-btn > span.ant-checkbox {
  top: -50px;
  right: 35px;
  width: 0;

  .ant-checkbox-inner {
    width: 22px;
    height: 22px;
    border-radius: 25px;
  }
}

.service-btn > span.ant-checkbox-checked::after {
  border: none;
}

.service-btn > span.ant-checkbox-checked .ant-checkbox-inner {
  border-radius: 25px;
  width: 25px;
  height: 25px;
}

.service-btn > span.ant-checkbox-checked .ant-checkbox-inner::after {
  position: absolute;
  display: table;
  border: 2px solid #fff;
  top: 13px;
  border-radius: 2px;
  border-top: 0;
  left: 7px;
  border-left: 0;
  transform: rotate(45deg) scale(1.5) translate(-50%, -50%);
}

// range calender
.rdrDefinedRangesWrapper {
  display: none;
}

.rdrCalendarWrapper {
  color: #000000 !important;
  font-size: 1rem !important;
  border: 1px solid #e7e8ea !important;
  border-radius: 11px !important;
  padding: 0 !important;
}

// confirm relative
.relative-container {
  border: 1px solid #e7e8ea !important;
  width: 250px;
  border-radius: 11px;
  text-align: center;
  position: relative;

  .edit-relative {
    position: absolute;
    right: 10px;
    top: 10px;
    cursor: pointer;
  }
}

.btn-back {
  background: #e7e8ea !important;
  color: #000 !important;
  font-size: 1rem !important;
  border-radius: 45px !important;
  border: none !important;
  height: 55px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}

.timing-list {
  max-height: 250px;
  overflow-x: auto;
}

.info-icon-tip {
  margin-left: 10px;
}

.info-modal-container {
  p {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    color: #64748b;
    width: 337px;
  }

  .image-container {
    text-align: center;
  }
}

.caregivers-wrapper {
  min-height: 50vh;

  .no-data-found {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    height: 62vh;

    h3 {
      text-align: center;
      line-height: 27px;
      font-size: 20px;
      font-weight: 400;
      margin-top: 10px;
    }
  }

  .caregivers-card {
    padding: 15px;
    border: 1px solid #e7e8ea;
    border-radius: 21px;
    width: 182.5px;
    height: 275px;
  }

  .profile-wrapper {
    p {
      margin: 5px 0 5px 0;
    }

    .service-img {
      border-radius: 50px;
    }

    .person-title {
      background: #364bc6;
      border-radius: 7px;
      width: 80px;
      height: 24px;
      color: #fff;
      margin: 0;
    }

    .person-title > span {
      position: relative;
      font-weight: 500;
      font-size: 12px;
      bottom: 5.5px;
      right: 2px;
    }
  }

  .ratings-card {
    .review-title {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #64748b;
    }

    .inner-div-1 {
      padding-right: 10px;
    }

    .inner-div-2 {
      padding-left: 10px;
    }
  }

  .book-btn {
    width: 158px !important;
    height: 40px !important;
    margin: 6px -2.8px !important;
  }
}

.timing-container {
  .ant-picker-large .ant-picker-input > input {
    font-size: 18px;
  }

  p,
  .ant-typography {
    font-size: 16px;
  }
}

.notification-icon {
  position: relative;
}

.unread-notification-dot {
  position: absolute;
  right: 7.63%;
}

.profile-details-wrapper {
  .username {
    bottom: 56px;
    position: relative;
    left: 70px;

    .caregiver-btn {
      width: 80px !important;
      height: 24px !important;
      background: #364bc6;
      border-radius: 8px !important;
      font-size: 10px !important;
      letter-spacing: 0.05em !important;
      cursor: default;
    }

    .caregiver-btn:hover {
      background: #364bc6;
      color: #fff;
    }
  }

  .role-btn {
    background: #364bc6;
    width: 122px;
    height: 35px;
    color: #fff;
    cursor: default;
  }

  .role-btn:hover,
  .role-btn:active {
    background: #364bc6;
    color: #fff;
  }

  .bookmark-btn {
    position: relative;
    top: 2px;
    right: 24px;
  }

  .profile-detail-title {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    color: #64748b;
  }

  .profile-detail-val {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 22px;
  }

  .service-offer {
    &-card {
      border: 1px solid #e7e8ea;
      border-radius: 15px;
      padding: 10px;
      align-items: center;
      min-height: 72px;
      max-height: 72px;

      .date-card {
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: #364bc6;
        color: white;
        padding: 5px 15px;
        border-radius: 15px;
      }

      .time-text {
        font-size: 12px;
        margin-left: 10px;
      }
    }

    &-text {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 20px;
      color: #0f172a;
      margin-left: 10px;
    }

    &-languages {
      margin-left: 0 !important;
      white-space: break-spaces;
    }
  }
}

.profile-name {
  text-transform: capitalize;
}

.service-appointment-col {
  padding: 0 5px;
}

.red-border {
  border: 1px solid #ff0000 !important;
}

@media (max-width: 768px) {
  .modal-view {
    .ant-modal {
      max-width: fit-content !important;
    }
  }

  .caregivers-wrapper .book-btn {
    width: 158px !important;
    height: 40px !important;
    margin: auto !important;
  }

  .caregivers-wrapper .caregivers-card {
    width: 300px !important;
  }

  .caregivers-wrapper {
    justify-content: center !important;
  }

  .profile-details-wrapper {
    .Pending-appointment-content {
      width: 74%;

      .username {
        bottom: 28px;
        position: relative;
        left: 35px;

        .caregiver-btn {
          width: 40px !important;
          height: 12px !important;
          background: #364bc6;
          border-radius: 4px !important;
          font-size: 5px !important;
          letter-spacing: 0.025em !important;
          cursor: default;
        }

        .caregiver-btn:hover {
          background: #364bc6;
          color: #fff;
        }
      }

      .role-btn {
        background: #364bc6;
        width: 61px;
        height: 17.5px;
        color: #fff;
        cursor: default;
      }

      .role-btn:hover,
      .role-btn:active {
        background: #364bc6;
        color: #fff;
      }

      .bookmark-btn {
        position: relative;
        top: 1px;
        right: 12px;
      }

      .profile-detail-title {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 7px;
        line-height: 9px;
        color: #64748b;
      }

      .profile-detail-val {
        font-family: "Open Sans", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 8px;
        line-height: 11px;
      }

      .service-offer {
        &-card {
          border: 1px solid #e7e8ea;
          border-radius: 15px;
          padding: 5px;
          align-items: center;

          .location-container {
            width: min-content;
          }

          .date-card {
            display: flex;
            flex-direction: column;
            align-items: center;
            background-color: #364bc6;
            color: white;
            padding: 2.5px 7.5px;
            border-radius: 15px;
          }

          .time-text {
            font-size: 6px;
            margin-left: 5px;
          }
        }

        &-text {
          font-family: "Open Sans", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 7.5px;
          line-height: 10px;
          color: #0f172a;
          margin-left: 5px;
        }

        &-languages {
          margin-left: 0 !important;
          white-space: break-spaces;
        }
      }
    }
  }
}

.review-modal-container {
  text-align: center;

  .rating-num {
    font-size: 47px;
    font-weight: 700;
    margin-bottom: 0;
  }

  .review-count-msg {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
    text-align: center;
    color: #64748b;
  }

  .no-review-txt {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 27px;
    text-align: center;
    color: #64748b;
  }

  .star-wrapper {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 10px;
  }

  .client-wrapper {
    background: white;
    box-shadow: 0 10px 60px rgba(15, 23, 42, 0.1);
    border: 1px solid #e7e8ea;
    border-radius: 11px;
    padding: 15px 20px;
    margin-top: 15px;

    .ratings-star-wrapper {
      right: 6px;
      position: relative;
      bottom: 13px;
    }

    .rating-date {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #64748b;
    }

    .client-name {
      font-weight: 600;
      font-size: 14px;
      line-height: 19px;
    }

    .client-feedback {
      font-weight: 400;
      font-size: 14px;
      line-height: 26px;
      color: #64748b;
      text-align: left;
      margin-bottom: 0 !important;
    }
  }

  .no-reviews-wrapper {
    margin: 60px 0 20px 0;
  }
}

.payment-wrapper {
  .amount-wrapper {
    background: #ebedfa;
    border-radius: 11px;
    width: 92%;
    height: auto;
    padding: 20px;
  }

  .amount-wrapper.booking-confirm {
    height: auto !important;
  }

  .payment-msg {
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    color: #64748b;
    opacity: 0.5;
  }

  .payment-card-wrapper {
    width: 450px;
    height: 65px;
    background: #ffffff;
    border: 1px solid #364bc6;
    border-radius: 11px;
    padding: 20px;

    .cvv-input {
      width: 60px;
      height: 35px;
      border: 1px solid #e7e8ea;
      border-radius: 11px;
      margin-right: 5vw;
      //text-align: 'center';
      letter-spacing: 3px;
    }

    .card-number {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #364bc6;
      margin-bottom: 0;
      position: relative;
      right: 110px;
    }

    .masked-number {
      font-family: "Open Sans", sans-serif;
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 20px;
      color: #364bc6;
    }
  }

  .card-radio-btn {
    margin: 10px 0;

    .ant-radio {
      position: absolute;
      top: 0;
      right: 20px;
      bottom: 0;
      display: flex;
      align-items: center;
    }

    .ant-radio-checked::after {
      width: 16px;
      height: 16px;
      top: 0;
      bottom: 0;
      margin: auto;
    }
  }

  .card-radio-mobile-btn {
    margin: 10px 0;

    .ant-radio-inner {
      position: relative;
      top: -46px;
      left: 21em;
    }

    .ant-radio-input {
      position: relative;
      top: -15px;
      left: 31em !important;
    }

    .ant-radio-checked::after {
      top: -5vh;
      left: 31em;
    }
  }

  .card-select-text {
    margin-left: 25px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #64748b;
  }

  .price-value {
    font-weight: 700;
  }

  .total {
    .price-title {
      font-weight: 700;
    }

    .price-value {
      font-weight: 700;
      color: #364bc6;
    }
  }
}

.cancel-modal-container {
  .notes {
    height: 15vh;
  }

  .upload-text {
    font-weight: 400;
    font-size: 16px;
    line-height: 28px;
    color: #64748b;
  }

  .appointment-start-btn {
    width: 220px !important;
    height: 60px;
  }

  .upload-btn {
    width: 47% !important;
  }

  .upload-cancel-btn {
    width: 47% !important;
  }

  .upload-img-wrapper {
    width: 340px;
    height: 235px;
    border: 1px solid #e7e8ea;
    border-radius: 20px;
    margin-left: 6px;

    .upload-img-btn {
      width: 40% !important;
    }
  }

  .ant-checkbox-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 96%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    letter-spacing: 0.3799999952316284px;
    color: #0F172A;

    .ant-checkbox-inner {
      width: 24px;
      height: 24px;
      border: 1px solid #E7E8EA;
    }

    .ant-checkbox-checked .ant-checkbox-inner::after {
      top: 50%;
      left: 30%;
    }
  }

  .select-checkbox {
    .ant-checkbox + span {
      position: relative;
      right:75px;
    }
  }

  .cancel-text {
    font-weight: 600;
    font-size: 20px;
    line-height: 28px;
    color: #0f172a;
    margin-top: 30px;
  }

  button {
    width: 50px;
  }
}

.complete-modal-container {
  .notes {
    height: 30vh;
  }

  .expense-btn {
    width: 140px;
    height: 50px !important;
    background: #364bc6;
    color: #fff;
    border-radius: 41px;
  }

  .complete-btn {
    width: 160px !important;
    height: 50px !important;
    border-radius: 41px;
  }
}

.my-caregivers-slots-wrapper {
  .slots-text {
    width: 107px;
    height: 32px;
    background-color: #ebedfa;
    color: #364bc6;
    border-radius: 7px;
    padding: 5px;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: 600;
    margin-top: 13px;
    cursor: pointer;

    &__active {
      background: #364bc6 !important;
      color: #fff !important;

      img {
        color: #fff !important;
      }
    }

    img {
      left: 7px;
      position: relative;
      bottom: 2px;
    }
  }
}

.availability-modal-container {
  .availability-wrapper {
    .modal-title {
      margin-bottom: -13px;
      font-weight: 600;
      font-size: 28px;
      line-height: 40px;
      text-align: center;
      color: #0f172a;
    }

    .title-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .back-btn {
        margin-bottom: 8px;
        cursor: pointer;
      }

      .modal-title {
        font-weight: 600;
        font-size: 28px;
        line-height: 40px;
        text-align: center;
        color: #0f172a;
      }
    }

    .react-calendar {
      border: none;
      margin-left: 8px;

      &__navigation__prev-button:disabled,
      &__navigation__next-button:disabled {
        color: #e7e8ea !important;
      }

      &__navigation__prev2-button,
      &__navigation__next2-button {
        visibility: hidden;
        display: none;
      }

      &__tile:disabled {
        color: #e7e8ea !important;
        background: transparent;
      }

      &__tile--now {
        background: #fff;

        abbr {
          color: #000 !important;
        }
      }

      &__month-view__days__day--weekend {
        color: #000;
      }

      &__month-view__days__day--neighboringMonth {
        color: #757575 !important;
      }

      &__navigation {
        width: 380px;

        &__arrow {
          font-size: 30px;
        }

        &__label__labelText {
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
        }
      }

      &__viewContainer {
        width: 380px;
      }

      // &__tile {
      //     color: #000;
      // }
      &__month-view__weekdays__weekday {
        abbr {
          font-weight: 600;
          font-size: 13px;
          line-height: 18px;
          color: #64748b;
        }
      }

      &__tile--active {
        background: #006edc;

        abbr {
          color: #fff !important;
        }
      }

      &__month-view__days__day {
        display: flex;
        border-radius: 50%;
        flex: 0 0 10.2857% !important;
        align-items: center;
        justify-content: center;

        abbr {
          align-items: center;
          display: flex;
          width: 40px;
          height: 32px;
          justify-content: center;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
        }
      }
    }

    .availability-timing-wrapper {
      .selected-date {
        margin-top: 10px;
        text-align: left;
        font-weight: 600;
        font-size: 20px;
        line-height: 24px;
      }

      .slots-wrapper {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
      }

      .slot-parent {
        background: rgba(54, 75, 198, 0.1);
        border-radius: 15px;
        margin: 10px;
      }

      .slot-tag {
        margin-bottom: 0;
        color: #364bc6;
        font-weight: 400;
        font-size: 17px;
        line-height: 28px;
        padding: 10px;
      }
    }
  }
}

// new calendar package design code
.date-range-picker-wrapper {
  .rmdp-wrapper.rmdp-shadow {
    border: 1px solid #e7e8ea;
    box-shadow: none;
  }

  .rmdp-calendar {
    width: 435px;
    padding: 0 15px;

    .rmdp-header {
      margin-top: 18px;
    }

    .rmdp-arrow {
      border: solid #0f172a;
      border-width: 0 2px 2px 0;
      padding: 4px;
    }

    .rmdp-header-values span {
      font-weight: 700;
      font-size: 20px;
      line-height: 16px;
      text-align: center;
      color: #0f172a;
    }

    .rmdp-year-picker .rmdp-today span {
      background-color: #fff;
      color: #000;
    }

    .rmdp-year-picker .rmdp-selected {
      border-radius: 12px;
      padding: 2px 0;
      background-color: #364bc6;
      color: #fff;
    }

    .rmdp-day.rmdp-selected span:not(.highlight) {
      background-color: #364bc6;
      box-shadow: 0 0 3px #8798ad;
      color: #fff;
    }

    .rmdp-month-picker .rmdp-today span {
      background-color: #fff;
      color: #000;
    }

    .rmdp-month-picker .rmdp-selected {
      border-radius: 12px;
      padding: 2px 0;
      background-color: #364bc6;
      color: #fff;
    }

    .rmdp-day-picker {
      width: 410px;
      // height: 150px;
      padding: 0 11px;

      .rmdp-disabled {
        opacity: 0.5;
        color: gray;
      }

      .rmdp-range {
        box-shadow: none;
        background-color: #e7e8ea;
      }

      .rmdp-range.start,
      .rmdp-range.end {
        background-color: #364bc6;
      }

      .rmdp-week {
        width: 386px;
        margin: 8px 0;
      }

      .rmdp-day {
        height: 52px;
        width: 58px;

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 20px;
          color: #0f172a;
        }
      }

      .rmdp-day.rmdp-range.start span,
      .rmdp-day.rmdp-range.end span {
        color: #fff;
        font-weight: 700;
      }

      .rmdp-day.rmdp-today span {
        background: #64748b;
      }

      .rmdp-day.rmdp-range span {
        color: #0f172a;
        font-weight: 700;
      }

      .rmdp-today span {
        background: white;
        color: #000;
      }

      .rmdp-today.rmdp-range span {
        background: #e7e8ea;
        color: #0f1762;
        font-weight: 700;
      }

      .rmdp-today.rmdp-range.start span {
        background: #364bc6;
        color: #fff;
        font-weight: 700;
      }

      .rmdp-week-day {
        font-weight: 400;
        font-size: 14px;
        line-height: 16px;
        color: #64748b;
      }
    }
  }
}

.image-modal-container {
  .ant-modal-content {
    padding: 0;
    // border-radius: 50%;
    height: 200px;
    width: 200px;
    margin: auto;
    background: transparent;
    box-shadow: none;
  }

  .ant-modal-body {
    padding: 0;

    img {
      height: 200px;
      width: 200px;
      // border-radius: 50%;
    }
  }

  .ant-modal-close {
    right: -40px;
    position: absolute;
    top: -30px;
    color: #fff;
  }
}

@media (max-width: 768px) {
  .amount-wrapper {
    background: #ebedfa;
    border-radius: 11px;
    width: 100% !important;
    height: auto !important;
    padding: 20px;
  }

  .appointment-nav-container {
    width: 100%;
    align-items: center;
  }
}

.notification-image {
  margin: 150px 117px 117px;
}

.date_item {
  .rdrMonthAndYearWrapper {
    height: 6px !important;
    padding-top: 20px !important;
  }
}

.details-footer-bottom-upcoming .ant-btn.cancel-btn,
.details-footer-bottom-upcoming .complete-start-btn-div {
  margin-top: 0 !important;
}

.common-btn-row.common-btn-row-choose-date {
  position: absolute;
  bottom: 15px;
  right: 15px;
  width: calc(100% - 30px);
}

.choose-date-butt-n.common-btn-row.common-btn-row-choose-date {
  position: relative;
  bottom: 0;
  right: 0;
  width: 100%;
}

// .ant-picker-dropdown {
//   // .ant-picker-panel-container {
//   .ant-picker-footer {
//     display: none !important;
//     // }
//   }
// }

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;