@media only screen and (max-width: 997px) {
    .ant-dropdown-menu {
        top: 2rem !important;
    }
    .ant-dropdown{
        left: 4px !important;
    }
    .ant-dropdown-menu-item{
        font-size: 22px !important;
        padding: 14px 33px !important;
        
    }
}
.icon-container {
    display: flex;
    transition: transform 0.5s ease;
  }
  
  .icon-container.closed {
    transform: translateX(2px);
  }
  
  .icon-container.open {
    transform: translateX(-5px);
  }
  
  .ant-dropdown-menu-submenu-popup ul, .ant-dropdown-menu-submenu-popup li {
    top: 0 !important;
  }
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;