@import "../../../common/styles/theme/variable.less";

footer {
  position: fixed;
  padding: 10px 10px 0 10px;
  bottom: 0;
  width: 100%;
  height: 40px;
  background: grey;
}
.appointment-dashboard {
  footer {
    position: fixed;
    padding: 10px 10px 0 10px;
    bottom: 0;
    width: 100%;
    height: 40px;
    background: grey;
  }

  .upload-img-wrapper {
    .upload-img-btn {
      width: 40% !important;
    }
  }

  .completed-appointment {
    opacity: 0.5;
  }

  .ant-tabs-nav-wrap {
    background: #f7f9fa;
  }

  .ant-tabs-ink-bar {
    background-color: transparent;
  }

  .ant-tabs-tab {
    background: #ffffff !important;
    border-radius: 11px !important;
    width: 140px;
    height: 50px;
    .ant-tabs-tab-btn {
      font-size: 18px;
      color: #0f172a !important;
      width: 130px;
      text-align: center;
    }
  }

  .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 0 15px !important;
  }

  .ant-tabs-tab-active {
    border-color: rgba(0, 122, 255, 0.1) !important;
    background: rgba(0, 122, 255, 0.1) !important;

    .ant-tabs-tab-btn {
      color: #0f172a !important;
    }
  }

  .no-data-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 55vh;

    .no-data-text {
      font-weight: 400;
      font-size: 20px;
      line-height: 27px;
      color: #64748b;
      margin-bottom: 0;
    }
  }

  .appointment-wrapper {
    .profile-wrapper {
      .caregiver-name {
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        color: @primary;
        margin-bottom: 3px;
      }

      .caregiver-img {
        margin: 4px 7px 0 0;
      }

      .relative-wrapper {
        .book-btn {
          padding-top: 1px;
          height: 20px;
          width: 80px;
          background: @primary;
          color: @white;
          border-radius: 11px;
          font-size: 11px;
          text-align: center;
        }

        .relative-name {
          font-weight: 600;
          font-size: 12px;
          line-height: 19px;
          color: #64748b;
          margin-left: 5px;
        }
      }
    }

    .servicelist-icons::-webkit-scrollbar {
      display: none;
    }

    .servicelist-icons {
      overflow: auto;
    }

    .Location-section {
      padding-left: 0 !important;
    }

    .card-title {
      font-weight: 400;
      font-size: 12px;
      line-height: 16px;
      color: #64748b;
    }

    .br-25 {
      border-radius: 25px;
    }

    .caregiver-btn {
      width: 122px;
      height: 35px;
      background: #364bc6;
      color: #fff;
      cursor: default;
      margin-right: 10px;
    }

    .caregiver-btn:hover {
      background: #364bc6;
      color: #fff;
    }

    .track-btn {
      width: 122px;
      height: 35px;
      background: #00b383;
      color: #fff;
    }

    .card-value {
      margin: 0 0 3px 12px;
    }

    .pending {
      width: 122px;
      height: 35px;
      background: #fde8d4;
      color: #f68b29;
      border-radius: 11px;
      font-weight: 600;
    }

    .ongoing {
      width: 122px;
      height: 35px;
      background: #ccf0e6;
      color: #00b383;
      border-radius: 11px;
      font-weight: 600;
    }

    .extension-pending {
      width: 150px;
      height: 35px;
      background: #fff4ea;
      color: #f68b29;
      border-radius: 11px;
      font-weight: 600;
      margin-right: 15px;
    }

    .completed {
      width: 122px;
      height: 35px;
      background: #d7dbf4;
      color: #4054c8;
      border-radius: 11px;
      font-weight: 600;
    }

    .cancelled {
      width: 122px;
      height: 35px;
      background: #ffe6e6;
      color: #ff0000;
      border-radius: 11px;
      font-weight: 600;
    }

    .declined {
      width: 122px;
      height: 35px;
      background: #ffe6e6;
      color: #ff0000;
      border-radius: 11px;
      font-weight: 600;
    }

    .upcoming {
      width: 122px;
      height: 35px;
      background: #d7dbf4;
      color: #4054c8;
      border-radius: 11px;
      font-weight: 600;
    }
  }

  .pending {
    width: 140px;
    height: 50px;
    background: #fde8d4;
    color: #f68b29;
    border-radius: 41px;
    font-weight: 600;
  }

  .ongoing {
    width: 140px;
    height: 50px;
    background: #ccf0e6;
    color: #00b383;
    border-radius: 41px;
    font-weight: 600;
  }

  .extension-pending {
    width: 160px;
    height: 50px;
    background: #fff4ea;
    color: #f68b29;
    border-radius: 41px;
    font-weight: 600;
  }

  .completed {
    width: 140px;
    height: 50px;
    background: #d7dbf4;
    color: #4054c8;
    border-radius: 41px;
    font-weight: 600;
  }

  .cancelled {
    width: 140px;
    height: 50px;
    background: #ffe6e6;
    color: #ff0000;
    border-radius: 41px;
    font-weight: 600;
  }

  .declined {
    width: 140px;
    height: 50px;
    background: #ffe6e6;
    color: #ff0000;
    border-radius: 41px;
    font-weight: 600;
  }

  .upcoming {
    width: 140px;
    height: 50px;
    background: #d7dbf4;
    color: #4054c8;
    border-radius: 41px;
    font-weight: 600;
  }

  .cancel-btn {
    background: #ffcccc !important;
    color: #f00 !important;
  }
}

.give-reviews {
  &-wrapper {
    &-title {
      font-weight: 700;
      font-size: 20px;
      line-height: 40px;
    }

    .profile {
      .profile-pic {
        border-radius: 50%;
      }

      .profile-name {
        font-weight: 700;
        margin-top: 11px;
        font-size: 15px;
      }

      .verified-icon {
        position: relative;
        bottom: 1px;
        left: 2px;
      }
    }

    .star-wrapper {
      width: 72%;
      margin: 0 0 15px 35px;
    }

    .give-review-input {
      height: 6em;
      overflow: hidden;
      padding: 10px;
    }

    .reviews-cancel-btn {
      height: 45px !important;
      width: 38% !important;
      font-size: 16px !important;
    }

    .reviews-submit-btn {
      font-size: 18px !important;
      font-weight: 500 !important;
      width: 62% !important;
      height: 45px !important;
    }
  }
}

.caregiver-review-container {
  .reviewsListing {
    // background: #ffffff;
    // box-shadow: 0 10px 60px rgba(15, 23, 42, 0.1);
    border-radius: 20px;
    border: none;
  }
}

.Addexpense_details {
  .ant-input {
    border: none !important;
    height: 30px !important;
    width: 150px;
  }

  .ant-input-number-input-wrap {
    border: none !important;
    height: 30px !important;
    width: 73px !important;
  }

  .ant-input-number {
    width: 30% !important;
    border: none !important;
  }
}

@media (max-width: 768px) {
  .appointment-wrapper {
    .profile-wrapper {
      .caregiver-name {
        display: flex !important;
        font-size: 15px !important;
      }
    }
  }

  .appointment-tab {
    .appointment-wrapper {
      width: 100%;
    }

    .relative-wrapper {
      display: flex;
      min-width: 100% !important;
      flex-wrap: nowrap !important;

      .relative-name {
        display: flex;
        flex-wrap: nowrap !important;
        font-size: 12px !important;
      }

      .book-btn {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .servicelist-wrapper {
        display: flex;
        text-align: initial;
      }

      .detailing {
        text-align: left;
        padding-left: 0 !important;
      }
    }

    .Location-section {
      padding-left: 0 !important;
    }
  }

  .ant-tabs-tab {
    padding: 5px 10px;
    width: 10%;
  }

  .ant-tabs-tab-btn {
    font-size: 14px;
    width: 100%;
  }

  .card-title {
    text-align: left;
  }

  .profile-detail-title {
    display: block !important;
  }

  .upload-img-wrapper {
    width: 100% !important;
  }
}

.Expenses-title {
  font-weight: 700;
  font-size: 25px !important;
}

.username {
  .bookmark-button {
    position: relative;
    top: -3px;
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;