@media only screen and (min-width: 320px) and (max-width: 767px) {
  .map-content,
  .payment_modal,
  .profileselect {
    max-height: initial;
  }
  .message-main ol li .ant-breadcrumb-link, .main-content-nav ol li .ant-breadcrumb-link {
    font-size: 18px;
}
  .welcome-header {
    font-size: 30px;
    text-align: center;
    padding: 0 30px;
  }
  .btn-black.i-understand-btn{
  width: 100%;
}
  .details-footer-bottom.details-footer-bottom-upcoming .ant-btn.auth-action-btn {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .Done_btn,
  .additional-details-btn-item .ant-btn,
  .confirm-relative-modal .ant-btn,
  .map-content .google-next-button {
    width: 13rem !important;
  }
  .align-center-mob,
  .appointment-tab .relative-wrapper,
  .mob-d-flex .ant-col.d-flex.ant-col-lg-6.ant-col-xl-4,
  .parent-root .card-body {
    align-items: center;
  }
  .map-content .map-actions {
    bottom: 60px;
    left: 30px;
    right: 0;
    max-width: calc(100% - 60px);
    width: calc(100% - 60px);
  }
  .hourlyrate label p {
    margin-bottom: 10px !important;
  }
  .ant-slider-mark .ant-slider-mark-text:last-child {
    left: 96% !important;
    width: 60px;
  }
  .footer,
  .payment-wrapper .card-radio-btn .ant-radio-input {
    left: 0 !important;
  }
  .map-content .google-next-button {
    height: 50px !important;
    margin-bottom: 0 !important;
    width: 10rem !important;
  }
  .ml-mob-5 {
    margin-left: 5px !important;
  }
  .mr-mob-0 {
    margin-right: 0 !important;
  }
  .choose-relative-modal-div label.ant-radio-button-wrapper.custom-radio,
  .full-width {
    max-width: 100% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .choose-relative-modal-div label.ant-radio-button-wrapper.custom-radio {
    border-left: 1px solid #d9d9d9;
  }
  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover::before,
  .ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent;
  }
  .choose-relative-modal-div label.ant-radio-button-wrapper.custom-radio.ant-radio-button-wrapper-checked {
    border: 1px solid #5b71d4;
  }
  .welcome-subheader {
    font-size: 16px;
    padding-top: 10px;
    text-align: center;
    max-width: 100% !important;
  }
  #signup-form {
    width: 100%;
}
  .ant-modal.availability-modal .ant-modal-body,
  .radio-box-div {
    padding: 15px 0;
  }
  .center-container {
    padding: 30px 0;
  }
  .main-div-top,
  .px-3-mob {
    padding-left: 15px;
    padding-right: 15px;
  }
  .ant-radio-button-wrapper.custom-radio {
    font-size: 15px !important;
    padding: 19px !important;
    height: auto;
  }
  .ant-radio-button-wrapper.custom-radio img {
    width: 60px;
    height: 60px;
  }
  .form-row.form-row-div .border {
    border: 1px solid #ddd !important;
    border-radius: 8px !important;
  }
  .form-row.form-row-div .pointer-add-pp,
  .text-center-mob {
    text-align: center !important;
  }
  .ant-btn.common-theme-btn {
    position: relative !important;
    text-align: center;
    justify-content: center !important;
    float: left;
    margin-top: 15%;
    font-size: 20px !important;
  }
  .careGiver_TotalEarnings span.ant-select-arrow,
  .choose-date-div p,
  .d-flex.justify-content-between.mt-1,
  .forgot-password-text,
  .mt-mob-0,
  .servicelist-wrapper,
  .work-details-container .responsive-top-margin {
    margin-top: 0 !important;
  }
  .welcome-container {
        min-height: auto;
        border: none;
        display: block;
        max-width: 100%;
        padding: 0 15px;
  }
  .NotificationsContent .Notifications,
  .date-time-div-avail-action .ant-image {
    position: relative;
  }
  .date-time-div-avail-action .ant-image:first-child {
    right: -22px;
  }
  .date-time-div-avail-action .ant-image:nth-child(2) {
    right: 38px;
  }
  .Add_Expenses_Form .ant-input-number-input-wrap,
  .ExpenseImg-custom .transform-component-module_wrapper__SPB86,
  .NotificationsContent,
  .ant-modal-content .ant-modal-body .ant-col.ant-col-14 .ant-btn-round.ant-btn-lg,
  .appointment-dashboard .ant-tabs-nav-list,
  .availability-modal-container .availability-wrapper .react-calendar__navigation,
  .availability-modal-container .availability-wrapper .react-calendar__viewContainer,
  .banking-info-img,
  .caregivers-wrapper .no-data-found h3,
  .choose-service-btn-row .mobile-flex-custom,
  .client_Expenses .ant-table-tbody > tr > td .ant-space.ant-space-horizontal,
  .custom-radio-space .ant-space-item,
  .date-range-picker-wrapper .rmdp-calendar,
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker,
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker .rmdp-week,
  .expenses_payment_cardContainer .ant-radio-group,
  .parent-avalability .availability-contents div,
  .parent-root,
  .password-strength-meter,
  .payment-list-div .scroll-wrapper .ant-radio-group,
  .payment-wrapper .card-radio-btn,
  .profilegender .ant-space-item .ant-radio-button-wrapper span,
  .relative-container,
  .relative-list-container-web .relative-list .relative-wrapper .address-wrapper,
  .relative-list-container-web .relative-list .relative-wrapper .relative-profile,
  .videoimage,
  .w-100-mob {
    width: 100%;
  }
  .App.availability-date-picker div:nth-child(2),
  .App.date-picker {
    overflow: auto;
  }
  .common-row .d-flex.justify-content-between {
    margin-bottom: 3px !important;
  }
  .welcome-subheader br{
    display: none;
  }
  .text-left-mobile{
    text-align: left !important;
  }
  .text-left-mobile .welcome-header {
    text-align: center !important;
    padding: 0;
  }
  #name {
    font-size: 22px;
    line-height: 30px;
}
  .common-card-modal {
    margin: 0 !important;
    padding: 0 !important;
  }
  .basic-details-row,
  .footer-text-auth,
  .starting-time-div,
  .username {
    margin-top: 15px;
  }
  .ant-modal.availability-modal .ant-modal-body div.m-4 div:first-child,
  .custom-modal.ant-modal .ant-modal-content,
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker,
  .steps-content .caregiverhourlyrate .ant-form-item-control .ant-form-item-control-input {
    padding: 0;
  }
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker{
    justify-content: center;
  }
  .custom-modal.ant-modal .ant-modal-content .ant-modal-body {
    padding: 0;
    height: auto;
  }
  .termsheadings h1 {
    font-size: 24px;
  }
  .appointment-nav-container .ant-breadcrumb li,
  .common-condition-details p,
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker .rmdp-day span,
  .profile-details-wrapper .profile-detail-title,
  .settings-editprofile .ant-slider-mark-text-active,
  .stripeinfo p,
  .timing-container .ant-picker-large .ant-picker-input > input {
    font-size: 13px;
  }
  .appointment-dashboard-cP.settings-editprofile .caregiver-profile-settings-card h4,
  .careGiver_TotalEarnings .Earnings_cardbody .Earnings_amountType,
  .common-condition-details h2,
  .common-condition-details h3,
  .map-content h2,
  .stripemodal .ant-typography h2,
  .stripemodal h4.ant-typography {
    font-size: 16px;
  }
  .choose-date-div p {
    font-size: 16px !important;
    margin-bottom: 0;
    text-align: left;
  }
  .px-3.mb-2.fw-semibold.px-mob-0.text-left-mobile.fs-4{
    margin-bottom: 0 !important;
  }
  .btn-mt-20,
  .emailref {
    margin-top: 20px;
  }
  .welcome-container .password-strength-meter-progress {
    padding-left: 10px;
    width: 52% !important;
  }
  .welcome-header.verify-header {
    padding: 0;
    font-size: 26px;
  }
  .ongoing-wrapper.ongoing-wrapper-scroll-mob {
    width: 100%;
    display: -webkit-box;
    height: auto !important;
    overflow: auto !important;
    padding-bottom: 10px;
  }
  .ongoing-wrapper.ongoing-wrapper-scroll-mob::-webkit-scrollbar {
    width: 0;
  }
  .ongoing-wrapper.ongoing-wrapper-scroll-mob::-webkit-scrollbar-track {
    box-shadow: none;
  }
  .ongoing-wrapper.ongoing-wrapper-scroll-mob::-webkit-scrollbar-thumb {
    background-color: transparent;
    outline: 0;
  }
  .caregivers-choose-custom .my-caregiver-container-mob,
  .my-caregiver-container .my-caregiver-container-mob {
    height: 76vh;
    overflow: auto;
  }
  .caregivers-choose-custom .my-caregiver-container-mob {
    height: 59vh;
  }
  .ongoing-wrapper.ongoing-wrapper-scroll-mob .appointment-wrapper {
    width: 100%;
    float: left;
    margin-right: 15px;
    margin-bottom: 0 !important;
  }
  .account-confirmation {
    position: relative !important;
    bottom: 0;
    width: auto;
    text-align: left;
  }
  .careGiver_TotalEarnings .Transactions_Header,
  .mob-d-flex,
  .mobile-flex-custom,
  .welcome-container .password-strength-meter-label {
    display: flex;
    align-items: center;
  }
  .ant-modal.availability-modal .ant-modal-body div.m-4 div:nth-child(4) div:first-child > div:first-child,
  .welcome-container .password-strength-meter-label span {
    padding-left: 5px;
  }
  .phone-number-custom {
  padding-bottom: 20px;
        margin-top: 30px !important;
  }
  .phone-number-custom .form-control.ant-input {
    height: 45px;
    line-height: 40px;
  }
  .email-container {
    max-width: calc(100% - 30px);
    position: inherit;
    margin-top: 6rem;
    height: auto;
    padding: 30px 0;
  }
  .reqgenaratedMsg > h1 {
    font-size: 26px;
    margin-top: 30px;
  }
  .form-row.form-row-div {
    margin-top: 2rem;
    height: auto;
    overflow-y: auto;
    max-height: calc(100vh - 150px);
    margin-bottom: 0;
  }
  .availability-main-div,
  .chart-card-main,
  .choose-relative-modal-div .ant-modal-body,
  .date-range-picker-wrapper.date-range-picker-wrapper-custom,
  .geo-location-div-inner,
  .h-mob-auto,
  .payment-wrapper .amount-wrapper.booking-confirm,
  .profile-header-row,
  .view-tutorial-inner {
    height: auto !important;
  }
  .appoinment_card_border-month,
  .carddetailsinfo .ant-card,
  .chart-card-main .chart .recharts-wrapper,
  .chart-card-main .chart .recharts-wrapper svg,
  .expense_reject_No,
  .form-row .ant-input,
  .geo-location-div-inner #circle-example > div > div,
  .geo-location-div-inner #circle-example > div > div > img,
  .message-container .message-wrapper .left-child .friend-list,
  .message-container .message-wrapper .left-child .latest-message,
  .message-container .message-wrapper .left-child .search-chat,
  .profile-form .ant-form-item,
  .profile-header-row .ant-form-item,
  .settings-editprofile .passwordinput,
  .subform-1 {
    width: 100% !important;
  }
  .info-tag img {
    top: 15px;
    position: relative;
    z-index: 99;
  }
  .form-row .ant-radio-button-wrapper.custom-radio img,
  .profilegender .ant-space-item .ant-radio-button-wrapper img {
    width: auto;
    height: auto;
  }
  .justify-content-space-between,
  .profilegender .ant-space-item .ant-radio-button-wrapper {
    justify-content: space-between;
  }
  .profilegender .ant-space-item .ant-radio-button-wrapper span span,
  .profilegender .ant-space-item .ant-radio-button-wrapper span.ant-radio-button,
  .profilegender .ant-space-item .ant-radio-button-wrapper span.ant-radio-button .ant-radio-button-inner {
    width: auto;
  }
  span.ant-select-arrow {
    top: 30px;
  }
  .profile-details-wrapper .role-btn.role-mobile-btn{
        width: auto !important;
        margin-right: 0 !important;
        position: absolute;
        right: 0;
        top: 0;
  }
  .profileselect {
    min-height: inherit;
    padding-bottom: 0;
  }
  .additional-detail-wrapper .responsive-margin-bottom,
  .appointment-dashboard .no-data-wrapper .no-data-text,
  .mob-d-flex p,
  .profile-details-wrapper .time-details-row .profile-detail-val,
  .profile-details-wrapper .username p,
  .service-section {
    margin-bottom: 0;
  }
  .ant-form-item.other-language-input,
  .mb-mob-0,
  .work-details-container .responsive-bottom-margin,
  .work-details-container .responsive-top-margin p {
    margin-bottom: 0 !important;
  }
  .ant-image-img.plus-add-icon,
  .ml-left-0,
  .ms-mob-0,
  .profile-header-row .ant-form-item.mobile-btn-item {
    margin-left: 0 !important;
  }
  .availability-date-picker,
  .profile-details,
  .timing-container,
  .timing-container-custom {
    padding-left: 0 !important;
  }
  .ant-collapse.custom-collapse-link {
    border: 0;
    border-radius: 0;
    background: 0 0 !important;
  }
  .ant-collapse.custom-collapse-link .ant-collapse-item {
    border-radius: 0;
    background: 0 0 !important;
    border: 0;
  }
  .ant-collapse.custom-collapse-link .ant-collapse-item .ant-collapse-header {
    border-radius: 0;
    padding: 0;
    background: 0 0 !important;
    font-size: 18px;
  }
  .ant-collapse.custom-collapse-link .ant-collapse-item .ant-collapse-header .ant-collapse-expand-icon .ant-collapse-arrow {
    position: absolute;
    right: 5px;
    top: 0;
    bottom: 0;
    margin: auto;
    display: flex;
    align-items: center;
    font-size: 20px;
  }
  .ant-collapse.custom-collapse-link .ant-collapse-content {
    border-radius: 0;
    border: 0;
    background-color: transparent !important;
  }
  .appointment-dashboard-clientprofile.clientprofile,
  .footer,
  .settings-editprofile.bankdetails.appointment-dashboard-cP,
  .settings-editprofile.changepwd {
    background: #e4e4e4;
  }
  .complete-modal-container .details-footer-bottom-appointment .complete-btn,
  .complete-modal-container .details-footer-bottom-appointment .expense-btn {
    width: 48% !important;
    margin: 0 !important;
    padding: 0;
  }
  .availability-main-div .parent-avalability .scroll-wrapper {
    height: calc(71vh - 200px) !important;
  }
  .ant-collapse.custom-collapse-link .ant-collapse-content .ant-collapse-content-box {
    padding: 5px 0;
    background-color: transparent !important;
  }
  .ant-collapse.custom-collapse-link .ant-collapse-content .ant-collapse-content-box ul,
  .ant-modal.availability-modal .ant-modal-body div.m-4 div:nth-child(4) div:first-child > div:nth-child(2) {
    padding-left: 15px;
  }
  .ant-collapse.custom-collapse-link .ant-collapse-content .ant-collapse-content-box ul li {
    list-style-type: none;
  }
  .ant-collapse.custom-collapse-link .ant-collapse-content .ant-collapse-content-box ul li a {
    font-size: 18px;
    padding: 4px 0;
    display: inline-block;
    color: #444;
    font-weight: 400;
  }
  label.upload-file-field {
    border: 1px solid #ccc !important;
  }
  .steps-content .caregiverhourlyrate .ant-slider-mark .ant-slider-mark-text-active:nth-child(8) {
    left: auto !important;
    right: -35px;
    width: 55px;
  }
  .steps-content .caregiverhourlyrate .ant-slider-mark .ant-slider-mark-text-active:nth-child(7) {
    width: 52px;
  }
  .ant-modal.stripemodal {
    width: 100% !important;
    padding: 0 !important;
  }
  .stripemodal .ant-modal-body {
    padding: 10px 15px 0;
  }
  .ant-modal.availability-modal,
  .chart-card-main h1 {
    padding-bottom: 0 !important;
  }
  .stripemodal .ant-typography h3 {
    font-size: 17px;
  }
  .chart-card-main h1 {
    margin: 0 !important;
    font-size: 16px !important;
  }
  .chart-card-main span.ant-select-arrow {
    top: 18px;
  }
  .map-content .ant-form label,
  .profile-details-wrapper .Pending-appointment-content .service-offer-text,
  .stripeinfo td {
    font-size: 12px;
    line-height: 18px;
  }
  .stripemodal .ant-checkbox-wrapper {
    margin: 0 0 20px !important;
  }
  .parent-avalability .availability-contents {
    max-height: 90vh !important;
  }
  .parent-avalability .availability-contents div.scroll-wrapper div:first-child {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 100%;
  }
  .ant-modal.availability-modal .ant-modal-body div.m-4 div:first-child .ant-picker,
  .ant-modal.availability-modal .ant-modal-body div:first-child,
  .clientsettings.client-setting-custom-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn,
  .common-row {
    padding: 0 15px;
  }
  .ant-select-single.ant-select-lg:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-item,
  .clientsettings.client-setting-custom-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn .d-flex.align-items-center,
  .text-left-mob {
    text-align: left !important;
  }
  .clientsettings.client-setting-custom-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn .settingnames {
    width: 100%;
    display: flex !important;
    align-items: center;
    justify-content: space-between;
  }
  .ant-modal.availability-modal .ant-modal-body div.m-4 {
    margin-left: 15px !important;
    margin-right: 15px !important;
  }
  .banking-title {
    font-size: 20px;
    width: 50%;
    margin: auto;
    line-height: 27px;
    position: relative;
  }
  .ant-modal.availability-modal .ant-modal-body .ant-picker,
  .common-btn-row .auth-action-btn {
    width: 48% !important;
  }
  .profile-details-wrapper .service-offer-card .date-card {
    padding: 5px 10px;
    font-size: 12px;
  }
  .banking-title img {
    position: absolute;
    top: 30px;
    right: -25px;
  }
  .responsive-button.understand-btn,
  .understandbtn {
    margin: auto !important;
  }
  .steps-content {
    padding: 0 0 10px;
    border: none;
  }
  .pr-0-mob,
  .profile-details,
  .skip-btn-mob {
    padding-right: 0 !important;
  }
  .ant-form-vertical,
  .appointment-dashboard-cP.settings-editprofile,
  .contactimage,
  .contactinfo,
  .expensesItems .ant-table-tbody > tr > td,
  .expenses_payment_cardContainer {
    height: auto;
  }
  .mobile-btn-item .ant-btn {
    margin-left: auto !important;
  }
  .additional-detail-wrapper h1,
  .work-details-container h1 {
    margin-top: 20px !important;
    font-size: 20px;
  }
  .steps-content .hourlyrate .ant-slider-mark .ant-slider-mark-text-active:first-child {
    left: 6% !important;
  }
  .ant-slider-mark .ant-slider-mark-text:first-child,
  .ant-slider-mark .ant-slider-mark-text:last-child,
  .payment-wrapper .amount-wrapper .price-title,
  .payment-wrapper .price-value {
    font-size: 14px;
  }
  .additional-details-row,
  .work-details-form-row {
    height: calc(100vh - 120px) !important;
  }
  .work-details-container .work-next-button {
    width: 13rem;
    margin-top: 30px !important;
  }
  .work-details-container {
    height: auto;
    overflow: auto;
    max-width: 100% !important;
  }
  .additional-detail-wrapper {
    max-height: initial;
    overflow: auto;
    max-width: 100% !important;
  }
  .min-h-inhret.careGiver-row .scroll-wrapper,
  .tab-height-auto {
    overflow: auto !important;
    height: auto !important;
  }
  .mx-4.w-100.main-content, .ms-4.w-100.message-inner-row, .mx-4.message-inner-row.w-100, .contact-us-main {
    padding: 0;
    margin: 0 !important;
    width: 100% !important;
}
  .main-content-appoinment .responsive-padding{
    padding:15px;
  }
  .pt-5.d-flex.profile-details-wrapper{
    padding-top: 15px !important;
  }
  .extension-request-tag{
      position: absolute;
      right: 0;
      bottom: -25px;
      width: 150px;
      margin-right: 0 !important;
      font-size: 12px;
  }
  .mobile-h4,
  .mx-mob-0 {
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .client_Expenses,
  .main-fluid,
  .profile-details-wrapper,
  .skip-btn-mob {
    padding-top: 0 !important;
  }
  .add-avail-h4 {
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
  .ant-modal.availability-modal .ant-modal-body div.m-4 div.ant-picker:first-child {
    padding: 4px 10px;
  }
  .ant-btn-lg,
  .btn-black,
  .btn-black:disabled {
    font-size: 1rem !important;
  }
  .clientsettings.client-setting-custom-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab .anticon {
    margin-left: 20px !important;
  }
  .ant-form-item.other-language-input .ant-input {
    width: 100% !important;
    margin-left: 0 !important;
    height: 58px !important;
    font-size: 16px;
  }
  .reset-password-main {
    text-align: left;
    padding: 0 15px;
  }
  .reset-password-main .welcome-header {
    padding: 0;
    text-align: center;
  }
  .back-sign-in,
  .choose-relative-modal-div .relative-name,
  .logoutmodal h2{
     text-align: center;
  }
  .profile-details-wrapper .time-details-row .profile-detail-title {
    text-align: left;
  }
      .rmdp-day, .rmdp-week-day {
        height: 48px;
        width: 48px;
    }
  .choose-service-row-main .scroll-wrapper{
    height: calc(100vh - 432px) !important;
  }
  .pb-15-mobile,
  .reset-password-div .ant-form-item {
    padding-bottom: 15px;
  }
  .ant-divider-vertical,
  .caregiver-profile-settings-card .ant-slider-mark-text,
  .contactimage,
  .d-none-mob,
  .imageicons,
  .main-logo,
  .playmodal .ant-modal-close,
  .sidebar {
    display: none;
  }
  .clientsettings .ant-tabs-tab,
  .companion-details .ant-row,
  .dateofbirth,
  .details-footer-bottom .ant-btn.auth-action-btn.my-mob-0,
  .m-mob-0,
  .main-content,
  .main-content-nav,
  .message-inner-row,
  .view-tutorial-inner .ant-row {
    margin: 0 !important;
  }
  .appointment-dashboard .appointment-wrapper .profile-wrapper .relative-wrapper .book-btn {
    padding-top: 0;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 85px;
  }
  .phone-number-details .ant-input-affix-wrapper {
    height: 47px;
  }
  .phone-number-details .react-tel-input .form-control {
    height: 41px;
  }
  .client-card-top,
  .detailing-div,
  .mob-mb-3,
  .ratings-card,
  .servicelist-wrapper-custom {
    margin-bottom: 8px;
  }
  .responsive-mobile-card-wrapper{
    padding-bottom: 5px !important;
  }
  .detailing-div p {
    width: 100%;
    text-align: left;
  }
  .footer {
    width: 100%;
    font-size: 16px;
    height: auto;
    bottom: 0;
    padding: 12px 0;
    display: none;
  }
  .appointment-nav-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #fff;
    z-index: 999;
    padding: 12px 15px;
    height: 65px;
    align-items: center;
  }
  .appointment-nav-container .ant-breadcrumb {
    position: absolute;
    bottom: 7px;
  }
  .appointment-dashboard .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 16px;
    width: 100%;
  }
  .mobile-time-logo {
    height: 35px;
  }
  .menu-hamburger-btn {
    height: auto;
    border: none;
    padding: 0;
    box-shadow: none;
  }
  .menu-hamburger-btn img {
    height: 25px;
  }
  .status-btn-div {
    margin-top: 0;
    position: absolute;
    top: 10px;
    right: 10px;
    flex-direction: column;
    display: flex;
    align-items: flex-end;
  }
  .appointment-dashboard .appointment-wrapper .status-btn-div .ant-btn {
        width: auto !important;
        font-size: 10px;
        height: 28px;
        margin: 3px 0;
        border-radius: 6px;
        padding: 0 5px;
  }
  .appointment-dashboard .appointment-wrapper .extension-pending {
    width: auto;
    height: 32px;
    margin-right: 0;
    font-size: 10px;
    padding: 0 10px;
}
  .ant-dropdown.header-dropdown-custom {
    left: 0 !important;
    top: 60px !important;
    min-width: 100% !important;
    z-index: 999;
    position: fixed;
  }
  .ant-dropdown.header-dropdown-custom .ant-dropdown-menu {
    box-shadow: none;
    border-radius: 0;
    top: -5px !important;
    padding-bottom: 30px;
    height: calc(100vh - 70px);
    overflow: auto;
  }
  .caregivers-wrapper {
    min-height: 76vh;
  }
  .px-0-mob,
  .px-mob-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }
  .gmap-wrapper,
  .parent-root .chart-card-main .recharts-wrapper,
  .parent-root .chart-card-main .recharts-wrapper .recharts-surface {
    height: 150px !important;
  }
  .ant-dropdown.header-dropdown-custom .ant-dropdown-menu .ant-dropdown-menu-item {
    font-size: 18px !important;
    padding: 10px 15px !important;
    background-color: transparent !important;
  }
  .tab-button-custom-row{
    display: flex;
  }
  .radio-box-div {
    width: 100%;
    margin-top: 10px !important;
}
  .chat-icon-mobile{
    display: block;
  }
  .plus-add-img.plus-icon{
    display: none;
  }
  .appointment-dashboard-clientprofile.clientprofile,
  .settings-editprofile.bankdetails.appointment-dashboard-cP {
    padding: 105px 15px 60px;
    width: 100%;
  }
  .caregiver-name{
    display: flex;
    align-items: center;
  }
  .small-descripation{
    display: block;
  }
  .appointment-dashboard .appointment-wrapper .caregiver-btn.caregiver-btn-mobile{
        display: block !important;
        height: 20px;
        width: 50px !important;
        font-size: 8px;
        font-weight: 700;
        margin: 0 0 0 5px;
        padding: 0;
        border-radius: 6px;
  }
  .appointment-dashboard.appointment-dashboard-custom, .appointment-dashboard{
    padding: 65px 0 15px;
  }
  .profile-details.steps-content,
  .profile-geo-location-div {
    height: calc(100vh - 60px) !important;
  }
  .container.settings-editprofile.changepwd.appointment-dashboard-cP {
    background-color: #fff;
  }
  .appointment-dashboard-settings-p {
    padding: 90px 0 60px !important;
  }
  .careGiver-details-div,
  .clientsettings .appointment-dashboard-settings-p,
  .companion-details,
  .px-3.mx-auto.additional-detail-wrapper,
  .px-3.mx-auto.map-content {
    padding: 0 !important;
  }
  .clientsettings .appointment-dashboard-cP.settings-editprofile .caregiver-profile-settings-card {
    padding: 15px;
    height: auto !important;
  }
  .settings-editprofile.bankdetails .bankinput {
    width: 100% !important;
    padding: 15px !important;
    font-size: 14px !important;
  }
  .caregiver-review-container .reviewsListing {
    padding: 10px;
    margin-top: 15px;
    background-color: transparent;
    box-shadow: none;
  }
  .message-main.contact-main {
    min-height: 100vh !important;
  }
  .review-modal-container .client-wrapper {
    border: 1px solid #e7e8ea;
    border-radius: 11px;
    padding: 15px 20px;
    margin-top: 15px;
    box-shadow: 0 10px 60px rgba(15, 23, 42, 0.1);
    background-color: #fff;
  }
  .auth-action-btn.save-btn-bank-info {
    position: absolute !important;
    margin-top: 0 !important;
    bottom: 75px !important;
    width: 13rem;
    right: 0 !important;
    left: 0;
  }
  .appointment-dashboard-cP.settings-editprofile .caregiver-profile-settings-card {
    padding: 30px 15px;
    height: 77vh !important;
  }
  .caregiver-profile-settings-card {
    background-color: #fff;
    border-radius: 20px;
    padding: 15px 5px 30px;
    height: 82vh !important;
    overflow: inherit !important;
  }
  .caregiver-profile-settings-card .ant-slider-mark .ant-slider-mark-text-active {
    display: block !important;
    bottom: 35px;
  }
  .btn-position {
    width: 100%;
    position: relative;
    bottom: 0;
    right: 0;
    overflow: visible;
  }
  .settings-editprofile .caregiversave {
    width: 13rem;
    right: 3rem;
    margin-top: 0 !important;
  }
  .settings-editprofile {
    height: 62vh;
    padding: 20px;
    overflow: auto !important;
  }
  .settings-editprofile .circle {
    width: auto !important;
    right: 10px;
    display: flex;
    align-items: center;
  }
  .caregiver-profile-settings-card .ant-slider-mark .ant-slider-mark-text:last-child {
    left: 95% !important;
    width: 60px;
  }
  .expense_popup_cancel {
    width: 100%;
    height: 55px !important;
  }
  .date-time-div-avail,
  .payment-wrapper .card-radio-btn {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .service-card .ant-checkbox-inner {
    top: 28px;
  }
  .expensesItems .ant-table-body,
  .max-height-auto {
    max-height: inherit !important;
    overflow: inherit !important;
    height: auto !important;
  }
  .profile-details-wrapper .username {
    bottom: 0;
    left: 0;
    width: auto !important;
  }
  .c-app-card,
  .card-div,
  .caregiver-details-inner,
  .message-card,
  .radio-btn-relative-div .ant-radio-button-wrapper.custom-radio {
    padding: 15px !important;
  }
  .ant-radio-button-wrapper.ant-radio-button-wrapper-in-form-item.w-100.custom-radio.text-dark.align-items-center.border-less {
    padding: 0 15px !important;
}
  .profile-details-wrapper .service-offer-card {
    min-height: 80px;
  }
  .message-card-div.card-div {
    min-height: calc(100vh - 145px) !important;
    position: relative;
  }
  .message-change-mobile-only {
    position: absolute;
    top: 70px;
    left: 15px;
    width: calc(100% - 30px);
    opacity: 0;
    transition: 0.3s;
  }
  .right-child.message-change-mobile-only {
    opacity: 1;
    top: 10px;
    left: 0;
    width: 100%;
  }
  .user-active .message-change-mobile-only {
    opacity: 1;
  }
  .message-card-div.card-div .no-message-center {
    height: 725px;
    width: 100%;
  }
  .back-btn-message.ant-btn {
    background: 0 0;
    box-shadow: none;
    padding: 0;
    left: 15px;
    top: 0;
    border: none;
    font-size: 20px;
  }
  .message-container .message-wrapper .right-child .active-chat {
    font-size: 16px;
    margin: 0 0 0 15px;
  }
  .message-main-box {
    padding: 35px 10px 0 !important;
  }
  .message-container .ant-divider-horizontal {
    margin: 10px 0;
  }
  .message-container .message-wrapper .right-child .chat-box-wrapper .chat__container {
    height: 48vh;
  }
  .app-confirm,
  .appointment-details-top {
    max-height: 115px !important;
  }
  .message-container .message-wrapper .right-client-child .chat-box-wrapper {
    padding: 0 0 15px;
  }
  .message-container .message-wrapper .right-client-child .chat-box-wrapper .chat__container2 {
    height: 61vh;
  }
  .appointment-details-top p {
    left: 0 !important;
    text-align: left;
    bottom: 0 !important;
  }
  .appointment-details-top img {
    display: block;
  }
  .profile-details-wrapper img.bookmark-button {
    margin: 0 0 0 15px !important;
  }
  .appointment-inner {
    left: 0 !important;
    bottom: 0 !important;
    width: 100%;
  }
  .app-confirm {
    position: absolute;
    right: 0;
  }
  .appoinment_details {
    min-height: auto;
    max-height: initial;
  }
  .address-details-row,
  .ant-divider-horizontal,
  .profile-app-center {
    margin: 15px 0;
  }
  .profile-app-center{
    margin: 0 0 15px;
  }
  .address-details-row span,
  .message-container .message-wrapper .left-child .latest-message,
  .message-container .message-wrapper .left-child .list-person-name {
    text-align: left;
    width: 190%;
    display: flex;
    justify-content: space-between;
  }
  .message-container .message-wrapper .left-child .list-item {
    width: 58%;
}
  .profile-app-center .ant-col.d-flex.ant-col-lg-6.ant-col-xl-4 img {
    margin: 0 10px 0 0;
  }
  .address-details-confi,
  .right-mob-0 {
    right: 0 !important;
  }
  .details-footer-bottom {
    justify-content: space-between !important;
  }
  .details-footer-bottom .ant-btn.auth-action-btn {
    width: auto;
    padding: 5px 30px !important;
    height: 50px !important;
    font-size: 14px !important;
    margin: 10px 0 !important;
  }
  .details-footer-bottom .ant-btn.auth-action-btn.cancel-btn-custom,
  .details-footer-bottom .ant-btn.auth-action-btn.complete-start-btn,
  .details-footer-bottom .ant-btn.auth-action-btn.message-btn {
    padding: 5px 15px !important;
  }
  .details-footer-bottom .ant-btn.auth-action-btn.cancel-btn-custom {
    width: 32%;
  }
  .complete-start-btn-div {
    width: 68%;
    padding-left: 10px !important;
  }
  .details-footer-bottom .ant-btn.auth-action-btn.appointment-schedule-again-btn,
  .details-footer-bottom .ant-btn.auth-action-btn.complete-start-btn {
    width: 48%;
  }
  .details-footer-bottom .ant-btn.auth-action-btn.message-btn {
    width: 48%;
    margin: 0 0 0 10px !important;
  }
  .react-calendar__tile--active {
    width: 40px !important;
    height: 40px !important;
  }
  .dateofbirth .date {
    margin-top: 5px;
  }
  .ant-form-item.dateitem.dateitem-custom:first-child,
  .ant-form-item.dateitem.dateitem-custom:nth-child(2) {
    padding-right: 7px;
  }
  .booking-confirm-div {
    display: inline-block !important;
    margin: 10px 0;
    width: 100%;
  }
  .mobile-responsive-paragraph {
    padding-right: 25px;
    font-size: 13px;
    margin-bottom: 0 !important;
  }
  .ant-typography.profile-details-h4 {
    font-size: 15px !important;
  }
  .ant-typography.profile-details-p {
    margin-top: 2px !important;
  }
  .update-profile-slider .ant-slider-mark .ant-slider-mark-text:first-child {
    left: 20px !important;
  }
  .mobile-paragraph-checkbox,
  .role-div-mob {
    position: absolute;
    top: 0;
    right: 0;
  }
  .date-time-div-avail {
    font-size: 12px;
  }
  .playmodal .ant-modal-content {
    margin-bottom: 0;
    padding: 0;
  }
  .playmodal .ant-modal-content video {
    object-fit: cover;
  }
  .date-time-div-avail-action {
    display: flex;
    justify-content: flex-end;
    width: 70% !important;
  }
  .booking-confirm-text {
    font-size: 18px !important;
    text-align: center;
    width: 100%;
    margin-bottom: 0 !important;
  }
  .profile-details-wrapper .profile-detail-title.booking-confirm-time {
    margin-right: 0 !important;
    width: 100%;
    padding-left: 0;
    text-align: center;
  }
  .profile-details-wrapper .service-offer-text {
    font-size: 13px;
    line-height: 18px;
    text-align: left;
    margin-left: 6px;
    display: -webkit-box;
    max-width: 200px;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
  .careGiver-details-div div.d-flex.justify-content-end {
    justify-content: space-between !important;
    width: 67%;
  }
  .btn-black {
    width: auto;
    height: 55px !important;
  }
  .message-container .message-wrapper {
    min-height: auto;
  }
  .message-container .right-child {
    margin-left: 0 !important;
    margin-top: 20px;
  }
  .message-container .right-child.right-child-mb-0 {
    border: 0;
  }
  .message-container .message-wrapper .left-child .updated-time {
    width: auto !important;
    left: -2vw !important;
  }
  .message-container .message-wrapper .right-child .chat-box-wrapper {
    padding: 0 0 15px;
  }
  .expense-container {
    padding: 15px 15px 30px;
    margin: 0;
    height: auto;
    overflow: auto;
  }
  .expense-container.table-responsive {
    height: 78vh;
  }
  .ExpenseImg-custom .transform-component-module_content__FBWxo {
    margin: 0 auto;
  }
  .Expense_receipt .ExpenseImg .ExpenseImg_image {
    height: 175px;
    width: 230px;
  }
  .Expense_receipt .expenseReceipt_approve,
  .Expense_receipt .expenseReceipt_reject {
    width: 100%;
    height: 55px;
    font-size: 16px !important;
  }
  .expense-container .ant-table-body {
    max-height: 75vh !important;
  }
  .ant-modal-root .ant-modal {
    width: 100% !important;
    max-width: calc(100% - 30px) !important;
    z-index: 99999;
  }
  .client_Expenses .ant-table-tbody > tr > td:first-child {
    min-width: 160px;
  }
  .ant-popover {
    z-index: 999;
  }
  .NotificationsContent {
    height: auto;
    padding-bottom: 15px;
  }
  .client_Expenses .ant-table-tbody > tr > td:nth-child(2) {
    min-width: 145px;
  }
  .expenses_client_receipt,
  .rating-review-div img {
    margin-right: 5px;
  }
  .client_Expenses .ant-table-tbody > tr > td:nth-child(3) {
    text-align: left !important;
    min-width: 115px;
  }
  .client_Expenses .ant-table-tbody > tr > td .ant-btn {
    font-size: 14px !important;
    height: 32px !important;
    width: 90px !important;
    padding: 0;
  }
  .ant-popover.header-popover-custom {
    top: 60px !important;
    padding: 0;
    border-radius: 0;
  }
  .NotificationsContent .Notifications .anticon.anticon-close {
    position: absolute;
    top: 5px;
    right: 7px;
  }
  .common-btn-row.common-btn-row-choose-date {
    position: relative;
    bottom: 0;
    right: 0;
    width: 100%;
  }
  .Transactions_Container .ant-table-body {
    max-height: initial !important;
  }
  .expenses_client_title {
    width: 3rem;
    font-size: 13px;
  }
  .date-time-div-avail img {
    margin: 0 0 0 5px !important;
  }
  .Transactions_Container .expenses_client_title {
    width: 8rem;
  }
  .expenses_client_amount {
    font-size: 15px;
  }
  .clientsettings {
    max-height: initial;
    height: calc(100vh - 170px);
    overflow: initial;
    padding: 0;
    width: calc(100% - 30px);
    margin: auto;
  }
  .expenses_radio_card {
    width: 100%;
    height: auto;
    padding: 5px 15px;
  }
  .careGiver_TotalEarnings .Earnings_cardbody {
    margin: 7px 0;
  }
  .careGiver_TotalEarnings .Transactions_Container {
    margin: 0;
    height: auto;
  }
  .appointment-dashboard .no-data-wrapper {
    height: 60vh;
  }
  .careGiver_TotalEarnings .Earnings_Transaction_Header,
  .profile-details-wrapper .Pending-appointment-content .service-offer-card .time-text,
  .profile-details-wrapper .profile-detail-val,
  .relative-form label {
    font-size: 13px;
    line-height: 18px;
    text-align: left;
  }
  .Transactions_Header span.ant-select-arrow {
    top: 11px;
    right: 5px;
  }
  .careGiver_TotalEarnings .Select .ant-select-selector {
    height: 30px;
  }
  .careGiver_TotalEarnings .Earnings_cards {
    margin: 0 0 15px;
  }
  .expense_pay_popup_okay,
  .expense_reject_Okay {
    width: 100% !important;
    height: 55px !important;
  }
  .clientsettings .ant-tabs-nav {
    width: 100% !important;
    height: auto;
    display: inline-block;
    min-width: 100%;
  }
  .clientsettings .ant-tabs {
    display: inline-block;
    width: 100%;
  }
  .clientsettings .ant-tabs-nav .ant-tabs-nav-list {
    width: 100%;
    flex-direction: column;
    padding: 15px 10px;
  }
  .clientsettings .ant-tabs-nav .ant-tabs-nav-wrap {
    width: 100%;
    position: relative !important;
    background: 0 0;
  }
  .appointment-dashboard .clientsettings .ant-tabs-tab + .ant-tabs-tab {
    margin: 0 0 10px !important;
  }
  .clientsettings .ant-tabs-content-holder {
    overflow: auto;
    height: 50vh;
    border-left: 0;
    margin-left: 0;
  }
  .appointment-dashboard .appointment-wrapper .card-title {
    margin-bottom: 5px;
}
  .d-none-mobile{
    display: none !important;
  }
  .appointment-dashboard .appointment-wrapper .card-value {
    font-size: 12px;
    font-weight: 400;
    color: #0F172A;
}
  .starting-time-div-tab-p.starting-time-div-tab-mobile{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 0 0 10px;
  }
  .appointment-dashboard .appointment-wrapper .starting-time-div-tab-mobile .card-title,
  .appointment-dashboard .appointment-wrapper .starting-time-div-tab-mobile .card-value{
    margin-bottom: 0;
    font-size: 13px;
    color: #00B383;
  }
  .appointment-dashboard .appointment-wrapper .starting-time-div-tab-mobile .card-title{
    font-weight: 700;
  }
  .appointment-dashboard .ant-tabs-tab {
    height: 45px;
    width: 100%;
    box-shadow: rgba(149, 157, 165, 0.2) 0 1px 6px;
    margin-bottom: 10px !important;
  }
  .clientsettings .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
    padding-left: 0;
  }
  .clientprofile .subform-1 .ant-form-item {
    width: 100% !important;
    margin-right: 0;
  }
  .profile-form .addressheading {
    margin: 10px 0;
    text-align: left;
    font-size: 16px;
  }
  .client_save_btn {
    bottom: -30px;
    position: absolute;
    width: 13rem;
    right: 0;
    left: 0;
    margin: auto !important;
  }
  .plus-icon-img {
    height: 35px;
    padding-top: 5px;
  }
  .notification-icon-img {
    height: 23px;
  }
  .clientsettings .settingnames {
    display: block !important;
  }
  .progress-bar {
    margin-top: 1rem !important;
  }
  .payment-wrapper .card-radio-btn .ant-radio-checked::after,
  .profile-details .auth-action-btn {
    left: 0;
  }
  .container-fluid .appointment-tab .ant-tabs-tab {
    border-radius: 11px !important;
}
  .strengthpass {
    width: 100% !important;
    margin-left: 0;
  }
  .ant-picker-large {
    padding: 10px;
  }
  .password-strength-meter-progress {
    width: 60% !important;
    padding-left: 10px;
  }
  .settings-editprofile .changepwd-btn {
    width: 13rem !important;
    margin-top: 0 !important;
    right: 17%;
    position: relative !important;
    top: 0;
  }
   .settings-editprofile .client-bottom-btn-update .changepwd-btn,
   .cardbutton.client-bottom-btn-update .btn-black{
    right: 0 !important;
  }
   .cardbutton.client-bottom-btn-update .btn-black{
     position: relative !important;
     bottom: 0 !important;
     margin: 0 !important;
   }
  .relative-list-container-web .relative-list {
    height: 74vh;
    margin-bottom: 60px;
  }
  .relative-list-container-web .add-relative-btn {
    margin: -10px 0 15px;
  }
  .relative-list-container-web .add-relative-btn:focus,
  .relative-list-container-web .add-relative-btn:hover {
    background-color: #364bc6;
  }
  .card, .video {
    margin-right: 0;
    width: 100%;
  }
  .relative-list-container-web .relative-list .relative-wrapper {
    margin: 0 0 15px;
    align-items: flex-start;
  }
  .choose-service-btn-row, .dashboard-row-card, .relative-list .d-flex.justify-content-between.align-items-center {
    display: inline-block !important;
    width: 100%;
  }
  .expensesItems .ant-table-tbody > tr > td:first-child {
    min-width: 125px;
  }
  .expensesItems .expenses_client_title {
    width: 3rem;
  }
  .expensesItems .ant-table-tbody > tr > td:nth-child(2) {
    min-width: 90px;
  }
  .expenses_reject_Button,
  .expenses_reject_Button:hover {
    width: 70px;
    height: 30px !important;
    font-size: 12px;
  }
  .expensesItems .ant-table-tbody > tr > td .expenses_approve_Button {
    height: 30px !important;
    font-size: 12px !important;
    width: 70px;
    padding: 0;
  }
  .view-relative-details-bottom-row {
    width: 100%;
    right: 0 !important;
    bottom: 0 !important;
    position: relative !important;
  }
  .view-relative-details-inner {
    padding: 0 !important;
    width: 100%;
    justify-content: space-around !important;
    margin: 0 !important;
  }
  .relative-details {
    border-radius: 16px !important;
    height: 80vh !important;
    margin-bottom: 60px;
  }
  .relative-details-container .relative-details .values {
    font-size: 15px;
    margin-bottom: 5px !important;
  }
  .view-relative-details-inner .auth-action-btn {
    width: 48% !important;
    margin-right: 0 !important;
    margin-left: 0 !important;
  }
  .btn-black.add-card-bank-details.auth-action-btn {
    margin-top: 0 !important;
    position: relative !important;
    bottom: 0 !important;
    right: 25% !important;
    width: 50% !important;
  }
  .relative-list-container-web .relative-list .relative-wrapper .address-wrapper .relative-address {
    font-size: 14px;
    line-height: 16px;
    text-align: left;
    margin-top: 10px;
  }
  .relative-list-container-web .relative-list .relative-wrapper .relative-actions .btn-wrapper {
    width: 100%;
    justify-content: flex-end !important;
  }
  .relative-list-container-web .relative-list .relative-wrapper .relative-actions .btn-wrapper .ms-4 {
    margin-left: 15px !important;
  }
  .clientprofile {
    padding: 0 15px 10px !important;
    box-shadow: none !important;
  }
  .cardbutton .btn-black {
    width: 13rem !important;
    bottom: 0 !important;
    position: relative !important;
    right: 17% !important;
    margin: 10px 0 20px !important;
  }
  .client_no_appointments_text, .form-data-card, .no_appointments_text {
    margin-top: 30px;
  }
  .ant-btn.auth-action-btn.delete-relative-btn {
    margin-left: 0 !important;
    margin-right: 0 !important;
    width: 48% !important;
    height: 55px !important;
    font-size: 18px !important;
    padding: 0 !important;
    transform: scale(1) !important;
  }
  .cardform .ant-form .ant-form-item, .chart-card-main {
    width: 100% !important;
    margin-left: 0 !important;
  }
  .availability-modal-container .availability-wrapper .react-calendar, .schedule_container, .videos {
    margin-left: 0;
  }
  .contact-client-row {
    height: calc(100vh - 125px);
    max-height: inherit;
    padding: 20px;
    margin-top: 85px;
    }
  .contactimage{
    display: block;
  }
  .email-icon-img {
    width: 125px !important;
    margin-bottom: 20px;
  }
  .parent-root .root {
    display: inline-block;
  }
  .parent-root .dashboard-body {
    height: auto;
    max-height: inherit;
    margin-left: 0 !important;
    width: 100%;
    overflow: inherit;
  }
  .parent-root .right-panel {
    width: 100%;
    padding-left: 0;
    padding-top: 30px;
  }
  .dashboard-row-card .ant-card {
    margin-left: 0 !important;
    width: 100% !important;
    margin-bottom: 15px;
  }
  .mySchedule {
    width: 100%;
    margin-left: 0;
    height: 58vh;
  }
  .App.date-picker > div:first-child {
    width: 125% !important;
    transform: translateX(0) !important;
    margin-left: 0 !important;
    margin-top: 0 !important;
  }
  .padding-right-15 {
    padding-right: 8px !important;
  }
  .relative-form-wrapper-custom {
    height:calc(100vh - 145px) !important;
    padding: 30px 15px !important;
    width: calc(100% - 30px);
    margin:0 auto;
  }
  .min-h-inhret .scroll-wrapper, .relative-form-wrapper-custom .scroll-wrapper {
    height: 77vh !important;
    overflow: inherit !important;
  }
  .min-h-inhret.careGiver-row .ant-col.ant-col-xs-24 {
    padding-right: 8px;
  }
  .availability-modal-container .availability-wrapper .title-wrapper .modal-title {
    font-size: 20px;
    margin: 0 !important;
    width: 100%;
  }
  .careGiver-details-div .auth-action-btn.careGiver-details-btn {
    width: 30% !important;
    padding: 0 !important;
    margin: 0 !important;
  }
  .availability-modal-container .availability-wrapper .availability-timing-wrapper .selected-date {
    font-size: 16px;
    margin: 30px 0 5px;
    padding-left: 10px;
  }
  .availability-modal-container .availability-wrapper .react-calendar__month-view__days__day {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .payment-wrapper .card-select-text {
    margin-left: 0;
    font-size: 16px;
    color: #64748b;
    margin-top: 30px;
  }
  .payment-list-div .scroll-wrapper {
    height: auto !important;
    width: 100%;
    overflow: inherit !important;
  }
  .payment-wrapper .payment-card-wrapper {
    width: 100%;
    padding: 10px;
  }
  .payment-list-div .scroll-wrapper .ant-radio-group span.ant-radio + * {
    padding-right: 0;
    padding-left: 0;
    width: 100%;
  }
  .payment-wrapper .payment-msg {
    font-size: 14px;
    line-height: 21px;
  }
  .payment-wrapper .amount-wrapper {
    height: auto !important;
    padding: 15px;
  }
  .payment-wrapper .payment-card-wrapper .card-number, .payment-wrapper .payment-card-wrapper .masked-number {
    font-size: 14px;
    margin-right: 0 !important;
    position: relative;
    right: 30px;
  }
  .careGiver-details-div .auth-action-btn.careGiver-details-btn.availability-btn {
    width: 60% !important;
  }
  .payment-wrapper .card-radio-btn .ant-radio-inner {
    top: 0;
    left: 0;
  }
  .payment-wrapper .card-radio-btn .ant-radio {
    position: absolute;
    top: 3px;
    right: 10px;
    bottom: 0;
  }
  .payment-wrapper .card-radio-btn .ant-radio-checked::after {
    top: 0;
    border-color: red;
    width: 16px;
    height: 16px;
    bottom: 0;
    margin: auto;
  }
  .careGiver-details-div .auth-action-btn.careGiver-details-btn.book-btn {
    width: 40% !important;
    margin-left: 10px !important;
  }
  .pb-mob-10 {
    padding-bottom: 10px;
  }
  .form-data-card {
    overflow: auto;
    height: auto !important;
    padding: 0 8px;
  }
  .additional-info-tag {
    font-size: 18px !important;
    margin-top: 0 !important;
  }
  .radio-btn-relative-div .ant-radio-button-wrapper.custom-radio img {
    height: auto;
    width: auto;
  }
  .pets-radio-div .ant-btn-lg {
    height: 50px;
  }
  .notification-icon-btn {
    height: auto;
    font-size: 25px;
    line-height: initial;
  }
  .right-side-data {
    width: 32%;
    justify-content: flex-end;
  }
  .right-side-data > span {
    margin: 0 20px;
  }
  .caregiver-details-descriaption{
    text-align: left !important;
    display: inline-block;
    margin: 0 !important;
  }
  .responsive-data-caregiver{
      display: inline-block !important;
      left: -15px !important;
      bottom: 95px !important;
  }
  .responsive-data-caregiver.responsive-data-caregiver-update{
    left: 70px !important;
  }
  .d-flex.appointment-inner.location-details-div.pb-mob-10{
    padding-left: 0;
    margin:5px 0 0;
  }
  .ant-btn.btn-back.appointment-cancel-btn {
    margin-left: 0 !important;
    width: 45%;
  }
  .right-side-data .ant-badge-multiple-words {
    padding: 0 5px;
  }
  .message-container .message-wrapper .left-child .unread-count {
    left: 32vw;
  }
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker .rmdp-day,
  .profile-details-wrapper .service-offer-card img {
    height: 40px !important;
    width: 40px !important;
  }
  .details-footer-bottom-appointment {
    padding: 0 !important;
    margin-top: 0 !important;
  }
  .service-appointment-row {
    margin-left: -8px;
    margin-right: -8px;
  }
  .appointment-dashboard .appointment-wrapper .profile-wrapper .relative-wrapper .relative-name {
    font-size: 11px !important;
    display: inline-block;
    width: 45%;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    text-align: left;
  }
  .service-appointment-col {
    padding: 0 8px;
  }
  .justify-flex-start-mob,
  .rating-review-div {
    justify-content: flex-start !important;
  }
  .no-appointment-div {
    margin: 0 !important;
    height: 85vh !important;
  }
  .add-new-relative-div {
    position: relative !important;
    bottom: 0 !important;
    right: 0 !important;
    justify-content: space-between !important;
    margin-top: 0 !important;
  }
  .ant-btn.auth-action-btn.add-new-relative-btn {
    width: 48% !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
  }
  .ant-popover.notification-popover-custom {
    left: auto !important;
    right: 0;
    width: 100%;
    top: 55px !important;
    padding-bottom: 34px;
  }
  .notification-popover-custom .ant-popover-inner {
    box-shadow: none;
    padding: 0 0 30px;
    border-radius: 0;
  }
  .caregivers-wrapper .caregivers-card {
    width: 100% !important;
    margin: 0 0 30px !important;
    height: 305px;
  }
  .monthly_cal {
    width: 100%;
    margin-left: 0;
    padding: 15px;
  }
  .caregivers-wrapper.caregivers--details-inn {
    min-height: inherit;
  }
  .service-appointment-col-car {
    margin-bottom: 15px !important;
  }
  .choose-relative-modal-div .ant-radio-group {
    margin: 0 !important;
    display: inline-block !important;
    width: 100%;
  }
  .choose-service-btn-row button.btn-large {
    width: 48%;
    font-size: 14px !important;
    padding: 0 !important;
  }
  .choose-service-btn-row .ant-checkbox-wrapper.select-all-check {
    margin-top: 10px;
  }
  .choose-service-row-main p {
    font-size: 16px !important;
  }
  .choose-service-row-main .service-card {
    width: 45%;
    height: auto;
    margin-left: 8px !important;
    margin-top: 8px !important;
  }
  .Pending-appointment-content .scroll-wrapper,
  .message-main {
    height: auto !important;
    min-height: auto !important;
  }
  .choose-service-row-main .service-card .service-img {
    width: 70px;
    height: 70px;
  }
  .choose-service-row-main .service-card p {
    font-size: 13px !important;
    line-height: 17px;
    margin-bottom: 10px !important;
    margin-top: 10px !important;
  }
  .date-range-picker-wrapper .rmdp-wrapper.rmdp-shadow {
    overflow: auto;
    width: 100%;
    margin-bottom: 15px;
  }
  .date-range-picker-wrapper .rmdp-calendar .rmdp-header {
    margin-top: 12px;
}
  .timing-list span.ant-select-arrow {
                top: 21px;
        right: 8px;
    }
  .timing-list span.ant-select-arrow .anticon {
            font-size: 10px;
    }
  .info-icon-tip-custom.info-icon-tip {
    left: 40%;
  }
  .my-caregivers-slots-wrapper .slots-text {
    width: 110px;
    margin-bottom: 5px !important;
    text-align: center;
    font-size: 12px !important;
    line-height: 22px;
    padding: 4px 0;
    height: 30px;
  }
  .Pending-appointment-content .scroll-wrapper {
    overflow: inherit !important;
  }
  .date-range-picker-wrapper .rmdp-calendar {
    padding: 5px 10px;
  }
  .choose-date-div p.fw-normal.color-secondary {
    font-size: 14px !important;
  }
  .timing-list {
    max-height: inherit;
    padding: 0 4px !important;
  }
  .timing-list .ant-picker-large {
    width: 85% !important;
  }
  .mode-icon-div {
    position: absolute;
    right: -15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    top: 0;
    margin: 0 !important;
  }
  .mode-icon-div.mobile-time-margin{
    margin-top:28px !important;
  }
  .mode-icon-div img {
      margin: 10px 3px !important;
      height: 17px !important;
      width: 17px !important;
  }
  .client-bottom-btn.client-bottom-btn-update.change-password-caregiver, .client-bottom-btn.client-bottom-btn-update.fix-card-100-92,
  .client-bottom-btn.client-bottom-btn-update.add-new-card-btn,
  .btn-black.add-card-bank-details.auth-action-btn.float-end.savebutton.add-card-btn{
    right: 0;
    bottom: 0;
}
  .caregivers-choose-custom .caregivers-wrapper {
    min-height: 60vh;
  }
  .timing-list .ant-row.position-relative.width-full{
    margin-bottom: 10px;
  }
  .p-3.border-less.rounded.position-relative.date-range-picker-wrapper.date-range-picker-wrapper-custom{
    height: calc(100vh - 145px) !important;
    overflow: auto;
  }
  .top-heading-booking-confirm {
    display: inline-block !important;
    width: 100%;
    margin-bottom: 15px;
  }
  .Pending-appointment-content .location-container,
  .profile-details-wrapper .Pending-appointment-content .username,
  .review-card-mob {
    bottom: 0 !important;
    left: 0 !important;
  }
  .profile-details-wrapper .Pending-appointment-content .role-btn {
    width: auto;
    height: 32px;
    margin-right: 0 !important;
  }
  .top-heading-booking-confirm p {
    width: 100% !important;
    text-align: center;
    margin-bottom: 0 !important;
  }
  .profile-details-wrapper .Pending-appointment-content .profile-detail-title {
    font-size: 15px;
    line-height: 23px;
  }
  .profile-details-wrapper .Pending-appointment-content .profile-detail-val {
    font-size: 14px;
    line-height: 17px;
    text-align: left;
  }
  .profile-details-wrapper .Pending-appointment-content .service-offer-card .date-card {
    padding: 5px 15px;
  }
  .bottom-scroll {
    position: absolute;
    bottom: 75px;
    right: 0;
  }
  .appointment-dashboard .appointment-wrapper .cancelled,
  .appointment-dashboard .appointment-wrapper .completed,
  .appointment-dashboard .appointment-wrapper .ongoing,
  .appointment-dashboard .appointment-wrapper .pending,
  .appointment-dashboard .appointment-wrapper .upcoming,
  .profile-details-wrapper .role-btn {
    width: auto;
    height: 32px;
    padding: 0 10px;
    font-size: 11px;
  }
  .profile-details-wrapper .username > img {
    bottom: 3px !important;
  }
  .flex-wrap-nowrap-mob {
    flex-wrap: nowrap !important;
    align-items: center;
  }
  .w-80-mob {
    width: 55%;
  }
  .appointment-wrapper .profile-wrapper .caregiver-name {
    font-size: 14px !important;
    font-weight: 700 !important;
  }
  .appointment-wrapper .profile-wrapper .caregiver-name .caregiver-name-tag {
        width: 60%;
        display: inline-block;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
  }
  .caregivers-wrapper .ratings-card .inner-div-1 .review-title,
  .rating-hourly-price-div span {
    display: inline-block;
    width: 100%;
    text-align: left;
  }
  .caregivers-wrapper .ratings-card .inner-div-2.rating-hourly-price-div,
  .inner-div-1.inner-div-1-custom {
    padding-left: 45px;
  }
  .rating-review-details-inner {
    bottom: 0 !important;
    left: 0 !important;
    margin-bottom: 10px;
  }
  .profile-details-wrapper .bookmark-btn {
    top: -5px;
    right: 0;
    position: absolute;
  }
  .profile-details-wrapper .service-offer-card .time-text {
    font-size: 11px;
    margin-left: 5px;
  }
  .mt-3-mob {
    margin-top: 10px !important;
  }
  .appointment-dashboard .appointment-wrapper .profile-wrapper .relative-wrapper .relative-name.relative-name-ongoing {
    width: 34%;
  }
  .relative-wrapper-ongoing {
    justify-content: flex-start !important;
    align-items: center;
  }
  .starting-time-div-caregiver {
    position: absolute;
    top: 0;
    right: 10px;
  }
  .starting-time-div-caregiver p {
    margin-bottom: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font-size: 12px;
    line-height: 15px;
  }
  .accept-decline-btn-col .ant-btn-round.ant-btn {
    margin: 0 0 0 10px !important;
    width: 7rem !important;
    height: 45px !important;
    font-size: 14px !important;
  }
  .client-schedule {
    padding: 65px 0 15px;
  }
  .schedule_container {
    width: 100%;
    padding: 0
}
  .schedule_tab {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 20px;
    padding: 0 15px;
  }
  .weekly_schedule_appoinments {
    margin-top: 15px;
    height: calc(100vh - 205px);
    padding: 15px;
}
  .schedule_tab > div {
    width: 48%;
    margin: 0;
  }
  .ant-btn.plus-icon-add-bottom{
    border: 0;
    box-shadow: none;
    padding: 0;
    z-index: 99;
    position: fixed;
    bottom: 15px;
    right: 15px;
    display: flex !important;
  }
  .weekly_calender {
    margin-top: 50% !important;
  }
  .schedule_appoinments {
    display: inline-block;
    padding: 0 15px;
    height: auto;
  }
  .schedule_calender {
    width: 100%;
    margin-left: 0;
    padding: 0 15px;
  }
  .appoinment_client_details {
    min-height: 24vh;
    max-height: 40vh;
    padding-top: 20px;
  }
  .checkboxclass .ant-checkbox .ant-checkbox-inner {
    width: 20px;
    height: 20px;
  }
  .checkboxclass .ant-checkbox-inner::after {
    width: 7px;
    height: 11px;
  }
  .steps-content .hourlyrate {
    padding: 0;
    margin-bottom: 0;
  }
  .geo-location-div-inner #circle-example {
    height: 475px !important;
  }
  .companion-details h1 {
    margin-top: 0 !important;
    font-size: 22px;
  }
  .stripeinfo {
    overflow-x: auto;
    height: 380px;
  }
  .TextLevelSmallLabel {
    display: inline-block;
    text-align: left !important;
    overflow-wrap: anywhere;
  }
  .parent-avalability.parent-avalability-custom.p-4 {
    padding: 30px 15px !important;
  }
  .ant-btn.get-started-btn {
    height: 50px !important;
    font-size: 16px !important;
    width: 13rem !important;
  }
  .companionmodaltwo .ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
    height: 45px;
  }
  .companionmodaltwo .ant-select-multiple .ant-select-selector,
  .companionmodelthree .ant-select-multiple .ant-select-selector {
    height: auto;
    padding: 7px 15px;
  }
  .companionmodaltwo .ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 43px;
  }
  .companionmodaltwo .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 43px;
  }
  .upgradeselect .ant-select-arrow {
    top: 25px;
  }
  .companionmodaltwo .ant-btn-round.ant-btn-lg,
  .companionmodelthree .ant-btn-round.ant-btn-lg {
    width: 95% !important;
    height: 50px !important;
  }
  .companionmodelthree .ant-slider-mark .ant-slider-mark-text {
    font-size: 8px !important;
    font-weight: 600;
  }
  .upload-image {
    height: 143px;
    bottom: 100px;
  }
  .upgrade-caregiver-div p {
    margin-bottom: 25px;
  }
  .ant-btn.btn-black.done-btn-success {
    margin: auto !important;
    width: 10rem;
  }
  .mx-auto-mobile {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .message-container .message-wrapper .left-child .unread-count.unread-count-custom {
    right: 0;
    left: auto;
    top: 0;
  }
  .auth-action-btn.auth-action-btn-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .steps-content.profile-details .companion-details {
    max-width: 100vw !important;
  }
  .expense-container .expense-custom-table-row {
    width: 475px;
    overflow: auto;
  }
  .expense-container .expense-tr-common {
    width: 100%;
  }
  .expense-tr-common .expenses_reject_Button,
  .expense-tr-common .expenses_reject_Button:hover {
    height: 40px !important;
    font-size: 1rem !important;
    width: 100px;
  }
  .expense-td.expense-status-td .ant-space {
    justify-content: flex-end;
  }
  .message-container .message-wrapper .right-child .chat-box-wrapper .chat__container .timestamp.right,
  .message-container .message-wrapper .timestamp.right {
    left: 38%;
  }
  .message-container .message-wrapper .right-child .chat-box-wrapper .chat__container .timestamp.left {
    text-align: left;
    width: 100%;
    display: block;
  }
  .client-bottom-btn.client-bottom-btn-update {
    right: 0;
    bottom: 0;
    position: relative;
}
  .client-bottom-btn.client-bottom-btn-update.change-password-caregiver, .client-bottom-btn.client-bottom-btn-update.fix-card-100-92 {
    right: 0;
    bottom: 0;
    position: relative;
}
.clientsettings.client-setting-custom-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab {
    margin: 0 0 10px !important;
    height: 50px;
    border-radius: 6px !important;
    padding: 0 !important;
}


}
@media only screen and (min-width: 320px) and (max-width: 767px) and (orientation: landscape) {
  .choose-relative-modal-div label.ant-radio-button-wrapper.custom-radio,
  .full-width {
    max-width: 40% !important;
    margin-right: 30px !important;
    margin-left: 36px !important;
  }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) {
  .settings-editprofile {
    overflow: inherit !important;
  }
  .m-mob-0.appointment-dashboard.px-3-mob, .setting-fluid{
    padding: 0 15px !important;
  }
  .expense_reject_No.ant-btn,
  .expense_reject_Okay.ant-btn {
    font-size: 1rem !important;
    height: 50px !important;
    width: 100% !important;
  }
  span.ant-select-arrow {
    top: 30px;
  }
  .timing-list span.ant-select-arrow {
    top: 22px;
  }
  .ant-col.ps-4.text-start.timing-container.ant-col-xs-24.ant-col-lg-12.ant-col-xl-14 {
    padding-left: 0 !important;
    padding-top: 0;
  }
  .form-row.form-row-div .border {
    border: 1px solid #ddd !important;
    border-radius: 8px !important;
  }
  .form-row.form-row-div .pointer-add-pp {
    text-align: center !important;
  }
  .common-btn-row.my-caregiver-select-common-btn {
    position: absolute;
    bottom: 15px;
    left: 0;
    width: 100%;
  }
  .pointer-add-pp img {
    width: 60px !important;
  }
  .appointment-dashboard .appointment-wrapper .starting-time-div.starting-time-div-tab-p .card-value,
  .service-section {
    margin-bottom: 0;
  }
  .profileselect {
    max-height: initial;
    min-height: 5rem;
  }
  .profile-header-row {
    height: auto !important;
    overflow-y: inherit;
    margin: 0 8px 30px;
  }
  .auth-action-ban-details,
  .mb-mob-0,
  .profile-detail-title {
    margin-bottom: 0 !important;
  }
  .skip-btn-mob {
    padding-right: 0 !important;
    position: relative;
    right: -55px;
  }
  .parent-avalability .availability-contents .time-text {
    flex-direction: row !important;
    margin-top: 30px;
  }
  .caregiversave,
  .parent-avalability .availability-contents .time-text .mt-4.flex-column,
  .save-btn-bank-info {
    margin-top: 0 !important;
  }
  .availability-modal-container .availability-wrapper .react-calendar__navigation,
  .availability-modal-container .availability-wrapper .react-calendar__viewContainer,
  .clientsettings.client-setting-custom-tab .ant-tabs,
  .clientsettings.client-setting-custom-tab .ant-tabs-nav .ant-tabs-nav-wrap,
  .info-modal-container p,
  .playmodal .ant-modal-body,
  .settings .ant-form-item-control-input,
  .settings .ant-form-vertical .px-md-5,
  .settings.settings-caregiver .ant-tabs-tab,
  .video-container,
  .videoimage,
  .videos,
  .videos div:first-child,
  .videotitle {
    width: 100%;
  }
  .parent-avalability.parent-avalability-custom {
    align-items: flex-start !important;
  }
  .parent-avalability.parent-avalability-custom .availability-contents {
    max-height: 90vh !important;
  }
  .parent-avalability .availability-contents {
    padding: 45px 15px 15px !important;
    max-height: 84vh !important;
  }
  .react-calendar__month-view__days__day,
  .react-calendar__month-view__days__day--neighboringMonth,
  .react-calendar__tile {
    flex: 0 0 8.4% !important;
    height: 41px !important;
    width: 41px !important;
  }
  .ant-modal.availability-modal .ant-modal-body div.m-4 div:nth-child(4) div:first-child > div:first-child,
  .profile-form .addressheading,
  .profile-form .subheading {
    padding-left: 5px;
  }
  .ant-modal.availability-modal .ant-modal-body div.m-4 div:nth-child(4) div:first-child > div:nth-child(2) {
    padding-left: 30px;
  }
  .stripemodal .ant-modal-body {
    padding: 24px;
  }
  .auth-action-ban-details {
    margin-top: 40px !important;
  }
  .verify-main-div .auth-action-btn {
    margin-top: 45px;
  }
  .center-container.verify-container {
    padding-top: 30px;
    padding-bottom: 30px;
  }
  .responsive-button.understand-btn {
    margin: auto !important;
    font-size: 16px !important;
    padding: 0 !important;
    width: 12rem;
  }
  .additional-details-row,
  .geo-location-div-inner,
  .work-details-form-row {
    height: calc(100vh - 330px) !important;
  }
  #circle-example {
    height: 650px !important;
    margin-top: 15px;
  }
  .center-container {
    padding: 0 20px;
  }
  .welcome-container {
    max-width:80%;
    min-height: 650px;
    padding: 0;
  }
  .map-actions {
    max-width: 100%;
    width: 80%;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
  }
  .hourlyrate.caregiverhourlyrate p {
    margin-bottom: 10px !important;
  }
  .welcome-container .password-strength-meter-progress {
    padding-left: 10px;
  }
  .right-side-form {
    width: 100%;
    margin: 2rem 0;
  }
  .radio-box-div,  #signup-form{
    width: 75%;
}
  .w-100.d-inline-block.form-data-card #signup-form{
    width: 100%;
  }
  .additional-detail-wrapper .signup-slider .ant-slider-mark-text:nth-child(8) {
    left: auto !important;
    right: -50px;
  }
  .tt-height {
    height: calc(80vh - 335px) !important;
  }
  .welcome-subheader {
    font-size: 16px !important;
    max-width: 100% !important;
  }
  .inner-div-2.rating-hourly-price-div,
  .parent-avalability .text-center-ipad,
  .text-center-ipad.welcome-subheader {
    text-align: center;
  }
  .date-range-picker-wrapper .rmdp-wrapper.rmdp-shadow,
  .subform-1 .lastone,
  .termsandcon.custom-modal .ant-modal-body {
    height: auto;
  }
  .date-range-picker-wrapper .rmdp-wrapper.rmdp-shadow{
    width: 100%;
  }
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker {
    width: 100%;
    justify-content: center;
}
  .common-condition-details h2,
  .termsheadings h1 {
    font-size: 24px;
  }
  .parent-avalability .availability-contents .time-text .time-text-dropdown {
    margin-top: 0 !important;
    margin-bottom: 1.4rem !important;
  }
  .ant-modal.playmodal.addavailvideo {
    height: auto !important;
    top: 0 !important;
  }
  label.upload-file-field {
    position: relative;
    margin-top: 24px;
  }
  .common-condition-details h3 {
    font-size: 20px;
  }
  .padding-right-15 {
    padding-right: 8px !important;
  }
  .email-container {
    width: 85%;
    height: 450px;
  }
  .payment-list-div,
  .reqgenaratedMsg,
  .time-details-row {
    margin-top: 15px;
  }
  .form-row {
    padding-left: 15px;
  }
  .caregiver-profile-settings-card .ant-input-affix-wrapper.ant-input-password,
  .caregiver-profile-settings-card .ant-input.bankinput,
  .chart-card-main .recharts-wrapper,
  .chart-card-main .recharts-wrapper .recharts-surface,
  .form-row .ant-input,
  .profile-details-wrapper .username,
  .profile-header-row .ant-form-item,
  .subform-1 .ant-form-item {
    width: 100% !important;
  }
  .ant-col.ms-mob-0.detailing-div.ant-col-xs-12.ant-col-md-5.ant-col-lg-2,
  .ant-form-item.mobile-btn-item {
    margin-left: 0 !important;
  }
  .ant-form-item.mobile-btn-item .ant-btn {
    margin-right: auto !important;
    margin-left: auto !important;
  }
  .profilecont .text-start {
    overflow-y: inherit;
    max-height: inherit !important;
    padding: 0 8px;
  }
  .ant-form-item.other-language-input .ant-input {
    margin-left: 0 !important;
    width: 100% !important;
    height: 58px !important;
    font-size: 16px;
  }
  .additional-detail-wrapper .auth-action-btn {
    left: 0;
  }
  .ant-slider-mark .ant-slider-mark-text:first-child {
    font-size: 14px;
  }
  .steps-content .hourlyrate .ant-slider-mark .ant-slider-mark-text-active:first-child {
    left: 2% !important;
  }
  .bank-detail-wrapper .ant-row,
  .detailing-div, .mx-4.w-100 {
    margin: 0 !important;
  }
  .mx-4.w-100{
    padding:0 15px 0 30px;
    width: calc(100% - 220px) !important;
  }
  .settings {
    height: calc(100vh - 160px);
}
  .settings-button-position.btn-position.settings-button-position-profile .auth-action-btn.mt-5 {
    bottom: 0;
    right: 0;
    position: relative;
}
  .settings .ant-tabs-nav {
    width: 90px;
}
  .sidebar {
    width: 180px !important;
    min-height: auto !important;
    top:35px;
  }
  .mx-4.w-100.main-content, .ms-4.w-100.message-inner-row, .mx-4.message-inner-row.w-100, .contact-us-main {
    padding: 0 0 0 15px;
    margin: 0 0 0 180px !important;
    width: calc(100% - 180px) !important;
}
  .sidebar.collapsed-class{
    min-width: 4% !important;
    width: 70px !important;
  }
  .sidebar .ant-menu-vertical .ant-menu-item {
    margin: 10px 0 !important;
    font-size: 14px !important;
}
  .footer {
     width: 75%;
    left: auto !important;
    right: 0 !important;
}
  .pt-5.d-flex.profile-details-wrapper, .container-fluid .pt-5.d-flex{
    padding-top:2rem !important;
  }
  .m-mob-0.w-100.ipad-width{
    width: calc(100% - 220px) !important;
    padding: 0 30px;
  }
  .new-book-appoinment-heading {
    font-size: 32px !important;
    line-height: 40px !important;
}
  label.ant-radio-button-wrapper.custom-radio span {
    justify-content: center;
}
  .pt-5.main-fluid.d-flex{
    padding-top: 2rem !important;
  }
  .custom-radio-space label.ant-radio-button-wrapper.custom-radio span {
    justify-content: space-between;
}
  .choose-relative-modal-div label.ant-radio-button-wrapper.custom-radio {
    height: auto !important;
}
  .service-card {
    width: 155px;
    margin-left: 10px !important;
}
  .settings .bank-acknowledge .ant-form-item-label label {
    width: 90%;
}
  .settings-editprofile h4, .settings-editprofile .profile-detail-title, .settings-editprofile .dateofbirth, .settings-editprofile .roleupgrade p {
    font-size: 16px !important;
    line-height: 25px;
}
  .clientsettings.client-setting-custom-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn .settingnames {
    display: none;
}
  .client-bottom-btn {
    right: 0;
    bottom: 0;
}
  .clientsettings.client-setting-custom-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn .imageicons {
    display: flex;
    align-items: center;
    justify-content: center;
}
  .carddetailsinfo .ant-card {
    width: 100% !important;
}
  .carddetailsinfo .cards {
    display: block;
}
  .parent-root {
    width: calc(100% - 180px);
    padding: 0 0 0 15px;
    margin-left: 180px;
  }
  .appointment-navbar-custom {
    width: 100% !important;
    margin-left: 0 !important;
    padding-left: 1rem !important;
  }
  .monthly_cal,
  .mySchedule,
  .profilecont,
  .videos {
    margin-left: 0;
  }
  .ant-breadcrumb,
  .parent-root .ant-breadcrumb {
    font-size: 20px !important;
  }
  .Done_btn,
  .details-header-right {
    width: auto !important;
  }
  .details-header-right > img:nth-child(2),
  .details-header-right > span {
    margin: 0 15px;
  }
  .settings-editprofile .circle {
    right: 10px;
    top: -3px;
  }
  .d-md-none-tab {
    display: none;
  }
  .starting-time-div.starting-time-div-ipad {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
  }
  .ant-tabs.ant-tabs-mobile.appointment-tab .accept-decline-btn-col {
    position: relative;
    bottom: 0;
    right: 0;
  }
  .parent-root .dashboard-body {
    margin: 0 0 30px !important;
    height: auto;
    padding-left: 15px;
    width: 100%;
  }
  .parent-root .right-panel {
    width: 100%;
    padding-left: 20px;
  }
  .custom-dashboard-card .ant-card-body,
  .mySchedule {
    padding: 15px;
  }
  .ant-card.custom-dashboard-card {
    width: 32% !important;
    margin: 0 !important;
    height: 130px !important;
  }
  .custom-dashboard-card .ant-card-body .ant-typography {
    font-size: 16px !important;
    margin: 10px 0 0 !important;
  }
  .dashboard-row-card,
  .subform-2 {
    justify-content: space-between;
  }
  .ant-card.chart-card-main {
    width: 100% !important;
    height: 450px !important;
    margin-left: 0 !important;
  }
  .chart-card-main .recharts-wrapper,
  .chart-card-main .recharts-wrapper svg {
    height: 350px !important;
  }
  .mySchedule {
    width: 100%;
    height: auto;
  }
  .App.date-picker > div:first-child {
    width: 100% !important;
    transform: translateX(0) !important;
    margin-left: 0 !important;
  }
  .App.date-picker > div:first-child div:first-child div div:first-child {
    font-size: 12px !important;
    width: 40px !important;
    height: 40px !important;
    padding: 11px !important;
  }
  .monthly_cal {
    width: 100%;
  }
  .no_appointments_text {
    margin-top: 20%;
  }
  .container-fluid.appointment-dashboard {
    padding: 0 15px 45px;
  }
  .mx-4.main-content {
    margin: 0 !important;
    padding-left: 30px;
  }
  .video,
  .videos div:first-child.video {
    width: 47.5%;
  }
  .videos div:first-child.ant-row {
    margin: 0 !important;
  }
  .video {
    margin: 7px;
  }
  .ant-popover {
    left: auto !important;
    right: 15px !important;
    z-index: 1;
  }
  .nav-menu {
    right: 15px;
  }
  .card-div.message-card-div {
    padding: 15px !important;
  }
  .message-container .message-wrapper {
    min-height: 82vh;
    display: block;
  }
  .message-container .right-child {
    margin-left: 0 !important;
    margin-top: 0;
  }
  .left-child{
    height: 250px;
    overflow: auto;
  }
  .message-container .message-wrapper .right-child .chat-box-wrapper .chat__container {
      height: calc(100vh - 650px);
  }
  .careGiver_TotalEarnings .Earnings_cardbody {
    padding: 15px 10px;
}
  .careGiver_TotalEarnings .Earnings_cardbody .Earnings_amount {
    font-size: 24px;
    line-height: 40px;
}
  .careGiver_TotalEarnings .Earnings_cardbody .Earnings_amountType {
    font-size: 16px;
}
  .client_Expenses .ant-table-tbody > tr > td:first-child {
    min-width: 200px;
  }
  .client_Expenses .ant-table-tbody > tr > td:nth-child(2) {
    min-width: 105px;
    padding: 16px 10px;
  }
  .expense-container .ant-table-body {
    max-height: 74vh !important;
  }
  .client_Expenses .ant-table-tbody > tr > td:nth-child(3) {
    min-width: 155px;
  }
  .client_Expenses .ant-table-tbody > tr > td:nth-child(4) {
    min-width: 135px;
  }
  .message-inner-row {
        margin: 0 !important;
        padding-left: 20px;
        width: calc(100% - 220px) !important;
  }
  .sidebar-custom.collapsed-class ~ .schedule_container,
  .sidebar-custom.collapsed-class ~ .m-mob-0.w-100.ipad-width,
  .sidebar-custom.collapsed-class ~ .mx-4.w-100,
  .sidebar-custom.collapsed-class ~ .message-inner-row{
    width: calc(100% - 70px) !important;
  }
  .expense-container {
    margin: 0;
  }
  .Transactions_Header span.ant-select-arrow {
    top: 15px;
  }
  .Transactions_Header .ant-select,
  .clientsettings .ant-tabs-nav {
    width: 100px !important;
  }
  .settings.settings-caregiver .ant-tabs-nav .ant-tabs-nav-wrap {
    width: 75px;
  }
  .settings .ant-tabs-nav .ant-tabs-nav-list {
    width: auto;
}
  .settings .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab .imageicons{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .settings .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab .imageicons img{
    width:100% !important;
  }
  .settings .ant-tabs-large > .ant-tabs-nav .ant-tabs-tab .settingnames{
    display: none;
  }
  .settings-editprofile .caregiverrole,
  .subform-1 .firstone,
  .subform-1 .lastone {
    width: 100% !important;
    margin-right: 0;
  }
  .dateofbirth .date {
    margin-top: 10px;
    justify-content: space-between;
  }
  .dateofbirth,
  .profile-details-wrapper .profile-detail-title,
  .profile-details-wrapper .top-heading-booking-confirm p {
    margin: 0 !important;
  }
  .caregiver-profile-settings-card .password-strength-meter-progress,
  .caregiverdetals {
    margin-left: 10px;
  }
  .caregiverservice {
    min-height: 85px;
  }
  .ant-tabs-mobile #circle-example {
    height: 400px !important;
    margin-top: 0;
  }
  .btn-position,
  .save-btn-bank-info {
    width: auto;
    position: relative !important;
    right: 0 !important;
    bottom: 0 !important;
  }
  .clientprofile {
    padding: 30px 0 70px;
  }
  .ant-tabs-content-holder .clientprofile {
      padding: 15px 0 0;
      box-shadow: none !important;
  }
  .client-bottom-btn.client-bottom-btn-update.change-password-caregiver, .client-bottom-btn.client-bottom-btn-update.fix-card-100-92, .client-bottom-btn.client-bottom-btn-update.add-new-card-btn, .btn-black.add-card-bank-details.auth-action-btn.float-end.savebutton.add-card-btn {
    right: 0;
    bottom: 0;
    position: relative;
}
  .client_Expenses{padding-top: 2rem !important;}
  .strengthpass {
    width: 57% !important;
  }
  .ant-modal-centered .ant-modal {
    width: 65% !important;
    max-width: 65vw !important;
    max-height: initial !important;
  }
  .termsandcon.custom-modal.ant-modal {
    width: 75% !important;
    max-width: 75vw !important;
  }
  .termsandcon.custom-modal.ant-modal .ant-card.common-card-modal {
    margin: 0 !important;
    border: 0;
    padding: 0 !important;
  }
  .termsandcon.custom-modal.ant-modal .ant-card.common-card-modal .ant-card-body,
  .timing-list {
    padding: 0 !important;
  }
  .ant-modal-centered .ant-modal.availability-modal-container {
    width: 50% !important;
    max-width: 50vw !important;
  }
  .availability-modal-container .availability-wrapper .react-calendar__month-view__days__day abbr {
    height: 40px;
    line-height: 40px;
  }
  .availability-modal-container .availability-wrapper .react-calendar__month-view__days__day {
    flex: 0 0 2.2857% !important;
  }
  .availability-modal-container .availability-wrapper .title-wrapper .modal-title {
    margin: 0 !important;
    width: 100%;
  }
  .availability-modal-container .availability-wrapper .availability-timing-wrapper .selected-date {
    padding-left: 15px;
    margin-top: 15px;
    margin-bottom: 5px;
  }
  .payment-list-main-div {
    min-height: 85vh !important;
  }
  .payment-list-main-div .view-relative-details-inner {
    position: absolute;
    bottom: 15px;
    width: 100%;
    left: 0;
  }
  .ant-modal-body,
  .caregiver-appointment-row-main,
  .payment-wrapper .amount-wrapper.booking-confirm {
    height: auto !important;
  }
  .caregiver-appointment-row-main > div:first-child {
    height: 82vh !important;
    overflow: auto;
  }
  .caregiver-appointment-row-main.card-div > div:first-child {
    height: 75vh !important;
  }
  .Transactions_Container .ant-table-body,
  .caregiver-appointment-row-main .max-height-auto,
  .max-height-auto {
    max-height: inherit !important;
  }
  .expensesItems .ant-table-body {
    max-height: inherit !important;
    overflow: inherit !important;
  }
  .expensesItems .ant-table-tbody > tr > td:last-child {
    width: 230px;
  }
  .client_Expenses .ant-table-tbody > tr > td:nth-child(4) .ant-space.ant-space-horizontal,
  .expensesItems .ant-table-tbody > tr > td:last-child .ant-space {
    justify-content: flex-end;
  }
  .max-height-auto.caregiver-appoinment-details-div{
          max-height: calc(100vh - 450px) !important;
  }
  .c-app-card.caregiver-appointment-row-main{
    height: calc(100vh - 150px) !important;
  }
  .d-flex.details-footer-bottom.details-footer-bottom-upcoming.align-items-center{
      bottom: 80px;
    position: fixed;
    right: 30px;
  }
  .auth-action-btn {
    padding-left: 3.5rem !important;
    padding-right: 3rem !important;
  }
  .clientsettings {
    align-items: baseline;
  }
  .expenses_client_title {
    font-size: 16px;
    width: 5rem;
  }
  .appointment-dashboard .appointment-wrapper .cancelled,
  .appointment-dashboard .appointment-wrapper .completed,
  .appointment-dashboard .appointment-wrapper .ongoing,
  .appointment-dashboard .appointment-wrapper .pending,
  .appointment-dashboard .appointment-wrapper .upcoming,
  .clientsettings .ant-tabs-nav .ant-tabs-nav-wrap {
    width: 100px;
  }
  .d-flex.right-mob-0.justify-flex-start-mob {
    justify-content: flex-start !important;
    right: 0 !important;
  }
  .service-appointment-col {
    padding: 0 5px;
  }
  .caregiver-review-container .reviewsListing {
    background-color: transparent;
    box-shadow: none;
  }
  .availability-main-div {
    padding: 15px 5px !important;
    height: 86vh !important;
  }
  .review-modal-container .client-wrapper {
    box-shadow: 0 10px 60px rgba(15, 23, 42, 0.1);
    background-color: #fff;
  }
  .cancel-modal-container .upload-img-wrapper,
  .transform-component-module_wrapper__SPB86 {
    margin: auto;
  }
  .carddetailsinfo .cardbutton .auth-action-btn,
  .client_save_btn {
    bottom: -30px !important;
    position: absolute !important;
    width: 13rem !important;
    right: 0 !important;
    left: 0 !important;
    margin: 0 auto !important;
  }
  .carddetailsinfo .cardbutton .auth-action-btn{
    bottom: -50px !important;
  }
  .card {
    margin-right: 0;
}
  .appointment-inner {
    left: 0 !important;
    bottom: 0 !important;
  }
  .rating-review-details-inner {
    bottom: 55px !important;
  }
  .ant-divider-horizontal {
    margin: 12px 0;
  }
  .profile-details-wrapper .service-offer-card {
    min-height: 80px;
  }
  .main-content .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane > .tab-height-auto {
    height: 62vh !important;
  }
  .main-content .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane > .tab-height-auto.iPad-height-appoinment-db{
    height: 77vh !important;
  }
  .timing-container-custom.timing-container {
    margin-top: 15px;
  }
  .mode-icon-div {
    position: absolute;
    right: 0;
    top: 6px;
  }
  .date-range-picker-wrapper .rmdp-calendar {
    width: 100%;
}
  .mode-icon-div.mobile-time-margin{
    margin-top:42px !important;
  }
  .choose-date-div .px-3.text-left-mobile.fw-semibold.fs-4{
        font-size: 20px !important;
        margin-bottom: 0 !important;
  }
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker .rmdp-week {
    margin: 5px 0;
}
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker .rmdp-day {
    height: 48px;
    width: 48px;
}
  .ant-row.p-3.timing-list{
    min-height: auto;
    max-height: 250px;
    overflow: auto;
  }
  .add-new-relative-div,
  .view-relative-details-bottom-row {
    position: relative !important;
    bottom: 0 !important;
    right: 0 !important;
  }
  .caregivers-wrapper .caregivers-card {
    width: 47%;
    height: 300px;
  }
  .rating-review-div {
    justify-content: center !important;
  }
  .rating-review-div img {
    margin-right: 10px;
  }
  .caregivers-wrapper .ratings-card .review-title {
    width: 100%;
    text-align: center;
    display: inline-block;
  }
  .caregivers-wrapper .book-btn {
    margin: 25px auto 0 !important;
  }
  .payment-wrapper .amount-wrapper {
    width: 100%;
    margin: 0 auto;
  }
  .payment-wrapper .card-radio-btn .ant-radio {
    top: 0;
    right: 30px;
    position: absolute;
    bottom: 0;
  }
  .payment-wrapper .card-radio-btn .ant-radio-checked::after,
  .payment-wrapper .card-radio-btn .ant-radio-inner,
  .payment-wrapper .card-radio-btn .ant-radio-input {
    top: 0;
    left: 0 !important;
  }
  .payment-wrapper .card-radio-btn .ant-radio-checked::after {
    height: 16px;
    width: 16px;
    bottom: 0;
    margin: auto;
  }
  .location-container {
    left: 25vw !important;
    width: 50%;
  }
  .basic-details-row {
    align-items: center;
    display: flex;
  }
  .address-details-row.address-details-confi {
    margin: 10px 0;
  }
  .justify-flex-start-mob {
    justify-content: flex-start !important;
  }
  .schedule_tab {
    width: 50%;
  }
  .relative-list-container-web .relative-list .relative-wrapper {
    width: 100%;
    height: 100px;
  }
  .relative-list-container-web .relative-list .relative-wrapper .relative-actions .btn-wrapper {
    width: auto;
  }
  .d-flex.justify-content-space-between.button-center-iPad{
    justify-content: center;
  }
  .cancel-modal-container .appointment-start-btn,
  .cancel-modal-container .auth-action-btn.btn-back {
    width: 200px !important;
    font-size: 16px !important;
    padding: 0 !important;
  }
  .Add_Expenses_Form .ant-input-number-input-wrap {
    width: auto;
  }
  .ant-tabs-tab {
    width: auto;
    justify-content: center;
  }
  .expense_popup_cancel,
  .expense_popup_okay {
    width: 100%;
    height: 55px !important;
  }
  .settings-editprofile h4 {
    margin: 0 0 10px !important;
  }
  .caregiver-profile-settings-card .strengthpass {
    width: 80% !important;
  }
  .subform-2 .ant-form-item {
    width: 48% !important;
  }
  .availability-contents .scroll-wrapper {
    height: calc(70vh - 220px) !important;
  }
  .btn-back,
  .btn-black {
    font-size: 1rem !important;
    height: 50px !important;
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }
  .message-container .message-wrapper .left-child .friend-list {
    width: 100%;
}
  .call-btn {
    height: 58px !important;
  }
  .cancel-modal-container-custom .ant-modal-body {
    width: 85%;
    margin: auto;
  }
  .cancel-modal-container.cancel-modal-container-custom .cancel-text {
    text-align: left !important;
    margin-bottom: 10px;
  }
  .cancel-modal-container.cancel-modal-container-custom .ant-checkbox-wrapper {
    justify-content: space-between;
    flex-direction: row-reverse;
    width: 100%;
  }
   .profile-details-wrapper .username{
             text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
        width: 80% !important;
   }
   .profile-details-wrapper .role-btn {
        width: 90px !important;
        margin-right: 6px !important;
        top: -15px;
        left: 15px;
}
  .cancel-modal-container.cancel-modal-container-custom .select-checkbox .ant-checkbox + span {
    right: auto;
    left: 0;
    width: 100%;
  }
  .ant-divider.ant-divider-vertical{
    display: none;
  }
  .max-height-auto.appointment-details-top.pr-0-mob{
      max-height: 85px !important;
  }
  .cancel-modal-container button.ant-btn.ant-btn-round.ant-btn-primary.ant-btn-lg.btn-black.auth-action-btn.mt-3.mb-3 {
    width: 200px;
  }
  .starting-time-div.starting-time-div-tab-p {
       margin-top: 15px;
    display: flex;
    align-items: center;
  }
  .ant-col.max-height-auto.appointment-details-top{
    max-height: 90px !important;
  }
  .location-details-div {
    padding-left: 8px;
    margin-left: 8px;
}
  .my-3-iPad{
    margin: 10px 0 20px;
  }
  .schedule_container {
    width: calc(100% - 180px);
    padding: 0 0 0 15px;
    margin-left: 180px;
}
  .schedule_appoinments {
    flex-direction: column;
    height: calc(100vh - 250px);
    justify-content: flex-start;
}
  .schedule_calender {
    width: calc(100% - 30px);
    margin: 20px auto;
}
  .careGiver-row.careGiver-row-custom .scroll-wrapper {
    height: calc(68vh - 200px) !important;
  }
  .caregivers-wrapper.caregivers-wrapper-choose {
    min-height: 70vh;
  }
  .appointment-dashboard .appointment-wrapper .starting-time-div.starting-time-div-tab-p .card-title {
    margin: 0 10px 0 0;
  }
  .common-btn-row.common-btn-row-choose-date {
    position: absolute;
    width: 100%;
    bottom: 15px;
    left: 0;
  }
  .caregiver-button-div{
    width: 65%;
    justify-content: space-between !important;
  }
  .service-appointment-col.service-appointment-col-car{
    margin-inline:0 !important ;
  }
  .caregiver-button-div .ant-btn.btn-back.auth-action-btn,
  .caregiver-button-div .ant-btn.btn-black.auth-action-btn{
    width: 48% !important;
  }
  .border-less {
    position: relative;
  }
  .caregivers-wrapper .no-data-found h3 {
    width: 100%;
    margin-top: 30px;
  }
  .clientsettings.client-setting-custom-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab .ant-tabs-tab-btn {
    font-size: 14px;
    color: #333 !important;
  }
  .clientsettings.client-setting-custom-tab .ant-tabs-nav .ant-tabs-nav-wrap .ant-tabs-tab.ant-tabs-tab-active {
    background: #eef7fe !important;
  }
  .clientsettings.client-setting-custom-tab .ant-tabs-nav {
    width: 80px !important;
  }
  .clientsettings.client-setting-custom-tab .ant-tabs-content-holder {
    width: calc(100% - 261px);
  }
  .clientsettings.client-setting-custom-tab {
    height: 87.5vh;
  }
  .client-setting-custom-tab .ant-tabs-content-holder .ant-tabs-content .ant-tabs-tabpane {
    padding-left: 0;
  }
  .subform-1 .ant-form-item {
    padding: 0 5px;
    margin-bottom: 10px;
  }
  .profile-form .addressheading {
    margin-bottom: 5px;
  }
  .profile-details.steps-content,
  .profile-geo-location-div {
    height: calc(100vh - 285px) !important;
  }
  .parent-root .root {
    display: inline-block;
  }
  .relative-list-container-web .relative-list .relative-wrapper .address-wrapper {
    text-align: left;
  }
  .date-range-picker-wrapper.date-range-picker-wrapper-custom {
    height: calc(100vh - 160px);
  }
  .Pending-appointment-content.booking-confirmation-main .scroll-wrapper {
    height: calc(80vh - 100px) !important;
  }
  .top-heading-booking-confirm {
    align-items: center;
    padding-bottom: 20px;
  }
  .booking-confirmation-row .location-container {
    width: 70%;
  }
  .booking-confirmation-row .max-height-auto.text-left-mobile {
    height: 90px;
  }
  .client-schedule .weekly_schedule_appoinments {
    height: 83vh;
  }
  .client-schedule {
    padding: 2rem 15px 20px;
  }
  .circlecross {
    top: 2%;
    z-index: 9999;
  }
  .mx-auto-mobile {
    margin-left: auto !important;
    margin-right: auto !important;
  }
  .auth-action-btn.auth-action-btn-mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .responsive-bottom-margin.responsive-top-margin.responsive-top-margin-ipad {
    margin-top: 0 !important;
  }
  .expense-td.expense-status-td .ant-space {
    justify-content: flex-end;
  }
  .message-container .message-wrapper .left-child .unread-count {
    top: 0;
  }
  .message-container .message-wrapper .right-child .chat-box-wrapper .chat__container .timestamp.right {
    left: 80.8%;
  }
  .client-bottom-btn.client-bottom-btn-update {
    right: 75px;
    bottom: 120px;
}
  .carddetailsinfo .cardbutton.client-bottom-btn-update .auth-action-btn{
    bottom: 0 !important;
    position: relative !important;
  }
  .parent-avalability.parent-avalability-update{
    padding:0 !important;
  }
  .contact-us-main{
    width: calc(100% - 220px) !important;
  }
  .header-breadcrumb{
    width: 55%;
  }
  .message-main ol, .main-content-nav ol{
        width: 100%;
    white-space: nowrap;
    text-overflow: ellipsis;
    flex-wrap: nowrap;
    overflow: hidden;
  }
  .message-main ol li .ant-breadcrumb-link, .main-content-nav ol li .ant-breadcrumb-link {
    font-size: 15px;
}
  .recharts-wrapper, .recharts-surface{
    width: 100% !important;
    height:250px !important;
  }
  .plus-add-img {
    margin-right: 0 !important;
}
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
  .ant-modal-centered .ant-modal.termsandcon.custom-modal {
    width: 65% !important;
  }
  .parent-root .right-panel,
  .right-panel {
    width: 38%;
  }
  .client_save_btn {
    bottom: 0;
    position: absolute;
    width: 15%;
    right: 0;
  }
  .mt-4.time-text-dropdown {
    margin: 0 0 10px !important;
  }
  .email-container {
    width: 60%;
  }
  .profile-header-row .ant-form-item {
    width: 98% !important;
  }
  .ant-form-item.mobile-btn-item .ant-btn {
    margin-right: 0 !important;
    margin-left: auto !important;
    margin-top: 30px !important;
  }
  .container.profilecont {
    max-width: 100% !important;
    margin-left: 0 !important;
  }
  .parent-root .root {
    display: flex;
  }
  .sidebar {
    min-width: 7% !important;
  }
  .parent-root .dashboard-body {
    width: 62%;
    margin-bottom: 0 !important;
  }
  .Transactions_Container .ant-table-body {
    max-height: 350px !important;
  }
  .settings .ant-tabs-nav .ant-tabs-nav-wrap {
    width: 245px;
  }
  .subform-1 .firstone {
    width: 98% !important;
    margin-right: 5px;
  }
  .subform-1 .lastone {
    width: 98% !important;
    margin-left: 5px;
  }
  .settings-editprofile .circle {
    right: 10px;
    top: 11px;
  }
  .dateofbirth .date {
    margin-top: 10px;
    width: 72%;
  }
  .ant-modal-centered .ant-modal {
    width: 60% !important;
    height: auto !important;
    top: 0 !important;
  }
  .no_appointments_text {
    margin-top: 43%;
  }
  .ant-card.custom-dashboard-card {
    width: 32% !important;
  }
  .footer {
    bottom: 0;
    height: 45px;
    left: 0;
    right: 0;
    margin: auto;
  }
  .ant-tabs-mobile #circle-example {
    height: 300px !important;
    margin-top: 0;
  }
  .subform-1 .ant-form-item,
  .subform-2 .ant-form-item {
    width: 49% !important;
  }
  .settings-editprofile .subform-1,
  .settings-editprofile .subform-2 {
    justify-content: space-between;
  }
  .starting-time-div {
    position: relative;
    top: 0;
    left: 0;
    margin-left: 0;
  }
  .d-md-none-tab {
    display: block;
  }
  .main-content .ant-tabs-content-holder > .ant-tabs-content > .ant-tabs-tabpane > .tab-height-auto {
    height: 50vh !important;
  }
  .caregiver-appointment-row-main.card-div > div:first-child {
    height: 65vh !important;
  }
  .caregivers-wrapper .caregivers-card,
  .schedule_tab {
    width: 30%;
  }
  .relative-list-container-web .relative-list .relative-wrapper .relative-actions .btn-wrapper {
    width: 20%;
  }
  .profilecont .text-start {
    padding: 0 8px;
    max-height: calc(100vh - 350px);
  }
  span.ant-select-arrow {
    width: 13px;
  }
  .additional-details-row .ant-row {
    padding: 0 8px;
  }
  .common-card-modal .ant-card-body {
    padding: 0;
  }
  .ant-modal.cancel-modal-container-custom {
    width: 515px !important;
  }
  .starting-time-div.starting-time-div-tab-p {
    position: relative;
    top: 0;
    margin: 0;
    max-width: initial;
    left: 0;
    right: auto;
    display: inline-block;
  }
  .appointment-dashboard .appointment-wrapper .starting-time-div.starting-time-div-tab-p .card-title {
    margin: 0 0 16px;
  }
  .work-details-container.work-details-container-ipad {
    max-width: 60.5vw !important;
  }
  .responsive-bottom-margin.responsive-top-margin.responsive-top-margin-ipad {
    margin-top: 33px !important;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1400px){
  .client-bottom-btn.client-bottom-btn-update {
    right: 75px;
}
  .height-adjust-screen-size{
    height: 43vh !important;
  }
  .mx-4.w-100.main-content, .ms-4.w-100.message-inner-row, .mx-4.message-inner-row.w-100, .contact-us-main, .schedule_container{
    padding-right: 20px;
  }
  .message-main ol li .ant-breadcrumb-link, .main-content-nav ol li .ant-breadcrumb-link {
    font-size: 24px;
}
  .recharts-wrapper, .recharts-surface{
    width: 100% !important;
    height: 270px !important;
  }
  .date-range-picker-wrapper .rmdp-calendar, .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker,
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker .rmdp-week{
    width: 100%;
}
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker .rmdp-day {
    height: 40px;
    width: 40px;
}
  .timing-list {
    padding: 0 !important;
}
  .width-full{
    width: 100% !important;
  }
  .width-full .ant-picker.ant-picker-large{
    width: 98% !important;
  }
  .d-none-small-devices{
    display: none;
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1600px){
  .client-bottom-btn.client-bottom-btn-update {
    right: 75px;
}
  .client-bottom-btn.client-bottom-btn-update.fix-card-100-92, .client-bottom-btn.client-bottom-btn-update.add-new-card-btn, .btn-black.add-card-bank-details.auth-action-btn.float-end.savebutton.add-card-btn{
    bottom: 60px; position: relative;
  right: 0;
    }
  .client-bottom-btn.client-bottom-btn-update.change-password-caregiver{
    position: fixed;
  }
  .height-adjust-screen-size{
    height: 43vh !important;
  }
  .d-none-small-devices{
    display: none;
  }
  .width-full{
    width: 100% !important;
  }
   .width-full .ant-picker.ant-picker-large{
    width: 98% !important;
  }
   .date-range-picker-wrapper .rmdp-calendar, .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker,
  .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker .rmdp-week{
    width: 100%;
}
   .date-range-picker-wrapper .rmdp-calendar .rmdp-day-picker .rmdp-day {
    height: 50px;
    width: 50px;
}
  .mx-4.w-100.main-content, .ms-4.w-100.message-inner-row, .mx-4.message-inner-row.w-100, .contact-us-main, .schedule_container{
    padding-right: 20px;
  }
  .message-main ol li .ant-breadcrumb-link, .main-content-nav ol li .ant-breadcrumb-link {
    font-size: 26px;
}
  .mx-4.message-inner-row.w-100.careGiver_TotalEarnings{
    padding:0 25px;
  }
  .recharts-wrapper, .recharts-surface{
    width: 100% !important;
    height: 300px !important;
  }
}
@media only screen and (min-width: 1601px) and (max-width: 1900px){
  .message-main ol li .ant-breadcrumb-link, .main-content-nav ol li .ant-breadcrumb-link {
    font-size: 28px;
}
  .height-adjust-screen-size{
    height: 43vh !important;
  }
  .mx-4.w-100.main-content, .ms-4.w-100.message-inner-row, .mx-4.message-inner-row.w-100, .contact-us-main, .schedule_container{
    padding-right: 20px;
  }
  .recharts-wrapper, .recharts-surface{
    width: 100% !important;
    height: 300px !important;
  }
  .client-bottom-btn.client-bottom-btn-update {
    right: 75px;
}
  .mx-4.message-inner-row.w-100.careGiver_TotalEarnings{
    padding:0 25px;
  }
}
@media only screen and (min-width: 1901px) and (max-width: 2500px){
  .mx-4.message-inner-row.w-100.careGiver_TotalEarnings{
    padding:0 25px;
  }
  .clientsettings .ant-tabs-nav {
    height: auto;
    width: 400px;
}
}
