.careGiver_TotalEarnings {
  font-family: "Open Sans", sans-serif;
  .ant-table-thead {
    display: none !important;
  }
  .ant-table-thead > tr > th {
    background: none !important;
    border-bottom: none !important;
  }
  .ant-table-tbody > tr > td {
    border-bottom: none !important;
  }
  .Earnings_header {
    font-size: 30px;
    font-weight: 700;
  }
  .Earnings_cards {
    margin: 12px 15px;
  }
  .breadCrumbs_item{

    margin-left: 5px;
  }
  .Earnings_cardbody {
    background: #ffffff;
    padding: 20px;
    border-radius: 20px;
    text-align: center;
    .Earnings_amountType {
      color: #64748b;
      font-size: 18px;
      line-height: 24px;
    }
    .Earnings_amount {
      color: #00b383;
      font-size: 32px;
      font-weight: 700;
      line-height: 56px;
    }
  }
  .Transactions_Container {
    background-color: #ffffff;
    padding: 20px;
    margin: 0 15px;
    height: 60vh;
    border-radius: 20px;
    overflow: auto;
  }
  .Transactions_Header {
    justify-content: space-between;
    padding-bottom: 20px;
    border-bottom: 1px solid #e7e8ea;
  }
  .Earnings_Transaction_Header {
    color: #0f172a;
    font-size: 18px;
    line-height: 24px;
  }
  .Earnings_select_year {
    color: #64748b;
    font-weight: 400;
    font-size: 14px;
    line-height: 19px;
  }
  .Select .ant-select-selector {
    border-radius: 20px;
    background-color: #e7e8ea !important;
    border: none !important;
  }
  span.ant-select-arrow {
    color: #000;
    font-size: 14px;
    margin-top: -4px;
}
  .Transactions_Row {
    justify-content: space-between;
    padding: 15px;
  }

  .Transaction_Type {
    color: #0f172a;
    font-size: 18px;
    font-weight: 600;
  }
}
.Transaction_Date {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
.Transaction_Amount {
  color: #0f172a;
  font-size: 18px;
  font-weight: 700;
}
.Expense_receipt {
  .expenseText {
    font-family: "Open Sans", sans-serif !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 24px !important;
    line-height: 40px;
    text-align: center;
    color: #0f172a;
  }
  .ExpenseImg {
    margin: 20px 0 40px 0;
    .ExpenseImg_image{
      height: 335px;
      width: 335px;
      border-radius: 11px;
      cursor: pointer;
    }
  }
  .expenseReceipt_reject {
    width: 135px;
    height: 60px;
    background: #e7e8ea;
    border-radius: 41px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    border: none !important;
    color: #0f172a;
  }
  .expenseReceipt_approve {
    width: 195px;
    height: 60px !important;
    background: #0f172a;
    box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
    border-radius: 41px;
    font-family: "Open Sans", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 40px;
    text-align: center;
    color: #ffffff;
  }
}
.transaction-image{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 50vh;
  .no-data-text {
      font-weight: 400;
      font-size: 18px;
      line-height: 25px;
      color: #64748B;
  }
  .spinItem{
    margin-left: 49%;
    margin-top: 50px;
  }
}


@media screen and (max-width: 768px) {
  .careGiver_TotalEarnings .Transactions_mobile_Container {
    background-color: #ffffff;
    padding: 20px;
    margin: 0 15px;
    border-radius: 20px;
  }
  .Expense_receipt .ExpenseImg .ExpenseImg_image {
    height: 155px;
    width: 150px;
    border-radius: 11px;
    cursor: pointer;
    justify-content: center !important;
    align-items: center !important;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;