.scroll-wrapper {
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #ddd;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
    }
  }
  
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;