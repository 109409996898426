.clientsettings {
  width: 100%;
  overflow-y: scroll;
  box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1) !important;
  background-color: white;
  height: 78.5vH;
  padding: 25px;
  border-radius: 10px;
  &.contact-client-row{
    background: transparent;
    box-shadow: none !important;
    border-radius: 0;
  }
  .ant-form-item-control-input {
    width: 100%;
  }

  .ant-tabs-nav {
    height: 411px;
    width: 300px;

    .ant-tabs-nav-wrap {
      position: -webkit-sticky;
      position: fixed !important //width:500px
    }
  }

  .ant-tabs-tab {
    margin: 10px 20px 20px 10px !important;
  }

  .ant-tabs-ink-bar {
    background: transparent !important;
  }

  &.client-setting-custom-tab {
    .ant-tabs-nav {
      .ant-tabs-nav-wrap {
        padding: 0 15px 0 0;
        position: relative !important;
        background: transparent;

        .ant-tabs-tab {
            width: 100%;
            margin: 0 0 15px !important;
            height: 70px;
            border-radius: 15px !important;
            padding: 15px !important;
          .anticon {
            margin-right: 0;
            margin-left: 0 !important;
          }

          .ant-tabs-tab-btn {
            width: 100%;
            font-size: 16px;
            text-align: left;

            .imageicons {
              width: 50px;
            }

            .settingnames {
              width: calc(100% - 50px);
              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
    }
  }
}

.settings-editprofile {
  padding: 20px;
}

.profile-form {
  column-gap: 2%;
  margin: auto;

  .subheading {
    display: block !important;
    color: #64748B !important;
    font-size: 18px;
    line-height: 40px;
    padding-bottom: 10px;
  }
}

.profile-form .addressheading {
    color: #64748B;
    font-size: 18px;
    line-height: 40px;
    padding-bottom: 10px;
}

.subform-1 {
  display: flex;
    .ant-form-item-label{
      label{
    color: #64748B;
    font-size: 18px;
    line-height: 40px;
      }
    }
}

.subform-1 label {
  color: rgb(192, 192, 192);

}

.subform-1 .firstone {
  flex: 1;
}

.subform-1 .lastone {
  flex: 1;
}

.container-fluid .ant-tabs-tab {
  background-color: #fff;
  border: 1px solid #007AFF10;
  border-radius: 15px;
  padding: 6px 6px !important;
}

.container-fluid .ant-tabs-tab-active {
  // background-color: #007AFF10;
  // border: 1px solid #007AFF10;
  border-radius: 15px;
}

.container-fluid .active1 .ant-tabs-tab-active {
  background-color: #e6f2ff !important;
}

.container-fluid .active2 .ant-tabs-tab-active {
  background-color: #ebedf9 !important;
}

.container-fluid .active3 .ant-tabs-tab-active {
  background-color: #e6f8f3 !important;
}

.container-fluid {
  .appointment-tab {
    .ant-tabs-tab {
      background: #ffffff !important;
      //box-shadow: 0 10px 60px 0 #0000001A !important;
      border-color: #ffffff !important;
      border-radius: 60px !important;
        .ant-tabs-tab-btn{
          color: #64748B !important;
          font-size: 16px !important;
          font-weight: 400 !important;
        }
      &.ant-tabs-tab-active {
        background: #364BC6 !important;
        border-color: #364BC6 !important;
        .ant-tabs-tab-btn{
          color:#ffffff !important;
        }
      }
    }
  }
}

.cards, .videos {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.card {
  margin-right: 10px;
  margin-bottom: 20px;
}

.videos {
  margin-left: 10px;
}

.video {
  margin-right: 15px;
  margin-bottom: 20px;
}

.cvvexpiry {
  display: flex;
  width: 100%;

}

.ant-input[disabled] {
  background-color: #fff;
}

.expirydate {
  width: 180px;
}

.cvvcard {
  width: 110px;
  margin-left: 10px;
}

.savebutton {
  position: fixed;
  bottom: 10rem;
  right: 3rem;
}

.ant-input-number-group-wrapper {
  width: 100%;
}

.ant-input-number-input-wrap {
  height: 44px;
  padding: 10px;
  display: flex;
  align-items: center;
  background-color: #fff;

}


.changepwd .ant-form-item-control-input {
  width: 61%;
}


.circle {
  position: absolute;
  right: 15px;
  top: 15px;
  opacity: 0.5;
}

.ant-select-arrow {
  opacity: 0.1;
  height: 11px;
  width: 19px;
}

.ant-input-number-group-addon {
  background-color: #fff;
}

.ant-input-number-input-wrap {
  cursor: not-allowed
}

.profile-form .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
  width: 100%;
}

.profile .ant-input-number-group .ant-input-number {
  width: 100% !important;
}

.password-strength-meter-progress {
  width: 50% !important;
}

.carddetailsinfo {
  display: flex !important;
  flex-direction: column !important;

  .ant-card {
    width: 335px !important;
    height: 200px !important;

  }

  .cards {
    flex: 8 !important;
  }

  .cardbutton {
    flex: 1 !important;
  }
}

.termsandcon {
  .ant-modal-body {
    height: 95vh;
    padding: 30px;
    overflow: auto;
  }
}

.clientprofile {
  .stripeaddcardbtn {
    position: fixed;
    right: 100px;
    bottom:92px;
    &.stripeaddcardbtn-update.stripeaddcardbtn-div{
      bottom:105px;
    }
  }
}

.client_save_btn {
  bottom: 0;
  position: absolute;
  width: 11%;
  right: 0;
}

.Change_save_btn {
  position: fixed;
  right: 5rem;
  bottom: 8rem
}

.changepwd-btn.Change_save_btn {
  position: fixed;
  right: 75px;
  bottom: 6.5rem
}
.client-bottom-btn{
  position: fixed;
  right: 75px;
  bottom: 7.5rem
}
.client-bottom-btn.client-bottom-btn-update{
  right: 125px;
}
.client-bottom-btn.client-bottom-btn-update.change-password-caregiver, .client-bottom-btn.client-bottom-btn-update.fix-card-100-92,
.client-bottom-btn.client-bottom-btn-update.add-new-card-btn,
.btn-black.add-card-bank-details.auth-action-btn.float-end.savebutton.add-card-btn{
  right: 100px;
  bottom: 105px;
}
.client-bottom-btn.client-bottom-btn-update.add-new-card-btn.addCard{
  position: relative;
  bottom: 0;
  right: 0;
}
@media (max-width: 920px) {

  .changepwd .ant-form-item-control-input {
    width: 100% !important;
  }

  .Change_save_btn {
    bottom: 2rem;
    position: inherit !important;
  }

  .client_save_btn {
    bottom: 2rem;
    position: fixed;
    width: 80%;
    right: 2rem;
  }

  .clientsettings .ant-tabs-nav {
    width: 80px !important;

    .ant-tabs-nav-wrap {
      width: 80px;
    }
  }

  .clientsettings .ant-tabs-nav {
    width: 80px !important;

    .ant-tabs-nav-wrap {
      width: 50px;
    }
  }

  .clientsettings .ant-form-vertical .px-md-5 {
    width: 70%;
  }

  .btn-position {
    width: 500px;
  }

  .clientsettings .imageicons img {
    width: 80% !important;
  }

}

@media (max-width: 768px) {

  .changepwd .ant-form-item-control-input {
    width: 100% !important;
  }

  .Change_save_btn {
    bottom: 2rem;
    position: inherit !important;
  }

  .clientprofile {
    width: 100%;
    overflow-y: scroll;
    box-shadow: 0 10px 60px rgba(0, 0, 0, 0.1) !important;
    background-color: white;
    padding: 10px;
    border-radius: 10px;
    max-width: 100%;
  }
  .cardbutton .btn-black {
    width: 103px;
  }
}

@media (max-width: 412px) {
  .Change_save_btn {
    bottom: 2rem;
    position: inherit !important;
  }

}

@media (max-width: 540px) {

  .changepwd .ant-form-item-control-input {
    width: 100% !important;
  }

  .Change_save_btn {
    bottom: 2rem;
    position: inherit !important;
  }

  .clientsettings .ant-tabs-nav .ant-tabs-nav-list {
    width: 66px;
  }

  .clientsettings .ant-tabs-nav {
    width: 44px !important;
  }

  .carddetailsinfo .ant-card {
    width: 250px !important;
  }

  .cardinfo .cardnumber {
    font-size: 15px !important;
  }

  .savebutton {
    width: 100px !important;
    padding: 10px !important;;
  }


  .cardbutton {
    width: -1% !important;
  }

  .clientsettings .savebutton {
    bottom: 24rem !important;
    right: 4rem !important;
    font-size: 13px;
  }

  .btn-black {
    font-size: 0.8rem !important;
  }
}
@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;