.sidebar {
  background: #0f172a;
  box-shadow: 0 10px 60px rgba(15, 23, 42, 0.1);
  border-radius: 12px;
  min-width: 60px;
  height: calc(100vh - 80px);
  position: fixed;
  top: 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  left: 12px;
  .sidebar-toggler {
    background: #fff;
    width: 22px;
    border-radius: 50%;
    padding-left: 3px;
    position: absolute;
    top: 60px;
    right: -10px;
    box-shadow: 0 10px 10px rgb(0 0 0 / 10%);
    cursor: pointer;
    z-index: 99;
  }
  .common-div-sidebar{
    height: calc(94vh - 210px);
  }
  .menu-list-div{
    overflow: auto;
    display: inline-block;
    height: 100%;
  }
  .ant-menu.ant-menu-inline-collapsed {
    width: 70px;
  }
  .ant-menu-vertical .ant-menu-item{
    margin: 15px 0 !important;
    display: flex;
    align-items: center;
    position: relative;
    font-size: 16px !important;
    &:before{
      content: '';
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      margin: auto;
      height: 24px;
      width: 6px;
      background: #ffffff;
      opacity: 0;
    }
    &.ant-menu-item-selected{
      &:before{
      opacity: 1;
    }
    }
  }
  .ant-menu.ant-menu-inline-collapsed > .ant-menu-item {
    text-align: center;
     padding: 0 calc(50% - 13px);
    &:last-child{
      .ant-menu-title-content{
        img{
          margin-right: 0 !important;
        }
        span{
          display: none;
        }
      }
    }
  }
  .ant-menu-dark .ant-menu-item-selected {
    background-color: transparent;
  }

  .ant-menu-item-active,
  .ant-menu-item-selected {
    background-color: transparent !important;
    outline: none;
  }
}

@media (max-width: 412px) {
  .logoutmodal {
    h2 {
      margin-left: 0 !important;
    }
  }
}

@media (max-width: 1024px) {
  .sidebar-logo {
    width: 115px;
  }
}

@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;