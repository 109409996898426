.additional-detail-wrapper {
    .signup-slider{

        // .ant-slider-mark-text {
        //     display: none!important;
        // }
        
        // .ant-slider-mark-text-active {
        //     display: flex !important;
        //     bottom: 35px!important;
        //     left: 0%!important;
        //     color: blue;
            
           
        // }
        // .steps-content .caregiverhourlyrate .ant-slider-mark .ant-slider-mark-text-active:nth-child(4){
        //     transform: translateX(30%);
        // }
        // .steps-content .caregiverhourlyrate .ant-slider-mark .ant-slider-mark-text-active:nth-child(5),
        // .steps-content .caregiverhourlyrate .ant-slider-mark .ant-slider-mark-text-active:nth-child(6) {
        //     transform: translateX(10%)!important;
        // }
        
        // .steps-content .caregiverhourlyrate .ant-slider-mark .ant-slider-mark-text-active:nth-child(7),
        // .steps-content .caregiverhourlyrate .ant-slider-mark .ant-slider-mark-text-active:nth-child(8) {
        //     transform: translateX(80%)!important;
        // } 
        .ant-slider-with-marks {
            margin: 35px 21px 28px 24px !important;
        }
        .ant-slider-mark-text {
            display: none;
        }
    
        .ant-slider-mark-text-active {
            display: block !important;
            bottom: 35px;
            font-size: 16px;
            font-weight: 700;
            color: #364BC6;
            width: 60px;
        }
    }
    .auth-action-btn {
        left: 12rem;
    }
}


@media only screen and (max-width: 767px) {
    .additional-detail-wrapper{
        max-height: 70vh;
        overflow: scroll;
        .responsive-margin-bottom{
            margin-bottom: 2rem;
        }
        .additional-screen-button{
            top: 10rem;
            width: 89%;
        }
    }

}




@primary-color: #364BC6;@border-radius-base: 11px;@height-lg: 40px;@input-padding-horizontal: 10px;@input-padding-vertical-lg: 10px;